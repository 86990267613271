import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

import { ReactComponent as BridgeNotConfirmed } from "../../../../../assets/images/bridge-not-confirmed.svg";
import { EVM_SUPPORTED_CHAINS } from "../../../../../ethereumFunctions/constants";
import { TRON } from "../../../../../tronFunction/constants";
import ChainLogoAndSwapIcon, { ChainLogo } from "./ChainLogoAndSwapIcon";
import { ContactGlitter } from "./ContactGlitter";
import { ReactComponent as BridgeProcessStatus } from "./bridgeProcessStatus.svg";
import { ReactComponent as BridgingProgress } from "./bridgingProgress.svg";
import { ReactComponent as SignTx } from "./signTx.svg";

function USDC2Dialog({
  classes,
  isDark,
  toChain,
  network,
  fromChain,
  isUSDCBridgeLoading,
  USDCBridgeError,
  solanaInitTxnSignature,
  algorandTxId,
  setToDefualtState: setToDefaultState,
  isUSDCSolanaDialogOpen,
  isUSDCAlgorandDialogOpen,
}) {
  const duration = getTxConfirmationDuration(fromChain, toChain);
  const swapNote = `Note: Tx on receiver side may take around ${duration} minutes to confirm and to show up on explorer and to update receiver's account balance`;

  const shouldShowCloseButton =
    (!solanaInitTxnSignature && isUSDCSolanaDialogOpen) ||
    (!algorandTxId && isUSDCAlgorandDialogOpen);

  const shouldShowTransactionDetails =
    (isUSDCSolanaDialogOpen && solanaInitTxnSignature) ||
    (isUSDCAlgorandDialogOpen && algorandTxId);

  const shouldShowSolanaMessage =
    isUSDCSolanaDialogOpen && solanaInitTxnSignature;

  const shouldShowAlgorandMessage = isUSDCAlgorandDialogOpen && algorandTxId;

  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        <IconButton
          style={{ color: "#ffffff", float: "right" }}
          onClick={() => {
            setToDefaultState();
            window.location.reload();
          }}
          className={classes.iconBtnRoot}
        >
          <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        {isUSDCBridgeLoading ? (
          <USDCBridging />
        ) : USDCBridgeError ? (
          <USDCBridgeErrorContent classes={classes} />
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ChainLogoAndSwapIcon
              fromChain={fromChain}
              toChain={toChain}
              isUSDCSolanaDialogOpen={isUSDCSolanaDialogOpen}
              isUSDCAlgorandDialogOpen={isUSDCAlgorandDialogOpen}
              solanaInitTxnSignature={solanaInitTxnSignature}
              algorandTxId={algorandTxId}
              classes={classes}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1rem",
                textAlign: "center",
                textDecoration: "none",
                width: "100%",
              }}
            >
              <div>
                <Typography
                  variant="h4"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginBottom: 0,
                  }}
                  className={classes.bridgingTxt}
                >
                  {shouldShowSolanaMessage || shouldShowAlgorandMessage ? (
                    <>
                      <DialogHeading>Bridging is now completed!</DialogHeading>
                      <DialogText>{swapNote}</DialogText>
                    </>
                  ) : (
                    <TransactionNotConfirmedMessage />
                  )}
                </Typography>
                {shouldShowTransactionDetails ? (
                  <TransactionDetails
                    isUSDCAlgorandDialogOpen={isUSDCAlgorandDialogOpen}
                    algorandTxId={algorandTxId}
                    fromChain={fromChain}
                    network={network}
                    isUSDCSolanaDialogOpen={isUSDCSolanaDialogOpen}
                    solanaInitTxnSignature={solanaInitTxnSignature}
                  />
                ) : null}
              </div>

              {shouldShowCloseButton ? (
                <CloseButton onClick={setToDefaultState} />
              ) : null}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

function TransactionDetails({
  isUSDCAlgorandDialogOpen,
  algorandTxId,
  fromChain,
  network,
  isUSDCSolanaDialogOpen,
  solanaInitTxnSignature,
}) {
  const scanUrl = getScanUrl(
    fromChain,
    network,
    algorandTxId,
    solanaInitTxnSignature,
    isUSDCAlgorandDialogOpen,
    isUSDCSolanaDialogOpen
  );

  return (
    <div style={{ marginTop: 49, marginBottom: 51 }}>
      <a
        style={{
          textDecoration: "none",
        }}
        href={scanUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>{"see transaction details"}</Button>
      </a>
    </div>
  );
}

function DisconnectDialog({
  classes,
  isDisconnectError,
  isBridgingInProgress,
  setStateHandler,
}) {
  return (
    <Dialog
      open={isDisconnectError && !isBridgingInProgress}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <IconButton
          style={{ color: "#ffffff", float: "right" }}
          onClick={setStateHandler}
          className={classes.iconBtnRoot}
        >
          <Close style={{ color: "#ffffff" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",
            textAlign: "center",
            textDecoration: "none",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              maxWidth: "276px",
            }}
          >
            <ChainLogo position={"left"} />
            <ChainLogo chain={"solana"} position={"right"} />
            <BridgeNotConfirmed width="276" />
          </Box>
          <DialogHeading>Oops!</DialogHeading>
          <DialogText>
            The Solana Network is experiencing downtime.
            <br />
            Please try again later.
          </DialogText>
          <ContactGlitter />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function InactivityDisconnectDialog({
  classes,
  isDark,
  setToDefaultState,
  disconnectIcon,
}) {
  return (
    <Dialog
      open
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <IconButton
          style={{ color: "#ffffff", float: "right" }}
          onClick={setToDefaultState}
          className={classes.iconBtnRoot}
        >
          <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          variant="h5"
          style={{
            color: isDark ? "#ffffff" : "#000000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",
            textAlign: "center",
            textDecoration: "none",
            width: "100%",
          }}
        >
          <img
            alt="disconnect icon"
            src={disconnectIcon}
            style={{ width: "100px", height: "100px" }}
          />
          <br />
          <div>
            <Typography
              variant="body1"
              style={{ fontSize: "1.2rem", color: "white" }}
            >
              Inactivity found more than 2 minutes.
              <br />
              Wallet disconnected.
              <br />
            </Typography>
          </div>
          <br />
          <br />
          <DialogActions>
            <Button onClick={setToDefaultState}>Ok</Button>
          </DialogActions>
        </Box>
        <br />
      </DialogContent>
    </Dialog>
  );
}

function IssueAlertDialog({
  classes,
  isSolanaClusterTpsAlertOpen,
  isBridgingInProgress,
  isDark,
  setStateHanlder,
  networkDown,
  oopsDark,
}) {
  return (
    <Dialog
      open={isSolanaClusterTpsAlertOpen && !isBridgingInProgress}
      classes={{
        paper: isDark ? classes.dialogPaperDark : classes.dialogPaper,
      }}
      sx={{
        background: "#31003A !important",
        "& .MuiPaper-root": {
          background: "#31003A !important",
        },
        "& .MuiDialog-paper": {
          background: "#31003A !important",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          style={{ color: "#ffffff", float: "right" }}
          onClick={setStateHanlder}
          className={classes.iconBtnRoot}
        >
          <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <br />
        <Typography
          variant="h5"
          style={{
            color: isDark ? "#ffffff" : "#000000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",
            textAlign: "center",
            textDecoration: "none",
            width: "100%",
          }}
        >
          {isDark ? (
            <img
              src={networkDown}
              style={{ width: "330px", height: "150px" }}
            />
          ) : (
            <img src={oopsDark} style={{ width: "330px", height: "150px" }} />
          )}
          <div>
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              Oops!
            </Typography>
          </div>
          <br />
          <div>
            <Typography variant="body1" style={{ fontSize: "1.2rem" }}>
              Solana network is experiencing
              <br />
              problems, please try again later.
            </Typography>
          </div>
          <br />
          <div>
            <Typography variant="body1" style={{ fontSize: ".9rem" }}>
              If the problem persists contact{" "}
              <a
                href="mailto: support@glitter.finance"
                className={classes.linkTeam}
              >
                Glitter team.
              </a>
            </Typography>
          </div>
          <br />
          <div style={{ fontWeight: "bold" }}>Thank you</div>
        </Typography>
        <br />
      </DialogContent>
    </Dialog>
  );
}

const LoadOrWaitDialog = ({
  classes,
  isLoading,
  isWaitingForOptInDialogOpen,
  isWaitingForBalanceUpdate,
  isDark,
  setToDefaultState,
  activeStep,
  toToken,
}) => {
  return (
    <Dialog
      open={isLoading || isWaitingForOptInDialogOpen}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <IconButton
          style={{ color: "#ffffff", float: "right" }}
          onClick={setToDefaultState}
          className={classes.iconBtnRoot}
        >
          {!isWaitingForOptInDialogOpen && (
            <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
          )}
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isWaitingForBalanceUpdate && (
          <div>
            <svg width="100" height="10">
              <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                <stop offset="20%" stopColor="#FF69C5" />
                <stop offset="90%" stopColor="#A767FF" />
              </linearGradient>
            </svg>
            <CircularProgress
              size={72}
              thickness={4}
              classes={{ circle: classes.circle }}
              className={classes.progress}
            />
            <br />
            <Typography
              variant="h5"
              style={{
                color: isDark ? "#ffffff" : "#000000",
                fontWeight: "bold",
                fontSize: "1rem",
                marginTop: "1rem",
              }}
            >
              Updating balance ...
            </Typography>
          </div>
        )}
        {!isWaitingForOptInDialogOpen &&
          !isWaitingForBalanceUpdate &&
          (activeStep === 1 || activeStep === 3 || activeStep === 4) && (
            <div>
              <svg width="100" height="10">
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="20%" stopColor="#FF69C5" />
                  <stop offset="90%" stopColor="#A767FF" />
                </linearGradient>
              </svg>
              <CircularProgress
                size={72}
                thickness={4}
                classes={{ circle: classes.circle }}
                className={classes.progress}
              />
              <br />
              <Typography
                variant="h5"
                style={{
                  color: isDark ? "#ffffff" : "#000000",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginTop: "1rem",
                }}
              >
                Connecting to the wallet ...
              </Typography>
            </div>
          )}
        {isWaitingForOptInDialogOpen && (
          <div>
            <svg width="100" height="10">
              <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                <stop offset="20%" stopColor="#FF69C5" />
                <stop offset="90%" stopColor="#A767FF" />
              </linearGradient>
            </svg>
            <CircularProgress
              size={72}
              thickness={4}
              classes={{ circle: classes.circle }}
              className={classes.progress}
            />
            <br />
            <br />
            <Typography
              variant="body1"
              style={{
                color: isDark ? "#ffffff" : "#000000",
                fontWeight: "bold",
                fontSize: "1rem",
                marginTop: "1rem",
              }}
            >
              Please wait to opt in {toToken} ...
            </Typography>
          </div>
        )}
        {activeStep === 5 && (
          <div>
            <SignTx />
            <Typography
              variant="body1"
              style={{ textAlign: "center", color: "white" }}
            >
              Please approve the transaction
            </Typography>
          </div>
        )}
        <ContactGlitter />
      </DialogContent>
    </Dialog>
  );
};

function BridgePendingShape(props) {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        aspectRatio: "276 / 96",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <img
          width="30"
          alt={props.fromToken}
          src={props.selectChainLogo(props.fromToken)}
        />

        {props.sourceRequestPending && (
          <p
            style={{
              color: "#FFFFFF",
              fontWeight: "bold",
            }}
          >
            COMPLETED
          </p>
        )}
        {props.sourceRequestFailure && (
          <p
            style={{
              color: "red",
              fontWeight: "bold",
            }}
          >
            FAILED
          </p>
        )}
      </div>
      <BridgingProgress
        style={{
          position: "absolute",
          top: "0",
          left: "auto",
          right: "auto",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          alignItems: "center",
          position: "absolute",
          right: "0",
        }}
      >
        <img
          width="30px"
          height="30px"
          alt={props.toToken}
          src={props.selectChainLogo(props.toToken)}
        />
        {props.targetRequestPending && (
          <p
            style={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            COMPLETED
          </p>
        )}
        {props.targetRequestFailure && (
          <p
            style={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            FAILED
          </p>
        )}
      </div>
    </div>
  );
}

function BridgeCompleteShape(props) {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        aspectRatio: "276 / 96",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          left: 0,
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <a
          href={props.selectChainUrl(props.fromToken)}
          style={{
            color: "#00cfff",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width="30"
            alt={props.fromToken}
            src={props.selectChainLogo(props.fromToken)}
          />
        </a>
        {!props.sourceRequestPending ? (
          <p
            style={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            PENDING
          </p>
        ) : (
          <p
            style={{
              color: "#FFFFFF",
              fontWeight: "bold",
            }}
          >
            COMPLETED
          </p>
        )}
      </div>
      <BridgeProcessStatus style={{ position: "absolute", inset: "0" }} />
      <div
        style={{
          position: "absolute",
          left: "auto",
          right: 0,
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <a
          href={props.selectChainUrl(props.toToken)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width="30"
            alt={props.toToken}
            src={props.selectChainLogo(props.toToken)}
          />
        </a>
        {!props.targetRequestPending ? (
          <p
            style={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            PENDING
          </p>
        ) : (
          <p
            style={{
              color: "#61f53c",
              fontWeight: "bold",
            }}
          >
            COMPLETED
          </p>
        )}
      </div>
    </div>
  );
}

const AlgoSolBridgingDialog = ({
  classes,
  isDark,
  sourceRequestFailure,
  setToDefaultState,
  bridgingProcessComplete,
  isBridgingInProgress,
  solanaSignedTxn,
  algorandTxId,
  isSolanaAckTxnSuccesfull,
  isAlgorandAckTxnSuccesfull,
  selectChainLogo,
  fromToken,
  toToken,
  targetRequestFailure,
  targetRequestPending,
  selectChainUrl,
  sourceRequestPending,
}) => {
  return (
    <Dialog
      open
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle id="success-bridge">
        <IconButton
          style={{ color: "#ffffff", float: "right" }}
          onClick={setToDefaultState}
          className={classes.iconBtnRoot}
        >
          <Close style={{ color: "#FFFFFF" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {isBridgingInProgress &&
          (solanaSignedTxn || algorandTxId) &&
          (!isSolanaAckTxnSuccesfull || !isAlgorandAckTxnSuccesfull) && (
            <>
              <BridgePendingShape
                sourceRequestFailure={sourceRequestFailure}
                selectChainLogo={selectChainLogo}
                fromToken={fromToken}
                toToken={toToken}
                targetRequestFailure={targetRequestFailure}
                targetRequestPending={targetRequestPending}
                sourceRequestPending={sourceRequestPending}
              />
              <DialogHeading>Now Bridging</DialogHeading>
              <DialogText>
                Please be patient. Transactions can take some time depending on
                the networks' congestion.
              </DialogText>
              <ContactGlitter />
            </>
          )}

        {bridgingProcessComplete && (
          <>
            <BridgeCompleteShape
              classes={classes}
              selectChainLogo={selectChainLogo}
              fromToken={fromToken}
              toToken={toToken}
              targetRequestPending={targetRequestPending}
              selectChainUrl={selectChainUrl}
              sourceRequestPending={sourceRequestPending}
            />
            <DialogHeading>Bridging is now Completed!</DialogHeading>
            <DialogText>
              Click on the respective logos for viewing more details.
            </DialogText>
            <ContactGlitter />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export {
  AlgoSolBridgingDialog,
  DisconnectDialog,
  InactivityDisconnectDialog,
  IssueAlertDialog,
  LoadOrWaitDialog,
  USDC2Dialog,
};

function SuccessMessage() {
  return <DialogHeading>{"Bridging is now Completed!"}</DialogHeading>;
}

function DialogText({ children }) {
  return (
    <Typography
      variant="body1"
      style={{
        fontSize: "14px",
        color: "white",
        opacity: "0.8",
        marginTop: "21px",
        textAlign: "center",
      }}
    >
      {children}
    </Typography>
  );
}

function DialogHeading({ children }) {
  return (
    <Typography
      style={{
        fontFamily: "ProximaNova",
        textAlign: "center",
        fontWeight: "700",
        fontSize: "26px",
        textTransform: "uppercase",
        lineHeight: "31px",
        color: "#FFFFFF",
        marginTop: "39px",
      }}
    >
      {children}
    </Typography>
  );
}

function USDCBridging() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <CircularProgress
        size={122}
        thickness={4}
        style={{
          color: "#FFFFFF",
          marginBottom: "0px",
        }}
      />
      <DialogHeading>PLEASE WAIT</DialogHeading>
      <DialogText>While we are swapping...</DialogText>
      <ContactGlitter />
    </div>
  );
}

function USDCBridgeErrorContent({ classes }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        marginBottom: "30px",
      }}
    >
      <BridgeNotConfirmed
        style={{
          fontSize: "125px",
          color: "black",
        }}
      />
      <TransactionNotConfirmedMessage />

      <Typography
        variant="h4"
        style={{
          fontFamily: "ProximaNova",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "18px",
          color: "#d8d6d8",
          marginTop: 12,
        }}
      >
        {`Please check the review tab and bridge explorer`}
      </Typography>
      <Typography
        variant="h4"
        style={{
          fontFamily: "ProximaNova",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "14px",
          color: "#d8d6d8",
          marginTop: 24,
          marginBottom: 32,
        }}
      >
        {`Contact support at:`}&nbsp;
        <a href="mailto: business@glitter.finance" className={classes.linkTeam}>
          business@glitter.finance
        </a>
      </Typography>
    </div>
  );
}

function CloseButton({ onClick }) {
  return (
    <Button
      style={{
        background:
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        backdropFilter: "blur(21px)",
        padding: "7px 42px",
        marginTop: 40,
        fontSize: "18px",
        marginBottom: 62,
        fontWeight: "bold",
        borderRadius: 23,
        color: "#76D0D5",
        border: "1px solid #6498a3",
      }}
      onClick={onClick}
    >
      Close
    </Button>
  );
}

function TransactionNotConfirmedMessage() {
  return (
    <Typography
      style={{
        fontFamily: "ProximaNova",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "18px !important",
        textTransform: "uppercase",
        color: "#d8d6d8",
      }}
    >
      TRANSACTION WAS NOT CONFIRMED
    </Typography>
  );
}
function getTxConfirmationDuration(fromChain, toChain) {
  if ([fromChain, toChain].includes("polygon")) {
    return "20";
  } else if (
    [EVM_SUPPORTED_CHAINS, "tron"].includes(fromChain) ||
    [EVM_SUPPORTED_CHAINS, "tron"].includes(toChain)
  ) {
    return "3-5";
  } else {
    return "";
  }
}

function SwapNote({ classes, swapNote }) {
  return <Typography variant="h6">{swapNote}</Typography>;
}
function getScanUrl(
  fromChain,
  network,
  algorandTxId,
  solanaInitTxnSignature,
  isUSDCAlgorandDialogOpen,
  isUSDCSolanaDialogOpen
) {
  if (fromChain === "algorand" && isUSDCAlgorandDialogOpen && algorandTxId) {
    return `https://${
      network === "testnet" ? "testnet." : ""
    }algoexplorer.io/tx/${algorandTxId}`;
  }

  if (isUSDCSolanaDialogOpen && solanaInitTxnSignature) {
    return `https://solscan.io/tx/${solanaInitTxnSignature}${
      network === "testnet" ? "?cluster=devnet" : ""
    }`;
  }

  if (fromChain === "avalanche") {
    return `https://${
      network === "testnet" ? "testnet." : ""
    }snowtrace.io/tx/${algorandTxId}`;
  }

  if (fromChain === "ethereum") {
    return `https://${
      network === "testnet" ? "goerli." : ""
    }etherscan.io/tx/${algorandTxId}`;
  }

  if (fromChain === TRON) {
    return `https://${
      network === "testnet" ? "shasta." : ""
    }tronscan.org/#/transaction/${algorandTxId}`;
  }

  if (fromChain === "polygon") {
    return `https://${
      network === "testnet" ? "mumbai." : ""
    }polygonscan.com/tx/${algorandTxId}`;
  }

  return "";
}
