import { solanaAccountSeeker, sleep } from "../solanaFunctions";
import { SystemProgram } from "@solana/web3.js";
import { deserialize } from "borsh";
import { bridgeProgramId, solAssetsInfo } from "./constants";
import { Escrow } from "./pdaState";
import configData from "../config.json";
import { getTokenAccountList } from "../solanaFunctions";
import { getServerURL } from "../algoFunctions/agloConnectionPublic";

import bs58 from "bs58";
import axios from "axios";

const explorerUrl = configData.others.explorerUrl;

export async function bridgeStatusInitializedFromSolana(
  network,
  connection,
  solanaPublicKey,
  mintAccount
) {
  const { solanaEscrowAccount } = await solanaAccountSeeker(
    network,
    solanaPublicKey,
    mintAccount
  );
  let counter = 0;
  let isFinalized = false;
  while (!isFinalized) {
    await sleep(parseInt(configData.settings.polling_interval));
    counter += 1;
    if (counter > parseInt(configData.settings.max_retries)) {
      break;
    }
    let signature = null;
    try {
      signature = await connection.getConfirmedSignaturesForAddress2(
        solanaEscrowAccount,
        {
          limit: 1,
        }
      );
    } catch (e) {
      continue;
    }
    if (!signature) {
      continue;
    }
    for (const sig of signature) {
      let txn = await connection.getTransaction(sig.signature);
      let data = txn.transaction.message.instructions[0].data;
      if (!data) {
        continue;
      }
      let data_bytes = bs58.decode(data);
      if (data_bytes.length === 85 && [11, 21].includes(data_bytes[0])) {
        let algorandTxnId = new TextDecoder().decode(
          new Uint8Array(data_bytes.slice(33, 85))
        );
        isFinalized = true;
        return { isFinalized, signature: sig.signature, algorandTxnId };
      }
      if (data_bytes.length === 33 && [12, 22].includes(data_bytes[0])) {
        return { isFinalized, signature: sig.signature, algorandTxnId: null };
      }
    }
  }
  return { isFinalized, signature: null, algorandTxnId: null };
}

export async function bridgeStatusInitializedFromAlgorand(
  connection,
  solanaPublicKey,
  algorandTxnId
) {
  let counter = 0;
  let isFinalized = false;
  while (!isFinalized) {
    await sleep(parseInt(configData.settings.polling_interval));
    counter += 1;
    if (counter > parseInt(configData.settings.max_retries)) {
      break;
    }
    let signature = null;
    try {
      signature = await connection.getConfirmedSignaturesForAddress2(
        solanaPublicKey,
        {
          limit: 1,
        }
      );
    } catch (e) {
      continue;
    }
    if (!signature) {
      continue;
    }
    for (const sig of signature) {
      let txn = await connection.getTransaction(sig.signature);
      let data = txn.transaction.message.instructions[0].data;
      if (!data) {
        continue;
      }
      let data_bytes = bs58.decode(data);
      if (
        data_bytes.length === 93 &&
        (data_bytes[0] === 13 || data_bytes[0] === 23)
      ) {
        let algo_txn_from_contract = new TextDecoder().decode(
          new Uint8Array(data_bytes.slice(33, 85))
        );
        if (algorandTxnId !== algo_txn_from_contract) {
          continue;
        }
        isFinalized = true;
        return { isFinalized, signature: sig.signature };
      }
    }
  }
  return { isFinalized, signature: null };
}

export const checkUSDCAlgorandBalanceAfterTransaction = async (
  fromChain,
  toChain,
  fromTokenBalance,
  algorandWalletAddress,
  network,
  txHash
) => {
  let counter = 0;
  let isFinalized = false;
  const USDCAssetInfo = configData.algorand[network].assets_info.find(
    (a) => a.symbol === "USDC"
  );

  while (true) {
    counter += 1;

    await sleep(parseInt(configData.settings.polling_interval * counter));

    if (counter > 25) {
      break;
    }

    let account_data;

    try {
      const url =
        getServerURL(network) + `/v2/accounts/${algorandWalletAddress}`;
      const account_response = await window.fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      account_data = await account_response?.json();

      const transactionHash =
        await checkBridgeExplorerForSuccessfullyInitializedTransaction(
          fromChain,
          toChain,
          txHash
        );

      if (
        account_data?.assets?.length &&
        transactionHash?.depositID === txHash &&
        transactionHash?.status === "Success"
      ) {
        for (const asset of account_data.assets) {
          if (asset["asset-id"] === Number(USDCAssetInfo.asset_id)) {
            const amount =
              asset?.amount / 10 ** parseInt(USDCAssetInfo.decimal);

            if (amount < fromTokenBalance) {
              isFinalized = true;
              return { isFinalized };
            }
          }
        }
      } else {
        continue;
      }
    } catch (error) {
      continue;
    }
  }

  return { isFinalized };
};

export const checkUSDCSolanaBalanceAfterTransaction = async (
  fromChain,
  toChain,
  connection,
  solanaWalletObject,
  fromToken,
  fromTokenBalance,
  network,
  signature
) => {
  let counter = 0;
  let isFinalized = false;

  while (true) {
    counter += 1;

    await sleep(parseInt(configData.settings.polling_interval * counter));

    if (counter > 25) {
      break;
    }

    try {
      let tokenAccountInfo = await getTokenAccountList(
        connection,
        solAssetsInfo(network).find((a) => a.symbol === fromToken).mint,
        solanaWalletObject.publicKey
      );

      if (!tokenAccountInfo?.length) {
        continue;
      }

      const transactionHash =
        await checkBridgeExplorerForSuccessfullyInitializedTransaction(
          fromChain,
          toChain,
          signature
        );

      if (
        transactionHash?.depositID === signature &&
        transactionHash?.status === "Success"
      ) {
        const currentBalance = await connection.getTokenAccountBalance(
          tokenAccountInfo?.[0]?.accountPubkey
        );

        const balanceUiAmount = Number(currentBalance?.value?.uiAmount);

        if (balanceUiAmount < fromTokenBalance) {
          isFinalized = true;
          return { isFinalized };
        }
      } else {
        continue;
      }
    } catch (error) {
      continue;
    }
  }

  return { isFinalized };
};

/* ------------------------------------------------------------ 
    Get the account info and check if it is initialized
--------------------------------------------------------------- */
export async function checkIfSolBridgeInitialized(network, connection, pubkey) {
  const accountInfo = await connection.getAccountInfo(pubkey);
  if (
    accountInfo === null ||
    (accountInfo.lamports === 0 &&
      accountInfo.owner.toString() === SystemProgram.programId.toString())
  ) {
    return { isValid: true, isInitialized: false };
  }

  let accountData = deserialize(
    Escrow.schema,
    Escrow,
    accountInfo.data ? accountInfo.data : accountInfo.account.data
  );
  if (
    !accountInfo.data ||
    accountInfo.data.length !== 97 ||
    accountInfo.owner.toString() !== bridgeProgramId(network).toString() ||
    accountData.is_initialized !== 1 ||
    accountData.sol_address.toString() !== pubkey.toString()
  ) {
    return { isValid: false, isInitialized: false };
  }
  return { isValid: true, isInitialized: true };
}

export const checkBridgeExplorerForSuccessfullyInitializedTransaction = async (
  fromNetwork,
  toNetwork,
  fromTransactionHash
) => {
  let transactionHash = "";
  try {
    const resp = await axios.get(
      `${explorerUrl}/txns/search?txnID=${fromTransactionHash}`
    );
    transactionHash = resp?.data?.transactions?.[0];
  } catch (error) {
    // We dont need any error implementation here
    // transactionHash will not be updated it will just return as empty string
  }

  return transactionHash;
};
