import { DeflyWalletConnect } from "@blockshake/defly-connect";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import {
  Box,
  Button,
  ButtonBase,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import MyAlgo from "@randlabs/myalgo-connect";
import WalletConnect from "@walletconnect/client";
import QRCodeModalSimple from "@walletconnect/qrcode-modal";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import algosdk from "algosdk";
import PropTypes from "prop-types";
import { toDataURL } from "qrcode";
import React, { memo } from "react";
import { isOptedIn } from "../../../algoFunctions/asset-optin";
import algoLogo from "../../../assets/images/algo.png";
import solanaLogo from "../../../assets/images/solana.svg";
import {
  getServerURL,
  getIndexerURL,
} from "../../../algoFunctions/agloConnectionPublic";
import { Heading } from "../../../componentsReuse/NutsAndBolts";
import WalletModal from "../../../componentsReuse/WalletModal";
import configData from "../../../config.json";
import {
  checkIfxAlgoAccountExist,
  getTokenAccountList,
  optInxAlgo,
  sleep,
  solAssetsInfo,
} from "../../../solanaFunctions";
import GradientBox from "../../GradientBox";
import styles from "./ToWalletStepStyles";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import TronWebNode from "tronweb";
import {
  getAvalanceTokenBalanceInfo,
  getAvalancheTokenBalanceInfoByExplorer,
  getEthTokenBalanceInfo,
  getEthTokenBalanceInfoByExplorer,
  getPolygonTokenBalanceInfo,
  getPolygonTokenBalanceInfoByExplorer,
} from "../../../ethereumFunctions/accountDetails";
import {
  EVM_SUPPORTED_CHAINS,
  EVM_SUPPORTED_WALLETS,
} from "../../../ethereumFunctions/constants";
import {
  ethereumWalletHandler,
  getEVMChainHelper,
} from "../../../ethereumFunctions/walletHandler";
import { TRON } from "../../../tronFunction/constants";
import { getTronUSDCBalanceByAbi } from "../../../tronFunction/walletHandler";
import { ischainAndIdSame } from "../../../utils/chainUtils";
import ToWallets from "../../ToWallets";
import {
  InputWalletsLogo,
  ToWalletDialogs,
  errorText,
} from "./components/Dialogs";

const ColorButton = withStyles((theme) => ({
  root: {
    width: "auto !important",
    background: theme.palette.update1Palette.bgGradient2,
    height: "47px",
    minWidth: "105px",
    borderRadius: "10px",
    textTransform: "capitalize",
  },
}))(Button);

class ToWalletStep extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      xSolAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "xSOL"
      ).asset_id,
      USDCAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "USDC"
      ).asset_id,
      errorType: "",
      algorandWalletAddress: "",
      solanaWalletAddress: "",
      avalancheWalletAddress: "",
      tronWalletAddress: "",
      selectedWalletType: null,
      algoWalletType: null,
      isAlgoInput: false,
      isSolInput: false,
      isConnectedToWallet: false,
      label: "Wallet Name",
      amount: 0,
      inverse: false,
      margin: 4,
      walletUri: "algorand://",
      errorLevel: "high",
      version: "auto",
      walletDataURL: algoLogo,
      isWalletQrModalOpen: false,
      walletOK: true,
      darkColor: "#000",
      lightColor: "#FFF",
      trxPayment: [],
      trxTransfer: [],
      isModalOpen: false,
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.fetchAlgoWalletInfo = this.fetchAlgoWalletInfo.bind(this);
    this.handleMyAlgoConnect = this.handleMyAlgoConnect.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.algorandWallet = null;
    this.handleClickWalletConnectButton =
      this.handleClickWalletConnectButton.bind(this);
    this.handleClickConnectMyAlgoWalletButton =
      this.handleClickConnectMyAlgoWalletButton.bind(this);
    this.handleSelectSolanaWalletButton =
      this.handleSelectSolanaWalletButton.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.handleSelectAvalancheWalletButton =
      this.handleSelectAvalancheWalletButton.bind(this);
    this.handleSelectTronWalletButton =
      this.handleSelectTronWalletButton.bind(this);
    this.getSolanaBalance = this.getSolanaBalance.bind(this);
    this.checkAssetOptIn = this.checkAssetOptIn.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  async componentDidMount() {
    if (this.props.toChain === "algorand" && this.props.algorandWalletAddress) {
      // await this.handleClickDisconnectButton();
    }
    if (this.props.toChain === "solana" && this.props.solanaWalletObject) {
      // await this.handleClickDisconnectButton();
    }
    if (this.state.algorandWalletAddress !== this.props.algorandWalletAddress) {
      this.setState({
        algorandWalletAddress: this.props.algorandWalletAddress,
      });
    }
    if (this.state.solanaWalletAddress !== this.props.solanaWalletAddress) {
      this.setState({ solanaWalletAddress: this.props.solanaWalletAddress });
    }
    if (
      EVM_SUPPORTED_CHAINS.includes(this.props.toChain) &&
      this.props.avalancheWalletAddress
    ) {
      await this.handleClickDisconnectButton();
    }
    if (
      this.state.avalancheWalletAddress !== this.props.avalancheWalletAddress
    ) {
      this.setState({
        avalancheWalletAddress: this.props.avalancheWalletAddress,
      });
    }
    if (this.props.toChain === "tron" && this.props.tronWalletAddress) {
      await this.handleClickDisconnectButton();
    }
    if (this.state.tronWalletAddress !== this.props.tronWalletAddress) {
      this.setState({
        tronWalletAddress: this.props.tronWalletAddress,
      });
    }
  }

  async componentDidUpdate(prevProps) {
    const { toChain, toToken, network } = this.props;

    if (prevProps.activeStep !== this.props.activeStep) {
      if (
        this.props.toChain === "algorand" &&
        this.props.algorandWalletAddress
      ) {
        await this.fetchAlgoWalletInfo();
        if (this.props.toToken === "xSOL" || this.props.toToken === "USDC") {
          let isAlgoWalletOptIn = await this.checkAssetOptIn(
            this.props.algorandWalletAddress,
            this.props.toToken === "xSOL"
              ? this.state.xSolAssetId
              : this.state.USDCAssetId
          );
          this.props.setIsAlgoWalletOptIn(isAlgoWalletOptIn);
          if (!isAlgoWalletOptIn) {
            this.props.setIsXsolOptInAlertOpen(true);
          }
        }
      }
      if (this.props.toChain === "solana" && this.props.solanaWalletObject) {
        await this.getSolanaBalance();
      }
      if (toChain === "ethereum" && this.props.avalancheWalletObject) {
        let balance = await getEthTokenBalanceInfo(
          this.state.avalancheWalletAddress,
          network,
          toToken,
          this.props.avalancheWalletObject
        );
        this.props.setToTokenBalance(balance);
      }
      if (
        (toChain === "avalanche" || toChain === "polygon") &&
        this.props.avalancheWalletObject
      ) {
        let balance =
          (await toChain) === "avalanche"
            ? getAvalanceTokenBalanceInfo(
                this.state.avalancheWalletAddress,
                network,
                toToken,
                this.props.avalancheWalletObject
              )
            : getPolygonTokenBalanceInfo(
                this.state.avalancheWalletAddress,
                network,
                toToken,
                this.props.avalancheWalletObject
              );
        this.props.setToTokenBalance(balance);
      }
      if (toChain === TRON && this.props.tronWalletObject) {
        let balance = await getTronUSDCBalanceByAbi(
          this.props.tronWalletObject,
          network,
          this.props.tronWalletAddress
        );
        this.props.setToTokenBalance(balance);
      }
    }
    if (this.state.algorandWalletAddress !== this.props.algorandWalletAddress) {
      this.setState({
        algorandWalletAddress: this.props.algorandWalletAddress,
      });
    }
    if (this.state.solanaWalletAddress !== this.props.solanaWalletAddress) {
      this.setState({ solanaWalletAddress: this.props.solanaWalletAddress });
    }

    if (
      this.state.avalancheWalletAddress !== this.props.avalancheWalletAddress
    ) {
      this.setState({
        avalancheWalletAddress: this.props.avalancheWalletAddress,
      });
    }
    if (this.state.tronWalletAddress !== this.props.tronWalletAddress) {
      this.setState({
        tronWalletAddress: this.props.tronWalletAddress,
      });
    }
  }

  generateWalletQRCode() {
    let {
      algorandWalletAddress,
      solanaWalletAddress,
      avalancheWalletAddress,
      tronWalletAddress,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const { toChain } = this.props;
    const that = this;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let walletURI = "";

    walletURI = `${toChain}://${
      toChain === "algorand"
        ? algorandWalletAddress
        : toChain === "solana"
        ? solanaWalletAddress
        : toChain === TRON
        ? tronWalletAddress
        : avalancheWalletAddress
    }?label=${label}`;

    opts.mode = "Auto";
    toDataURL(walletURI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: walletURI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async fetchAlgoWalletInfo() {
    this.props.setIsLoading(true);
    const algoAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "ALGO");
    const xSolInfo = configData.algorand[this.props.network].assets_info.find(
      (a) => a.symbol === "xSOL"
    );
    const USDCInfo = configData.algorand[this.props.network].assets_info.find(
      (a) => a.symbol === "USDC"
    );
    let { algorandWalletAddress } = this.state;
    if (
      algorandWalletAddress === null ||
      algorandWalletAddress === undefined ||
      algorandWalletAddress === ""
    ) {
      algorandWalletAddress = this.props.algorandWalletAddress;
    }
    const that = this;
    if (algosdk.isValidAddress(algorandWalletAddress)) {
      const url =
        getServerURL(this.props.network) +
        `/v2/accounts/${algorandWalletAddress}`;

      const urlTrx =
        getIndexerURL(this.props.network) +
        `/v2/accounts/${algorandWalletAddress}/transactions?limit=10`;

      try {
        let account_response = await window.fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (account_response.status === 404) {
          this.props.setToNativeBalance(0);
          this.props.setToTokenBalance(0);
          this.props.setIsLoading(false);
          return;
        }
        let account_data = await account_response.json();
        if (account_data) {
          if (account_data.message) {
            if (
              account_data.message.indexOf("no accounts found") > -1 &&
              this.props.toChain === "algorand"
            ) {
            }
          }
          if (String(account_data.address) === String(algorandWalletAddress)) {
            const amount =
              account_data.amount / 10 ** parseInt(algoAssetInfo.decimal);
            that.props.setToNativeBalance(amount);
            if (account_data.assets) {
              account_data.assets.forEach((asset) => {
                if (this.props.toToken === "xSOL") {
                  console.log("in ", asset);
                  if (asset["asset-id"] === Number(xSolInfo.asset_id)) {
                    const amount =
                      asset.amount / 10 ** parseInt(xSolInfo.decimal);
                    that.props.setToTokenBalance(amount);
                  }
                } else {
                  if (asset["asset-id"] === Number(USDCInfo.asset_id)) {
                    const amount =
                      asset.amount / 10 ** parseInt(USDCInfo.decimal);
                    console.log("in ", amount, asset);
                    that.props.setToTokenBalance(amount);
                  }
                }
              });
            } else {
              that.props.setToTokenBalance(0);
            }
          }
        }

        let txn_response = await window.fetch(urlTrx, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (txn_response.status === 404) {
          this.props.setIsLoading(false);
          return;
        }
        let txn_data = await txn_response.json();
        if (txn_data) {
          if (txn_data.transactions) {
            that.setState({
              trxPayment: txn_data.transactions.filter(
                (trx) => !!trx["payment-transaction"]
              ),
              trxTransfer: txn_data.transactions.filter(
                (trx) => !!trx["asset-transfer-transaction"]
              ),
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.props.setIsLoading(false);
      }
    }
    this.props.setIsLoading(false);
  }

  async handleMyAlgoConnect(walletName) {
    let localStorageSession = localStorage.getItem("walletconnect");
    if (walletName === "Defly" && localStorageSession) {
      localStorage.removeItem("walletconnect");
    }

    try {
      this.props.setIsLoading(true);
      this.algorandWallet = this.algorandWallet =
        walletName === "MyAlgo"
          ? new MyAlgo()
          : walletName === "Defly"
          ? new DeflyWalletConnect()
          : this.algorandWallet;

      const accountsConnect = await this.algorandWallet.connect();

      const accounts =
        walletName === "MyAlgo"
          ? accountsConnect
          : [{ address: accountsConnect[0] }];

      this.props.setAlgorandWalletObject(this.algorandWallet);
      this.props.setAlgoWallet(accounts[0].address);
      this.props.setToWallet(accounts[0].address);
      this.setState(
        { algorandWalletAddress: accounts[0].address },
        async () => {
          await this.fetchAlgoWalletInfo();
        }
      );
      if (algosdk.isValidAddress(accounts[0].address)) {
        this.props.setAlgorandWalletType(
          walletName === "MyAlgo" ? "myAlgoConnect" : "deflyConnect"
        );
        this.props.setIsWalletEmptyAlertOpen(false);
        if (this.props.toToken === "xSOL" || this.props.toToken === "USDC") {
          let isAlgoWalletOptIn = await this.checkAssetOptIn(
            accounts[0].address,
            this.props.toToken === "xSOL"
              ? this.state.xSolAssetId
              : this.state.USDCAssetId
          );
          this.props.setIsAlgoWalletOptIn(isAlgoWalletOptIn);
          if (!isAlgoWalletOptIn) {
            this.props.setIsXsolOptInAlertOpen(true);
          }
        }
      } else {
        this.setState(
          { isAlertDialogOpen: true, errorType: "isValidAddress" },
          () => {
            setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }
      this.props.setIsLoading(false);
    } catch (err) {
      console.error(err);
      this.props.setIsLoading(false);
    }
  }

  handleClickWalletConnectButton(walletName) {
    if (
      this.props.algorandWalletType === "" ||
      !this.props.algorandWalletType ||
      this.props.algorandWalletType === null
    ) {
      const that = this;
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });

      this.connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal:
          walletName === "Pera Algo" || walletName === "Exodus Mobile"
            ? QRCodeModal
            : QRCodeModalSimple,
      });

      if (!this.connector.connected) {
        this.connector.createSession();
      } else {
        this.connector.killSession();
      }
      this.connector.on("connect", async (error, payload) => {
        const { name } = payload?.params?.[0]?.peerMeta;

        if (error) {
          console.error(error);
          return;
        }
        if (name === "Pera Wallet" || name === "Exodus Mobile") {
          that.algorandWallet = this.connector;
          that.props.setAlgorandWalletObject(this.algorandWallet);
          const { accounts } = payload.params[0];
          let wallet = accounts[0];
          if (algosdk.isValidAddress(accounts[0])) {
            that.setState(
              { isConnectedToWallet: true, algorandWalletAddress: wallet },
              async () => {
                await that.fetchAlgoWalletInfo();
              }
            );
            that.setState({ isConnectedToWallet: true });
            that.props.setAlgoWallet(wallet);
            that.props.setToWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setAlgorandWalletType("walletConnect");
            if (
              that.props.toToken === "xSOL" ||
              that.props.toToken === "USDC"
            ) {
              let isAlgoWalletOptIn = await that.checkAssetOptIn(
                wallet,
                this.props.toToken === "xSOL"
                  ? this.state.xSolAssetId
                  : this.state.USDCAssetId
              );
              that.props.setIsAlgoWalletOptIn(isAlgoWalletOptIn);
              if (!isAlgoWalletOptIn) {
                that.props.setIsXsolOptInAlertOpen(true);
              }
            }
          } else {
            that.connector?.killSession();
            return;
          }
        } else {
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "isValidAddress",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                5000
              );
            }
          );
        }
      });

      this.connector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        const { accounts } = payload.params[0];
        that.setState(
          { algorandWalletAddress: accounts[0].address },
          async () => {
            await that.fetchAlgoWalletInfo();
          }
        );
        that.props.setAlgoWallet(accounts[0].address);
      });

      this.connector.on("disconnect", (error, payload) => {
        this.algorandWallet = null;
        if (error) {
          console.error(error);
          return;
        }
      });
    } else if (this.props.algorandWalletType === "walletConnect") {
      this.setState({ isDisconnectDialogOpen: true });
    } else if (
      ["myAlgoConnect", "deflyConnect"].includes(this.props.algorandWalletType)
    ) {
      this.setState({ isWalletConnectionModalOpen: true });
    }
  }

  handleClickConnectMyAlgoWalletButton(walletName) {
    if (this.props.algorandWalletType === "") {
      this.handleMyAlgoConnect(walletName);
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });
      this.props.setIsWalletEmptyAlertOpen(false);
    } else if (
      ["myAlgoConnect", "deflyConnect"].includes(this.props.algorandWalletType)
    ) {
      this.setState({ isDisconnectDialogOpen: true });
    } else if (this.props.algorandWalletType === "walletConnect") {
      this.setState({ isWalletConnectionModalOpen: true });
    }
  }

  async getSolanaBalance() {
    let isGetSolBalanceCompleted = false;
    let balance = 0;
    let retry_count = 0;
    while (!isGetSolBalanceCompleted) {
      try {
        balance = await this.props.connection.getBalance(
          this.props.solanaWalletObject.publicKey
        );
        isGetSolBalanceCompleted = true;
      } catch {
        retry_count++;
        if (retry_count > configData.settings.polling_retry) {
          this.props.setIsCheckConnectionAlertOpen(true);
          return;
        }
        await sleep(configData.settings.polling_interval);
        console.log("Retrying to get balance");
        continue;
      }
    }

    this.props.setToNativeBalance(balance / 1000000000);
    if (this.props.toToken === "SOL" || this.props.fromToken === "SOL") {
      return;
    }
    let tokenAccountInfo = await getTokenAccountList(
      this.props.connection,
      solAssetsInfo(this.props.network).find(
        (a) =>
          a.symbol ===
          (this.props.fromChain === "solana"
            ? this.props.fromToken
            : this.props.toToken)
      ).mint,
      this.props.solanaWalletObject.publicKey
    );
    if (!tokenAccountInfo) {
      this.props.setToTokenBalance(0);
      return;
    }

    const rawxAlgoBalance = await this.props.connection.getTokenAccountBalance(
      tokenAccountInfo[0].accountPubkey
    );
    console.log(
      "TokenAccountInfo Address : ",
      tokenAccountInfo[0].accountPubkey.toString()
    );

    const xAlgoBalance = Number(rawxAlgoBalance.value.uiAmount);
    this.props.setToTokenBalance(xAlgoBalance);
  }

  async handleSelectSolanaWalletButton(walletType) {
    if (this.props.solanaWalletType === "") {
      try {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        await this.props.setSolanaWallet(walletType);
        this.props.setIsWalletEmptyAlertOpen(false);
        this.props.setToWallet(this.props.solanaWalletAddress);
        await this.getSolanaBalance();
        this.setState({ isAlertDialogOpen: false });
        const that = this;
        if (this.props.toToken !== "SOL") {
          const { isSolanaOptIn } = await checkIfxAlgoAccountExist(
            this.props.network,
            this.props.connection,
            this.props.solanaWalletObject.publicKey,
            this.props.solanaAssetInfo?.mint,
            that
          );
          if (isSolanaOptIn === false) {
            this.props.setSolanaOptInAlertOpen(true);
          }
          this.props.setIsSolanaOptIn(isSolanaOptIn);
        }
        this.props.setIsLoading(false);
      } catch (err) {
        console.error(err);
        this.props.setIsLoading(false);
        this.props.setIsCheckConnectionAlertOpen(true);
      }
    } else if (this.props.solanaWalletType === walletType) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletType,
      });
    }
  }

  async handleClickConnectButton() {
    this.props.setIsLoading(true);
    this.setState({
      isWalletConnectionModalOpen: false,
    });
    this.props.setSolanaOptInAlertOpen(false);
    if (this.props.toChain === "algorand") {
      await this.props.setAlgoWallet("");

      if (this.props.algorandWalletType === "walletConnect") {
        await this.props.setAlgorandWalletType("");
        this.algorandWallet = null;
        this.handleClickConnectMyAlgoWalletButton();
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (
        ["myAlgoConnect", "deflyConnect"].includes(
          this.props.algorandWalletType
        )
      ) {
        await this.props.setAlgorandWalletType("");
        this.handleClickWalletConnectButton();
      }
    } else if (this.props.toChain === "solana") {
      await this.props.setSolanaWallet("");
      await this.handleSelectSolanaWalletButton(this.state.selectedWalletType);
    } else if (EVM_SUPPORTED_CHAINS.includes(this.props.toChain)) {
      await this.handleClickDisconnectButton();
      await this.handleSelectAvalancheWalletButton(
        this.state.selectedWalletType
      );
    }
    this.setState({
      isWalletConnectionModalOpen: false,
    });
    this.props.setIsAlgoWalletOptIn(false);
    this.props.setIsLoading(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
  }

  /*
   *
   *    Ethereum
   */
  async handleSelectAvalancheWalletButton(walletname) {
    const that = this;

    const {
      ethereumWalletType,
      ethereumWalletAddress,
      avalancheWalletAddress,
      network,
      toChain,
      toToken,
    } = this.props;
    const tokenName = toToken;

    const connectWithWallet = async (walletname) => {
      this.props.setIsLoading(true);

      let wallet;
      try {
        wallet = await ethereumWalletHandler(walletname, network, toChain);
        if (wallet?.ethereumWalletAddress) {
          this.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            avalancheWalletAddress: wallet?.ethereumWalletAddress,
          });

          this.props.setToWallet(wallet?.ethereumWalletAddress);
          this.props.setAvalancheWalletType(wallet?.ethereumWalletType);
          this.props.setAvalancheWalletObject(wallet?.ethereumWalletObject);
          this.props.setAvalancheWalletAddress(wallet?.ethereumWalletAddress);
        }
      } catch (error) {
        console.log("error ", error);
        that.setState(
          {
            isAlertDialogOpen: true,
            errorType: "avalancheWalletValidation",
          },
          () => {
            setTimeout(() => that.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }

      try {
        let balance;
        if (toChain === "ethereum") {
          balance = await getEthTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (toChain === "avalanche") {
          balance = await getAvalanceTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (toChain === "polygon") {
          balance = await getPolygonTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        }
        this.props.setToTokenBalance(balance);
      } catch (e) {
        console.log("error ", e);
        this.props.setToTokenBalance(0);
        this.props.setIsLoading(false);
        return;
      }

      this.props.setIsLoading(false);
    };

    if (
      ethereumWalletType === walletname &&
      ethereumWalletAddress !== "" &&
      avalancheWalletAddress === ""
    ) {
      this.props.setIsLoading(true);

      this.props.setAvalancheWalletAddress(ethereumWalletAddress);
      this.props.setAvalancheWalletType(ethereumWalletType);
      this.props.setToWallet(ethereumWalletAddress);

      this.setState({
        isConnectedToWallet: true,
        selectedWalletType: walletname,
        avalancheWalletAddress: ethereumWalletAddress,
      });
      // this.props.setToTokenBalance(fromTokenBalance);

      try {
        let balance;
        if (toChain === "ethereum") {
          balance = await getEthTokenBalanceInfoByExplorer(
            ethereumWalletAddress,
            network,
            tokenName
          );
        } else if (toChain === "avalanche") {
          balance = await getAvalancheTokenBalanceInfoByExplorer(
            ethereumWalletAddress,
            network,
            tokenName
          );
        } else if (toChain === "polygon") {
          balance = await getPolygonTokenBalanceInfoByExplorer(
            ethereumWalletAddress,
            network,
            tokenName
          );
        }
        this.props.setToTokenBalance(balance);
      } catch (e) {
        console.log("error ", e);
        this.props.setToTokenBalance(0);
      }

      this.props.setIsWalletEmptyAlertOpen(false);
      this.setState({ isAlertDialogOpen: false });
      this.props.setIsLoading(false);
    } else if (
      !EVM_SUPPORTED_WALLETS.includes(this.props.avalancheWalletType)
    ) {
      const provider = window?.ethereum;

      if (walletname === "walletConnect") {
        let localStorageSession = localStorage.getItem("walletconnect");
        if (localStorageSession) {
          localStorage.removeItem("walletconnect");
        }

        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        this.connector = new WalletConnectProvider({
          bridge: "https://bridge.walletconnect.org",
          rpc: {
            1: "https://rpc.ankr.com/eth",
            5: "https://rpc.ankr.com/eth_goerli",
            43113: "https://api.avax-test.network/ext/C/rpc",
            43114: "https://avalanche.public-rpc.com",
            137: "https://rpc.ankr.com/polygon",
            80001: "https://rpc.ankr.com/polygon_mumbai",
          },
          qrcode: QRCodeModalSimple,
          qrcodeModalOptions: {
            desktopLinks: ["exodus", "trust"],
            mobileLinks: ["metamask", "exodus", "trust"],
          },
        });

        //  Enable session (triggers QR Code modal)
        await this.connector.enable();

        let web3Provider = new ethers.providers.Web3Provider(this.connector);
        const walletChainId = web3Provider?.provider?.chainId;
        const isTrue = ischainAndIdSame(
          network,
          toChain,
          web3Provider?.provider?.chainId
        );

        const connectWalletName = web3Provider?.provider?.wc?.peerMeta?.name;

        if (connectWalletName === "Exodus Mobile" && !isTrue) {
          const param = getEVMChainHelper(network, toChain);
          try {
            const existingChainId = param[0].chainId;
            await this.connector.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: existingChainId }],
            });
          } catch (e) {
            console.log("error ", e);
          }
        } else if (walletChainId && !isTrue) {
          this.props.setIsLoading(false);
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "wrongChainError",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                10000
              );
            }
          );
          return;
        }

        web3Provider = new ethers.providers.Web3Provider(this.connector);

        that.props.setAvalancheWalletObject(web3Provider.getSigner());

        let wallet;

        if (
          web3Provider.provider.accounts &&
          web3Provider.provider.accounts.length > 0
        ) {
          wallet = web3Provider.provider.accounts[0];
          that.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            avalancheWalletAddress: web3Provider.provider.accounts[0],
          });
        }

        try {
          let balance;
          if (wallet) {
            if (toChain === "ethereum") {
              balance = await getEthTokenBalanceInfoByExplorer(
                wallet,
                network,
                tokenName,
                this.connector
              );
            } else if (toChain === "avalanche") {
              balance = await getAvalancheTokenBalanceInfoByExplorer(
                wallet,
                network,
                tokenName,
                this.connector
              );
            } else if (toChain === "polygon") {
              balance = await getPolygonTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                this.connector
              );
            }
            this.props.setToTokenBalance(balance);
            that.setState({ isConnectedToWallet: true });
            that.props.setAvalancheWalletAddress(wallet);
            that.props.setToWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setAvalancheWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "avalancheWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setToTokenBalance(0);
        }
      } else if (walletname === "metamask") {
        if (provider && provider?.coreProvider !== undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }
        // IF SOME OTHER EVM CHAIN WALLET EXISTS
        if (
          window?.ethereum === undefined ||
          !provider?.isMetaMask ||
          provider?.isExodus ||
          provider?.isCoin98
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }

        connectWithWallet(walletname);
      } else if (walletname === "coin98") {
        // IF SOME OTHER EVM CHAIN WALLET EXISTS
        if (!provider?.isCoin98) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coin98NotAvailable",
          });
          return;
        }

        connectWithWallet(walletname);
      } else if (walletname === "coreWallet") {
        if (provider === undefined || provider.coreProvider === undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletNotAvailable",
          });
          return;
        }
        if (
          (["43114", "43113"].includes(
            provider?.coreProvider?.networkVersion
          ) &&
            toChain === "ethereum") ||
          (["1", "5"].includes(provider?.coreProvider?.networkVersion) &&
            toChain === "avalanche") ||
          (["137", "80001"].includes(provider?.coreProvider?.networkVersion) &&
            toChain !== "polygon")
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletWrongChain",
          });
          return;
        }

        await connectWithWallet(walletname);
      }

      this.props.setIsWalletEmptyAlertOpen(false);
      this.setState({ isAlertDialogOpen: false });
      this.props.setIsLoading(false);
    } else if (this.props.avalancheWalletType === walletname) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletname,
      });
    }
  }

  /*
   *
   * TRON
   *
   */
  async handleSelectTronWalletButton(walletname) {
    const { network, tronWalletType } = this.props;
    const that = this;

    if (tronWalletType === "") {
      if (walletname === "tronLink") {
        // if (walletname === "tronLink") {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        try {
          const tronWeb = new TronWebNode({
            fullHost: "https://api.trongrid.io",
            eventServer: "https://api.trongrid.io",
          });

          // Check if the user has TronLink or Trust Wallet installed
          let addressTron = window?.tronWeb?.defaultAddress?.base58;
          let tronWebExt = window?.tronWeb;

          if (tronWebExt) {
            await tronWeb.setAddress(addressTron);
          } else {
            console.log("No TronLink or Trust Wallet installed");
          }

          that.props.setTronWalletObject(tronWebExt);

          if (addressTron) {
            that.setState({
              isConnectedToWallet: true,
              selectedWalletType: walletname,
              tronWalletAddress: addressTron,
            });
          }

          let balance;
          if (addressTron) {
            balance = await getTronUSDCBalanceByAbi(
              tronWeb,
              network,
              addressTron
            );

            this.props.setToTokenBalance(balance);
            that.setState({ isConnectedToWallet: true });
            that.props.setTronWalletAddress(addressTron);
            that.props.setToWallet(addressTron);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setTronWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "tronWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setToTokenBalance(0);
        }
      }
      this.props.setIsLoading(false);
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }
  async handleClickDisconnectButton() {
    if (this.props.toChain === "algorand") {
      let localStorageSession = localStorage.getItem("walletconnect");
      if (localStorageSession) {
        localStorage.removeItem("walletconnect");
      }
      if (this.props.algorandWalletType === "walletConnect") {
        if (this.connector) {
          this.connector.killSession();
        }
      }
      this.props.setAlgorandWalletType("");
      this.props.setAlgoWallet("");
    } else if (
      ["myAlgoConnect", "deflyConnect"].includes(this.props.algorandWalletType)
    ) {
      await this.props.setAlgorandWalletType("");
      this.handleClickWalletConnectButton();
    } else if (this.props.toChain === "solana") {
      this.props.solanaWalletObject.disconnect();
      await this.props.setSolanaWallet("");
    } else if (EVM_SUPPORTED_CHAINS.includes(this.props.toChain)) {
      if (this.props.avalancheWalletType === "metamask") {
        this?.connector?.killSession?.();
      }

      this.props.setAvalancheWalletObject("");
      this.props.setAvalancheWalletType("");
      this.props.setAvalancheWalletAddress("");
    } else if (this.props.toChain === TRON) {
      if (this.props.tronWalletType === "walletConnect") {
        if (this.connector) {
          this.connector?.killSession();
          this.connector?.disconnect();
        }
      }
      this.props.setTronWalletObject("");
      this.props.setTronWalletType("");
      this.props.setTronWalletAddress("");
    }

    this.setState({
      avalancheWalletAddress: "",
      algorandWalletAddress: "",
      solanaWalletAddress: "",
      tronWalletAddress: "",
    });

    this.setState({
      isDisconnectDialogOpen: false,
      isWalletSelectionModalOpen: false,
    });
    this.props.setToNativeBalance(null);
    this.props.setToTokenBalance(null);
    this.props.setIsWalletEmptyAlertOpen(true);
    this.props.setSolanaOptInAlertOpen(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
    this.props.setIsAlgoWalletOptIn(false);
  }

  async checkAssetOptIn(wallet, asset) {
    const result = await isOptedIn(this.props.network, wallet, asset);
    this.props.setxSolOptIn(result);
    return result;
  }

  async handleClickAssetOptInButton(asset) {
    const { algorandWalletType } = this.props;
    try {
      this.props.setIsWaitingForOptInDialogOpen(true);
      const url = getServerURL(this.props.network);
      const algodClient = new algosdk.Algodv2("", url, "");
      let params = await algodClient.getTransactionParams().do();
      params.fee = 1000;
      params.flatFee = true;
      let sender = this.state.algorandWalletAddress;
      let recipient = sender;
      let amount = 0;
      let note = algosdk.encodeObj(
        JSON.stringify({
          system: "Opt-in xSOL token",
          date: `${new Date()}`,
        })
      );

      let txn;
      let rawSignedTxn;
      let sentTxn;

      txn = {
        type: "axfer",
        assetIndex: Number(asset),
        from: sender,
        to: recipient,
        amount: amount,
        note: note,
        closeRemainderTo: undefined,
        revocationTarget: undefined,
      };

      if (algorandWalletType === "myAlgoConnect") {
        txn = {
          ...params,
          ...txn,
        };
        rawSignedTxn = await this.algorandWallet.signTransaction(txn);
        sentTxn = await algodClient.sendRawTransaction(rawSignedTxn.blob).do();
      } else if (algorandWalletType === "deflyConnect") {
        txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: params,
          ...txn,
        });
        let txnMap = [[{ txn: txn }]];
        rawSignedTxn = await this.algorandWallet.signTransaction(txnMap);
        sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
      } else {
        txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: params,
          type: "axfer",
          assetIndex: Number(asset),
          from: sender,
          to: recipient,
          amount: amount,
          note: note,
          closeRemainderTo: undefined,
          revocationTarget: undefined,
        });
        const encodedTxn = algosdk.encodeUnsignedTransaction(txn);
        const encodedTxnBuffer = Buffer.from(encodedTxn).toString("base64");
        const requestParams = [
          [{ txn: encodedTxnBuffer, message: "Opt in to xSOL Token" }],
        ];
        const request = formatJsonRpcRequest("algo_signTxn", requestParams);
        const result = await this.algorandWallet?.sendCustomRequest(request);
        rawSignedTxn = result?.map((element) => {
          return element
            ? new Uint8Array(Buffer.from(element, "base64"))
            : null;
        });
        sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
      }
      let txId = sentTxn.txId;
      console.log("Wallet's Opt-in transaction sent to Algorand! ", txId);
      await this.waitForConfirmation(algodClient, txId);
      let ptx = await algodClient.pendingTransactionInformation(txId).do();
      const noteArrayFromTxn = ptx.txn.txn.note;
      const receivedNote = Buffer.from(noteArrayFromTxn).toString("utf8");
      console.log(
        "Note from confirmed opt-in to xSOL token transaction: ",
        receivedNote
      );
      this.props.setIsXsolOptInAlertOpen(false);
      this.props.setIsOptInSuccessfulAlertOpen(true);
      setTimeout(() => {
        this.props.setIsOptInSuccessfulAlertOpen(false);
      }, 5000);
      this.props.setxSolOptIn(true);
      this.props.setIsWaitingForOptInDialogOpen(false);
    } catch (error) {
      console.error(error);
      this.props.setIsWaitingForOptInDialogOpen(false);
    }
  }

  async waitForConfirmation(algodClient, txId) {
    console.log("waiting for transaction: ", txId);
    let response = await algodClient.status().do();
    let lastRound = response["last-round"];
    while (true) {
      const pendingInfo = await algodClient
        .pendingTransactionInformation(txId)
        .do();
      if (
        pendingInfo["confirmed-round"] !== null &&
        pendingInfo["confirmed-round"] > 0
      ) {
        console.log(
          "Transaction " +
            txId +
            " confirmed in round " +
            pendingInfo["confirmed-round"]
        );
        break;
      }
      lastRound++;
      await algodClient.statusAfterBlock(lastRound).do();
    }
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
    this.props.setSolanaOptInAlertOpen(false);
  }

  handleCloseAlert() {
    this.setState({
      isAlertDialogOpen: false,
    });
    this.props.setIsOptInSuccessfulAlertOpen(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setSolanaOptInAlertOpen(false);
    this.props.setIsWaitingForOptInDialogOpen(false);
  }

  handleModal() {
    this.setState({ isModalOpen: true });
  }
  handleModalClose() {
    this.setState({ isModalOpen: false });
  }
  render() {
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      avalancheWalletAddress,
      tronWalletAddress,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      algoWalletType,
      isAlertDialogOpen,
      isWalletQrModalOpen,
      errorType,
      xSolAssetId,
      isModalOpen,
      USDCAssetId,
    } = this.state;
    const {
      classes,
      isDark,
      network,
      fromChain,
      toChain,
      fromWallet,
      solanaWalletType,
      ethereumWalletType,
      algorandWalletType,
      avalancheWalletType,
      tronWalletType,
      toToken,
      toNativeBalance,
      toTokenBalance,
      isOptInSuccessfulAlertOpen,
      isOptInAlertOpen,
      isSolanaOptInAlertOpen,
      notEnoughNativeBalanceAlertOpen,
      isXsolOptInAlertOpen,
    } = this.props;

    const that = this;
    const selectedToWalletAddress =
      toChain === "algorand"
        ? algorandWalletAddress
        : toChain == "solana"
        ? solanaWalletAddress
        : toChain == TRON
        ? tronWalletAddress
        : avalancheWalletAddress;

    const balance =
      {
        algorand: `ALGO Balance: ${Number(toNativeBalance).toPrecision(7)}`,
        solana: `SOL Balance: ${Number(toNativeBalance).toPrecision(9)}`,
      }[toChain] || "";

    const logo =
      {
        algorand: (
          <img style={{ width: 24, marginLeft: 5 }} src={algoLogo} alt="ALGO" />
        ),
        solana: (
          <img
            style={{ width: 20, marginLeft: 5 }}
            src={solanaLogo}
            alt="SOL"
          />
        ),
      }[toChain] || "";
    return (
      <>
        <WalletModal open={isModalOpen} handleClose={this.handleModalClose}>
          <Heading pd="0px 20px" text={"Choose and connect your wallet:"} />
          <ToWallets
            chain={toChain}
            fromChain={fromChain}
            toChain={toChain}
            solanaWalletType={solanaWalletType}
            ethereumWalletType={ethereumWalletType}
            algorandWalletType={algorandWalletType}
            tronWalletType={tronWalletType}
            handleModalClose={this.handleModalClose}
            handleClickWalletConnectButton={this.handleClickWalletConnectButton}
            handleClickConnectMyAlgoWalletButton={
              this.handleClickConnectMyAlgoWalletButton
            }
            handleSelectSolanaWalletButton={this.handleSelectSolanaWalletButton}
            handleSelectEvmWalletButton={this.handleSelectAvalancheWalletButton}
            handleSelectTronWalletButton={this.handleSelectTronWalletButton}
          />
        </WalletModal>
        {isAlertDialogOpen && (
          <Alert
            style={{
              color: "white",
            }}
            className={classes.alertBgTransparent}
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{
              message: classes.alertMessage,
              action: classes.alertAction,
            }}
          >
            {errorText(this.state.errorType)}
          </Alert>
        )}
        <ToWalletDialogs
          classes={classes}
          isDark={isDark}
          handleCloseDialog={this.handleCloseDialog}
          isWalletConnectionModalOpen={isWalletConnectionModalOpen}
          toChain={toChain}
          algorandWalletType={algorandWalletType}
          solanaWalletType={solanaWalletType}
          avalancheWalletType={avalancheWalletType}
          tronWalletType={tronWalletType}
          handleClickConnectButton={this.handleClickConnectButton}
          isDisconnectDialogOpen={isDisconnectDialogOpen}
          handleClickDisconnectButton={this.handleClickDisconnectButton}
        />

        <TextField
          id="wallet"
          label={
            (toChain === "algorand" && algorandWalletAddress === "") ||
            (toChain === "solana" && solanaWalletAddress === "") ||
            (EVM_SUPPORTED_CHAINS.includes(toChain) &&
              avalancheWalletAddress === "") ||
            (toChain === TRON && tronWalletAddress === "") ? (
              <Typography
                variant="body1"
                style={{
                  fontSize: "12px",
                  color: "#5E14A8",
                  fontFamily: "ProximaNova",
                }}
              >
                Connect your wallet...
              </Typography>
            ) : (
              "Selected wallet"
            )
          }
          variant="outlined"
          value={selectedToWalletAddress}
          className={classes.addressField}
          margin="normal"
          onChange={(event) => {
            toChain === "algorand"
              ? this.setState({ algorandWalletAddress: event.target.value })
              : toChain == "solana"
              ? this.setState({ solanaWalletAddress: event.target.value })
              : toChain == TRON
              ? this.setState({ tronWalletAddress: event.target.value })
              : this.setState({ avalancheWalletAddress: event.target.value });
          }}
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.addressInput,
            },
            endAdornment: (
              <>
                <InputWalletsLogo
                  classes={classes}
                  toChain={toChain}
                  toToken={toToken}
                  fromChain={fromChain}
                  fromWallet={fromWallet}
                  algorandWalletAddress={algorandWalletAddress}
                  ethereumWalletType={ethereumWalletType}
                  algorandWalletType={algorandWalletType}
                  algoWalletType={algorandWalletType}
                  solanaWalletAddress={solanaWalletAddress}
                  solanaWalletType={solanaWalletType}
                  avalancheWalletType={avalancheWalletType}
                  avalancheWalletAddress={avalancheWalletAddress}
                  tronWalletType={tronWalletType}
                  tronWalletAddress={tronWalletAddress}
                  handleClickWalletConnectButton={
                    this.handleClickWalletConnectButton
                  }
                  handleClickConnectMyAlgoWalletButton={
                    this.handleClickConnectMyAlgoWalletButton
                  }
                  handleModal={this.handleModal}
                  handleSelectSolanaWalletButton={
                    this.handleSelectSolanaWalletButton
                  }
                  handleSelectAvalancheWalletButton={
                    this.handleSelectAvalancheWalletButton
                  }
                  handleSelectEvmWalletButton={
                    this.handleSelectAvalancheWalletButton
                  }
                  handleSelectTronWalletButton={
                    this.handleSelectTronWalletButton
                  }
                />
              </>
            ),
          }}
          inputlabel={{
            root: classes.inputLabel,
          }}
          InputLabelProps={{
            shrink: false,
            style: { color: isDark ? "#fff" : "#000" },
          }}
        />
        {((toChain === "algorand" && algorandWalletAddress !== "") ||
          (toChain === "solana" && solanaWalletAddress !== "") ||
          (EVM_SUPPORTED_CHAINS.includes(toChain) &&
            avalancheWalletAddress !== "") ||
          (toChain === TRON && tronWalletAddress !== "")) && (
          <>
            {balance && logo ? (
              <GradientBox>
                <Typography
                  className={classes.balance}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {balance}
                  {logo}
                </Typography>
              </GradientBox>
            ) : null}

            <GradientBox>
              <Typography className={classes.balance}>
                {(toToken === "xALGO" ||
                  toToken === "xSOL" ||
                  toToken === "USDC") &&
                  `Your token balance:`}
              </Typography>
              <Typography
                variant="h6"
                className={classes.balance}
                style={{ fontWeight: "800" }}
              >
                {Number(toTokenBalance || 0)} {toToken}
              </Typography>
            </GradientBox>
          </>
        )}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            marginTop: "20px",
            gap: "10px",
          }}
        >
          {isOptInSuccessfulAlertOpen && (
            <Alert onClose={this.handleCloseAlert}>
              Successfully opted-in to {toToken === "USDC" ? "USDC" : "xSOL"}{" "}
              token token
            </Alert>
          )}
          {isOptInAlertOpen && (
            <Alert onClose={this.handleCloseAlert}>
              Check your Optin status for {toToken}, you cannot proceed without
              opting into receiving Asset!
            </Alert>
          )}

          {!notEnoughNativeBalanceAlertOpen && isXsolOptInAlertOpen && (
            //  {/* {true && (  */}
            <Alert
              action={
                <ButtonBase
                  onClick={() => {
                    this.handleClickAssetOptInButton(
                      this.props.toToken === "USDC" ? USDCAssetId : xSolAssetId
                    );
                  }}
                >
                  OptIn to {this.props.toToken === "USDC" ? "USDC" : "xSOL"}
                </ButtonBase>
              }
            >
              <Typography variant="body1" style={{ fontSize: "13px" }}>
                Your selected Algorand wallet is not opted-into{" "}
                {this.props.toToken === "USDC" ? "USDC" : "xSOL"} token.
              </Typography>
            </Alert>
          )}
          {!notEnoughNativeBalanceAlertOpen && isSolanaOptInAlertOpen && (
            <Alert
              action={
                <ButtonBase
                  onClick={() => {
                    that.props.setIsWaitingForOptInDialogOpen(true);
                    optInxAlgo(
                      that.props.network,
                      that.props.connection,
                      that.props.solanaWalletObject,
                      that.props.solanaAssetInfo?.mint,
                      that
                    );
                  }}
                >
                  Create {this.props.fromToken === "USDC" ? "USDC" : "xALGO"}{" "}
                  ATA
                </ButtonBase>
              }
            >
              <Typography variant="outlined" style={{ fontSize: "13px" }}>
                Your selected Solana wallet does not have an associated account
                for {this.props.fromToken === "USDC" ? "USDC" : "xALGO"}
              </Typography>
            </Alert>
          )}
        </Box>
      </>
    );
  }
}

ToWalletStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  connection: PropTypes.object.isRequired,
  isSolanaOptInAlertOpen: PropTypes.bool.isRequired,
  setSolanaOptInAlertOpen: PropTypes.func.isRequired,
  isXsolOptInAlertOpen: PropTypes.bool.isRequired,
  setIsXsolOptInAlertOpen: PropTypes.func.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
  toWallet: PropTypes.string.isRequired,
  setToWallet: PropTypes.func.isRequired,
  toToken: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  algorandWalletAddress: PropTypes.string.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  setIsWalletEmptyAlertOpen: PropTypes.func.isRequired,
  isSolanaOptIn: PropTypes.bool.isRequired,
  setIsSolanaOptIn: PropTypes.func.isRequired,
};
const _ToWalletStep = memo(ToWalletStep);
export default withStyles(styles)(_ToWalletStep);
