import { getServerURL } from "../algoFunctions/agloConnectionPublic";
export async function isOptedIn(network, wallet, asset) {
  const url = getServerURL(network) + `/v2/accounts/${wallet}`;
  const data = await window
    .fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json());

  return Boolean(
    data?.assets.some((a) => Number(a["asset-id"]) === Number(asset))
  );
}
