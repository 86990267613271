import axios from "axios";
import { TestToken__factory } from "glitter-evm-contracts";
import {
  getAvalanchAssetInfo,
  getAvalancheExplorer,
  getEthAssetInfo,
  getEthExplorer,
  getPolygonAssetInfo,
  getPolygonExplorer,
} from "./connection";

const SNOWTRACE_API_KEY = process.env.REACT_APP_SNOWTRACE_API_KEY;
const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY;
const REACT_APP_POLYGONSCAN = process.env.REACT_APP_POLYGONSCAN_API_KEY;

const getBalance = async (token, walletAddress, provider) => {
  const tkn = TestToken__factory.connect(token.asset_id, provider);
  const balance = (await tkn.balanceOf(walletAddress)).toString();
  return Number(balance) / 10 ** parseInt(token.decimal);
};

export const getEthTokenBalanceInfo = async (
  walletAddress,
  network,
  tokenName,
  provider
) => {
  const token = getEthAssetInfo(network, tokenName);
  return await getBalance(token, walletAddress, provider);
};
export const getAvalanceTokenBalanceInfo = async (
  walletAddress,
  network,
  tokenName,
  provider
) => {
  const token = getAvalanchAssetInfo(network, tokenName);
  return await getBalance(token, walletAddress, provider);
};
export const getPolygonTokenBalanceInfo = async (
  walletAddress,
  network,
  tokenName,
  provider
) => {
  const token = getPolygonAssetInfo(network, tokenName);
  return await getBalance(token, walletAddress, provider);
};

export const getEthTokenBalanceInfoByExplorer = async (
  walletAddress,
  network,
  tokenName
) => {
  const token = getEthAssetInfo(network, tokenName);
  const EXPLORER = getEthExplorer(network);

  let url = `${EXPLORER}/api?module=account&action=tokenbalance&&address=${walletAddress}&contractaddress=${token.asset_id}&tag=latest&apikey=${ETHERSCAN_API_KEY}`;
  try {
    let response = await axios.get(url);
    let balance = response.data.result;
    console.log("token balance ", balance);
    return balance / 10 ** parseInt(token.decimal);
  } catch (error) {
    console.log("Error while getting ethWalletTokenBalanceInfo ", error);
  }

  return 0;
};
export const getAvalancheTokenBalanceInfoByExplorer = async (
  walletAddress,
  network,
  tokenName
) => {
  const token = getAvalanchAssetInfo(network, tokenName);
  const EXPLORER = getAvalancheExplorer(network);

  let url = `${EXPLORER}/api?module=account&action=tokenbalance&&address=${walletAddress}&contractaddress=${token.asset_id}&tag=latest&apikey=${SNOWTRACE_API_KEY}`;
  try {
    let response = await axios.get(url);
    let balance = response.data.result;
    console.log("token balance ", balance);
    return balance / 10 ** parseInt(token.decimal);
  } catch (error) {
    console.log("Error while getting ethWalletTokenBalanceInfo ", error);
  }

  return 0;
};

export const getPolygonTokenBalanceInfoByExplorer = async (
  walletAddress,
  network,
  tokenName
) => {
  const token = getPolygonAssetInfo(network, tokenName);
  const EXPLORER = getPolygonExplorer(network);

  let url = `${EXPLORER}/api?module=account&action=tokenbalance&&address=${walletAddress}&contractaddress=${token.asset_id}&tag=latest&apikey=${REACT_APP_POLYGONSCAN}`;
  try {
    let response = await axios.get(url);
    let balance = response.data.result;
    console.log("token balance ", balance);
    return balance / 10 ** parseInt(token.decimal);
  } catch (error) {
    console.log("Error while getting ethWalletTokenBalanceInfo ", error);
  }

  return 0;
};
