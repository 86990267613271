const styles = (theme) => ({
  qrImage: {
    width: "256px",
    height: "256px",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
      borderRadius: "13px",
    },
  },
  addressInput: {
    height: "22px",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "500",
    marginRight: "75px",
    textOverflow: "ellipsis",
  },
  addressInputDark: {
    color: "#fff",
    borderColor: "#ffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6em",
    },
  },
  inputLabel: {
    color: "#5E14A8",
    position: "absolute",
    top: "-2px",
  },
  title: {
    float: "left",
    marginTop: "1%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2%",
      textAlign: "left",
      fontSize: 16,
    },
  },
  titleXs: {
    float: "left",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  btn: {
    fontSize: 11,
    display: "inline",
    color: "#313149",
    padding: "11px",
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 10,
    marginBottom: 20,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  walletImage: {
    verticalAlign: "bottom",
    padding: "0px !important",
    marginRight: "5px !important",
    marginLeft: "5px !important",
    cursor: "pointer",
    width: "23px !important",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      marginRight: "2px !important",
      marginLeft: "2px !important",
      width: "20px !important",
    },
  },
  walletHidden: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  dialogPaper: {
    background: theme.palette.update3Palette.modalGradient,
    color: "#313030",
    backdropFilter: "blur(21px)",
  },
  iconBtnRoot: {
    padding: 0,
  },
  customBadge: {
    color: "#000000",
    backgroundColor: "#62f53d",
  },
  gCustomBadge: {
    color: "#ffffff",
    backgroundColor: "#f76fc1",
  },
  myAlert: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    borderColor: "var(--clr-purple)",
    "& .MuiAlert-icon": {
      color: "var(--clr-purple)",
    },
  },
  balance: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "500",
    textAlign: "left",
    color: theme.palette.update3Palette.primary,
  },
  textFieldBadgeAnchor2: {
    top: "-2px",
    right: 0,
    padding: 0,
  },
  textFieldBadgeAnchor1: {
    top: "-2px",
    right: 8,
    padding: 0,
  },
  iconRoot: {
    fontSize: 12,
  },
  buttonsDiv: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
  },
  alertBgTransparent: {
    ...theme.palette.update2Palette.bgGradient2BcDrop,
  },
  alertAction: {
    "&.MuiAlert-action": {
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
  },
  alertRoot: {
    "&.MuiAlert-root": {
      [theme.breakpoints.down("xs")]: {
        paddingRight: 4,
        paddingLeft: 4,
      },
    },
  },
  alertButton: {
    display: "none",
    color: "#313149",
    padding: "7px",
    border: "1px solid #f76fc1",
    borderRadius: 50,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      float: "right",
      display: "block",
    },
  },
  alertBtn: {
    color: "#313149",
    padding: "7px",
    border: "1px solid #f76fc1",
    borderRadius: 50,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
});

export default styles;
