import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import myAlgowallet from "../assets/wallets/myalgo.svg";
import peraWallet from "../assets/wallets/PeraAlgo.svg";
import WalletConnectLogo from "../assets/images/WalletConnectIcon2.svg";
import exodusWalletImg from "../assets/images/Exodus_symbol.svg";
import deflyImg from "../assets/images/defly wallet icon.png";
import { WALLET_PROVIDERS } from "../solanaFunctions";
import { EVM_SUPPORTED_CHAINS } from "../ethereumFunctions/constants";
import { ETHEREUM_WALLET_PROVIDERS } from "../ethereumFunctions/walletHandler";
import { capitalize, isMobile } from "../utils/utils";
import { TRON } from "../tronFunction/constants";
import { TRON_WALLET_PROVIDERS } from "../tronFunction/walletHandler";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    background: "none !important",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rootContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    background: "none !important",
    flexWrap: "wrap",
  },
  boxItem: {
    margin: "10px 15px",
    borderRadius: "50%",
  },
  boxItemImg: {
    margin: "auto",
    cursor: "pointer",
    borderRadius: "50%",
    height: "70px",
    width: "70px",
    [theme.breakpoints.down("xs")]: {
      height: "50px",
      width: "50px",
    },
  },
  poweredByText: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "bold",
    padding: "10px 0px",
    fontSize: "14px",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
});

function FromWallets({
  classes,
  chain,
  ethereumWalletType,
  fromChain,
  toChain,
  handleModalClose,
  solanaWalletType,
  handleClickWalletConnectButton,
  handleClickConnectMyAlgoWalletButton,
  handleSelectSolanaWalletButton,
  handleSelectEvmWalletButton,
  handleSelectTronWalletButton,
}) {
  const ImgComp = ({ index, img, onClickHandler }) => (
    <img
      src={img}
      className={classes.boxItemImg}
      onClick={onClickHandler}
      width="70"
      height="70"
    />
  );
  return (
    <Box className={classes.root}>
      <div className={classes.rootContainer}>
        {chain === "algorand"
          ? algoWallets.map((x, i) => (
              <div
                item
                key={i}
                md={4}
                lg={4}
                xs={4}
                className={classes.boxItem}
              >
                <ImgComp
                  index={i}
                  img={x.img}
                  onClickHandler={() => {
                    if (x.name === "MyAlgo" || x.name === "Defly") {
                      handleModalClose();
                      handleClickConnectMyAlgoWalletButton(x.name);
                    } else {
                      handleModalClose();
                      handleClickWalletConnectButton(x.name);
                    }
                  }}
                />
                <Typography className={classes.poweredByText}>
                  {capitalize(x.name)}
                </Typography>
              </div>
            ))
          : chain === "solana"
          ? WALLET_PROVIDERS.map((wallet, i) => {
              if (isMobile && wallet.name === "Phantom") return;

              return (
                <div
                  item
                  key={i}
                  md={4}
                  lg={4}
                  xs={4}
                  className={classes.boxItem}
                >
                  <ImgComp
                    img={wallet.icon}
                    onClickHandler={() => {
                      handleModalClose();
                      handleSelectSolanaWalletButton(wallet.name);
                    }}
                  />

                  <Typography className={classes.poweredByText}>
                    {capitalize(wallet.name)}
                  </Typography>
                </div>
              );
            })
          : EVM_SUPPORTED_CHAINS.includes(chain)
          ? ETHEREUM_WALLET_PROVIDERS.map((wallet, i) => {
              if (
                isMobile &&
                ["metamask", "coin98", "coreWallet"].includes(wallet.name)
              )
                return;
              if (fromChain === "polygon" && wallet.name === "coreWallet")
                return;

              return (
                <div
                  item
                  key={i}
                  md={4}
                  lg={4}
                  xs={4}
                  className={classes.boxItem}
                >
                  <ImgComp
                    img={wallet?.icon}
                    onClickHandler={() => {
                      handleModalClose();
                      handleSelectEvmWalletButton(wallet.name);
                    }}
                  />
                  <Typography className={classes.poweredByText}>
                    {capitalize(wallet.name)}
                  </Typography>
                </div>
              );
            })
          : chain === TRON
          ? TRON_WALLET_PROVIDERS.map((wallet, i) => {
              // if (isMobile && ["tronLink"].includes(wallet.name)) return;

              return (
                <div
                  item
                  key={i}
                  md={4}
                  lg={4}
                  xs={4}
                  className={classes.boxItem}
                >
                  <ImgComp
                    img={wallet?.icon}
                    onClickHandler={() => {
                      handleModalClose();
                      handleSelectTronWalletButton(wallet.name);
                    }}
                  />
                  <Typography className={classes.poweredByText}>
                    {capitalize(wallet.name)}
                  </Typography>
                </div>
              );
            })
          : ""}
      </div>
    </Box>
  );
}

const algoWallets = [
  {
    name: "Defly",
    img: deflyImg,
    url: "https://defly.app/",
  },
  {
    name: "Pera Algo",
    img: peraWallet,
    url: "https://pera.money/",
  },
  {
    name: "Exodus Mobile",
    img: exodusWalletImg,
    url: "https://www.exodus.com/",
  },
  {
    name: "walletConnect",
    img: WalletConnectLogo,
    url: "https://walletconnect.com/",
  },
];

export default withStyles(styles)(FromWallets);
