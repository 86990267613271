import WalletConnectLogo from "../../src/assets/images/WalletConnectIcon2.svg";
import tronLinkLogo from "../../src/assets/images/Tronlink.png";
import tronDetailedAbiJson from "../utils/trc20DetailedAbi.json";
import { getTronAssetInfo } from "./connection";
import BN from "bn.js";
import { getUsdcReceiverAddress } from "../ethereumFunctions";
import BigNumber from "bignumber.js";

export const TRON_WALLET_PROVIDERS = [
  {
    name: "tronLink",
    url: "https://www.tronlink.org/",
    icon: tronLinkLogo,
  },
  // {
  //   name: "walletConnect",
  //   url: "https://walletconnect.com/",
  //   icon: WalletConnectLogo,
  // },
];

const connectWallet = async (selectedName, network, chain) => {};

export async function ethereumWalletHandler(selectedName, network, chain) {}

export const getTronUSDCBalanceByAbi = async (
  tronWeb,
  network,
  userAddress
) => {
  const assetInfo = getTronAssetInfo(network, "USDC");
  const contract = await tronWeb.contract(
    tronDetailedAbiJson.abi,
    assetInfo?.asset_id
  );

  let balance;
  try {
    balance = await contract.balanceOf(userAddress).call();
    let balance = new BigNumber(balance / 10 ** assetInfo?.decimal);
    return Number(balance.toFixed(6).toString());
  } catch (error) {
    console.log("Error while getting tronWalletTokenBalanceInfo ", error);
  }

  return 0;
};

export async function bridgeTron(
  tronWeb,
  sourceAddress,
  sourceChain,
  destinationChain,
  destinationAddress,
  _amount,
  network
) {
  let depositAddress = getUsdcReceiverAddress(sourceChain, network);
  let tokenInfo = getTronAssetInfo(network, "USDC");
  const tokenAddress = tokenInfo?.asset_id;
  let amount = new BN(_amount * 10 ** tokenInfo?.decimal);

  const trc20Params = [
    { type: "address", value: tronWeb.address.fromHex(depositAddress) },
    { type: "uint256", value: amount.toString() },
  ];

  const transfer = {
    destination: {
      chain: destinationChain,
      address: destinationAddress,
    },
    amount: amount.toString(),
  };

  const data = JSON.stringify(transfer);
  const options = {
    feeLimit: 100_000_000,
  };

  try {
    let txn = await tronWeb.transactionBuilder.triggerSmartContract(
      tokenAddress,
      "transfer(address,uint256)",
      options,
      trc20Params,
      sourceAddress
    );

    txn = await tronWeb.transactionBuilder.addUpdateData(
      txn.transaction,
      data,
      "utf8"
    );

    let signedtxn;
    try {
      signedtxn = await tronWeb.trx.sign(txn);
    } catch (error) {
      console.log("Error while signing tx ", error);
    }
    try {
      const txDetails = await tronWeb.trx.sendRawTransaction(signedtxn);
      return txDetails;
    } catch (error) {
      console.log("Error while sending tx ", error);
    }
    return {};
  } catch (error) {
    console.log("Error ", error);
    return {};
  }
}

export async function isEnoughEnergy(tronWeb, address) {
  const resources = await tronWeb.trx.getAccountResources(address);

  console.log("resources info", JSON.stringify(resources));

  if (!("EnergyLimit" in resources) || !("EnergyUsed" in resources)) {
    console.log("[TronReleaseHandler] Unable to retrieve account resources");
    return false;
  }

  if (resources.EnergyLimit - resources.EnergyUsed < 30_000) {
    console.log(
      "[TronReleaseHandler] Release account does not have enough energy"
    );

    return false;
  }

  return true;
}
