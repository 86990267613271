import React from "react";
import {
  Tooltip,
  Typography,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Done, Close } from "@material-ui/icons";

import myAlgowallet from "../../../../assets/images/myAlgoWallet.png";
import deflyWalletImg from "../../../../assets/images/defly wallet icon.png";
import peraWallet from "../../../../assets/images/pera.png";
// import ChooseWallet from "../../../../assets/images/Choose.svg";
import { WALLET_PROVIDERS } from "../../../../solanaFunctions";
import { ChooseWallet } from "../../../../componentsReuse/NutsAndBolts";
import exodusWalletImg from "../../../../assets/images/Exodus_symbol.svg";
import { EVM_SUPPORTED_CHAINS } from "../../../../ethereumFunctions/constants";
import { ETHEREUM_WALLET_PROVIDERS } from "../../../../ethereumFunctions/walletHandler";
import { TRON_WALLET_PROVIDERS } from "../../../../tronFunction/walletHandler";
import { isMobile } from "../../../../utils/utils";
import { TRON } from "../../../../tronFunction/constants";

const ToWalletDialogs = ({
  classes,
  isDark,
  handleCloseDialog,
  toChain,
  algorandWalletType,
  solanaWalletType,
  handleClickConnectButton,
  isWalletConnectionModalOpen,
  isDisconnectDialogOpen,
  avalancheWalletType,
  tronWalletType,
  handleClickDisconnectButton,
}) => {
  return (
    <>
      <Dialog
        open={isWalletConnectionModalOpen}
        onClose={handleCloseDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
        aria-labelledby="wallet-connection-dialog"
      >
        <DialogTitle>
          <IconButton
            style={{ color: "#ffffff", float: "right" }}
            onClick={handleCloseDialog}
            className={classes.iconBtnRoot}
          >
            <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            style={{
              color: "white",
            }}
          >
            You are already connected to{" "}
            {connectionType({
              toChain,
              algorandWalletType,
              solanaWalletType,
              avalancheWalletType,
              tronWalletType,
            })}
            , if you proceed you will be disconnected from{" "}
            {connectionType({
              toChain,
              algorandWalletType,
              solanaWalletType,
              avalancheWalletType,
              tronWalletType,
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleClickConnectButton}>Connect</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDisconnectDialogOpen}
        onClose={handleCloseDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
        aria-labelledby="disconnect-wallet-dialog"
      >
        <DialogTitle>
          <IconButton
            style={{ color: "#ffffff", float: "right" }}
            onClick={handleCloseDialog}
            className={classes.iconBtnRoot}
          >
            <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            style={{
              color: "white",
            }}
          >
            You are already connected to{" "}
            {connectionType({
              toChain,
              algorandWalletType,
              solanaWalletType,
              avalancheWalletType,
              tronWalletType,
            })}
            ,do you want to disconnect it?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleClickDisconnectButton}>Disconnect</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const InputWalletsLogo = ({
  classes,
  toChain,
  fromChain,
  toToken,
  fromWallet,
  handleModal,
  algoWalletType,
  algorandWalletAddress,
  algorandWalletType,
  solanaWalletAddress,
  avalancheWalletType,
  avalancheWalletAddress,
  ethereumWalletType,
  solanaWalletType,
  tronWalletType,
  tronWalletAddress,
  handleClickWalletConnectButton,
  handleClickConnectMyAlgoWalletButton,
  handleSelectSolanaWalletButton,
  handleSelectEvmWalletButton,
  handleSelectTronWalletButton,
}) => {
  const closeButtonSx = {
    position: "absolute",
    right: "0px",
    zIndex: "9",
    minWidth: "40px",
  };
  const handleSolanaDisconnect = () => {
    if (solanaWalletAddress !== "") {
      const wallet = WALLET_PROVIDERS.find(
        (wallet) => wallet.name === solanaWalletType
      );
      wallet.name && handleSelectSolanaWalletButton(wallet.name);
    }
  };

  if (toChain === "algorand") {
    return (
      <>
        {algorandWalletAddress !== "" &&
        algorandWalletType === "walletConnect" &&
        algoWalletType === "Exodus Mobile" ? (
          <Tooltip title="Disconnect">
            <Button
              style={closeButtonSx}
              onClick={() => handleClickWalletConnectButton("Exodus Mobile")}
            >
              <Close />
            </Button>
          </Tooltip>
        ) : (
          <></>
        )}
        {algorandWalletAddress !== "" &&
        algorandWalletType === "walletConnect" &&
        algoWalletType === "Pera Wallet" ? (
          <Tooltip title="Disconnect">
            <Button
              style={closeButtonSx}
              onClick={handleClickWalletConnectButton}
            >
              <Close />
            </Button>
          </Tooltip>
        ) : (
          <></>
        )}
        {algorandWalletAddress !== "" &&
        ["myAlgoConnect", "deflyConnect"].includes(algorandWalletType) ? (
          <Tooltip title="Disconnect">
            <Button
              style={closeButtonSx}
              onClick={handleClickConnectMyAlgoWalletButton}
            >
              <Close />
            </Button>
          </Tooltip>
        ) : (
          <></>
        )}
        {algorandWalletAddress === "" && (
          <ChooseWallet
            disabled={toToken === "" || !fromWallet}
            onClick={handleModal}
          />
        )}
      </>
    );
  } else if (toChain === "solana") {
    return (
      <>
        {solanaWalletAddress !== "" ? (
          <Tooltip title="Disconnect">
            <Button style={closeButtonSx} onClick={handleSolanaDisconnect}>
              <Close />
            </Button>
          </Tooltip>
        ) : (
          <ChooseWallet
            disabled={toToken === "" || !fromWallet}
            onClick={handleModal}
          />
        )}
      </>
    );
  } else if (EVM_SUPPORTED_CHAINS.includes(toChain)) {
    return (
      <>
        {ETHEREUM_WALLET_PROVIDERS.map((wallet, index) => {
          return avalancheWalletAddress !== "" &&
            avalancheWalletType === wallet.name ? (
            <Tooltip title="Disconnect">
              <Button
                style={closeButtonSx}
                onClick={() => handleSelectEvmWalletButton(wallet.name)}
              >
                <Close />
              </Button>
            </Tooltip>
          ) : (
            <></>
          );
        })}
        {avalancheWalletAddress === "" && (
          <ChooseWallet
            disabled={toToken === "" || !fromWallet}
            onClick={handleModal}
          />
        )}
      </>
    );
  } else if (toChain === TRON) {
    return (
      <>
        {TRON_WALLET_PROVIDERS.map((wallet, index) => {
          if (isMobile && ["tronLink"].includes(wallet.name)) return;

          return tronWalletAddress !== "" && tronWalletType === wallet.name ? (
            <Tooltip title="Disconnect">
              <Button
                style={closeButtonSx}
                onClick={() => handleSelectTronWalletButton(wallet.name)}
              >
                <Close />
              </Button>
            </Tooltip>
          ) : (
            <></>
          );
        })}
        {tronWalletAddress === "" && (
          <ChooseWallet
            disabled={toToken === "" || !fromWallet}
            onClick={handleModal}
          />
        )}
      </>
    );
  }
};

const connectionType = ({
  toChain,
  algorandWalletType,
  solanaWalletType,
  avalancheWalletType,
  tronWalletType,
}) => {
  if (toChain === "algorand" && algorandWalletType === "walletConnect") {
    return "ALGORAND WALLET";
  } else if (toChain === "algorand" && algorandWalletType === "myAlgoConnect") {
    return "MY ALGO WALLET";
  } else if (toChain === "algorand" && algorandWalletType === "deflyConnect") {
    return "Defly Wallet";
  } else if (
    toChain === "solana" &&
    solanaWalletType.toLowerCase() === "phantom"
  ) {
    return "Phantom";
  } else if (
    toChain === "solana" &&
    solanaWalletType.toLowerCase() === "sollet.io"
  ) {
    return "Sollet.io";
  } else if (
    toChain === "solana" &&
    solanaWalletType.toLowerCase() === "solflare"
  ) {
    return "Solflare";
  } else if (
    toChain === "solana" &&
    solanaWalletType.toLowerCase() === "mathWallet"
  ) {
    return "MathWallet";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(toChain) &&
    avalancheWalletType === "metamask"
  ) {
    return "MetaMask";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(toChain) &&
    avalancheWalletType === "coin98"
  ) {
    return "Coin98";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(toChain) &&
    avalancheWalletType === "exodus"
  ) {
    return "Exodus";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(toChain) &&
    avalancheWalletType === "trust-wallet"
  ) {
    return "Trust Wallet";
  } else if (toChain === TRON && tronWalletType === "trust") {
    return "Trust Wallet";
  } else if (toChain === TRON && tronWalletType === "tronLink") {
    return "Tron Link";
  }
};

const errorText = (errorType) => {
  var copyText = document.getElementById("wallet");
  let text;
  switch (errorType) {
    case "algoWalletValidation":
      text = "The wallet address is not valid";
      break;
    case "solanaWalletValidation":
      text = "The wallet address is not valid";
      break;
    case "phantomWalletError":
      text =
        "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
      break;
    case "solletWalletError":
      text =
        "Sollet wallet is locked or not installed, please check Sollet wallet status and unlock it, then try again!";
      break;
    case "avalancheWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again!";
      break;
    case "coreWalletNotAvailable":
      text =
        "Core Wallet is locked or not installed or you are not on the right chain or some other same change wallet exist like Metamask/Coin98/Exodus. Please disable them then switch to correct chain or install wallet, and try again!";
      break;
    case "coreWalletWrongChain":
      text =
        "You are not on the right chain in your wallet. Please switch to correct chain, refersh and try again!";
      break;
    case "metamaskNotAvailable":
      text =
        "Metamask is locked or not installed or some other Ethereum chain wallet exists like Exodus/Coin98/Core. So please disable other Ethereum chain wallet, refresh the page and try again. Or you can use Wallet Connect scan.";
      break;
    case "coin98NotAvailable":
      text =
        "Coin98 is locked or not installed. Please install, refresh the page and try again. Or you can use Wallet Connect scan.";
      break;
    case "avalancheWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again. Or you can use Wallet Connect scan.";
      break;
    case "tronWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again!";
      break;
    case "wrongChainError":
      text =
        "You are not on the right chain at your wallet, please switch to the right chain and then connect. Make sure your wallet supports this chain.";
      break;
    case "copiedWalletAddress":
      text = "Copied the wallet address: " + copyText.value;
      break;
    default:
      text = "You have an error!";
  }
  return text;
};

export { InputWalletsLogo, connectionType, errorText, ToWalletDialogs };
