import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Box, Typography, Tooltip } from "@material-ui/core";
import algosdk from "algosdk";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import { SystemProgram } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import clsx from "clsx";
import IdleTimer from "react-idle-timer";
import { isOptedIn } from "../../../../algoFunctions/asset-optin";
import FromWalletStep from "../../../../components/FromStep/FromWalletStep/FromWalletStep";
import ToChainStep from "../../../../components/ToStep/ToChainStep/ToChainStep";
import ToWalletStep from "../../../../components/ToStep/ToWalletStep/ToWalletStep";
import FromChainStep from "../../../../components/FromStep/FromChainStep/FromChainStep";
import StatusBar from "../../../../components/StatusBar";
import AlertBox from "../../../../components/AlertBox";
import {
  getServerURL,
  getASAVaultMSigAddress,
  getAlgoVaultMSigAddress,
  getAlgodClient,
  getFeeReceiverOwnerAddress,
  AppTransaction,
  sendTokenTransaction,
  sendAlgoTransaction,
  getUsdcRecieverAddress,
  sendUSDCTokenTransaction,
} from "../../../../algoFunctions/agloConnectionPublic";
import Divider from "../Divider";
import {
  bridgeStatusInitializedFromAlgorand,
  bridgeStatusInitializedFromSolana,
  checkIfSolBridgeInitialized,
  ownerPubkey,
  solanaAccountSeeker,
  getTokenAccountList,
  transactionHandler,
  initNativeBridgeIx,
  initTokenBridgeIx,
  solAssetsInfo,
  bridgeProgramId,
  initTokenUSDCBridgeIx,
  checkUSDCAlgorandBalanceAfterTransaction,
  checkUSDCSolanaBalanceAfterTransaction,
  sleep,
} from "../../../../solanaFunctions";
import configData from "../../../../config.json";
import { waitForConfirmation } from "../../../../algoFunctions/algoTransactions";

import DividerImage from "../../../../assets/images/divider.svg";
import algoLogoDark from "../../../../assets/images/algo.png";
import algoLogoWhite from "../../../../assets/images/algo-white.png";
import solanaLogo from "../../../../assets/images/solana.svg";
import networkDown from "../../../../assets/images/networkDown.svg";
import xAlgoLogo from "../../../../assets/images/xalgo.png";
import xSolLogo from "../../../../assets/images/xsol.png";
import disconnectIcon from "../../../../assets/images/error.png";
import oopsDark from "../../../../assets/images/oopsDark.svg";
import Review from "../../../Review/Index";
import ColorButtonComp from "../../../../componentsReuse/ColorButton";
import { styles } from "./BridgePanelStyles";
import {
  AlgoSolBridgingDialog,
  DisconnectDialog,
  InactivityDisconnectDialog,
  IssueAlertDialog,
  LoadOrWaitDialog,
  USDC2Dialog,
} from "./components/Dialogs";
import Footer from "../../../../componentsReuse/Footer";
import MaxInput from "../../../../componentsReuse/MaxInput";
import ButtonComp from "../../../../componentsReuse/ButtonComp";
import request from "../../../../utils/apisauce";
import { EVM_SUPPORTED_CHAINS } from "../../../../ethereumFunctions/constants";
import { bridgeEvmToEvm } from "../../../../ethereumFunctions/walletHandler";
import { EXPLORER_STATUS } from "../../../../utils/data";
import WidgetIntegration from "./components/WidgetIntegration";
import { bridgeTron } from "../../../../tronFunction/walletHandler";
import { TRON } from "../../../../tronFunction/constants";

const MIN = 60 * 1000;
const STATUS = ["operational", "degraded", "downtime", "maintenance"];
const TX_WAIT_TIME = 10 * 1000;

class BridgePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Status
      serverStatus: {
        overall: {
          available: true,
          maintenance: false,
          degraded: false,
          lastPinged: "",
          secondsSincePing: 0,
        },
      },
      // Alerts dialog states
      isWalletEmptyAlertOpen: false,
      isTransactionErrorAlertOpen: false,
      isTxnSignAndSendAlertOpen: false,
      isCheckConnectionAlertOpen: false,
      isSolanaClusterTpsAlertOpen: false,
      isDisconnectError: false,
      isUSDCSolanaDialogOpen: false,
      isUSDCAlgorandDialogOpen: false,
      isUSDCBridgeLoading: false,
      USDCBridgeError: false,
      isTxnConfirmationErrorAlertOpen: false,
      isAccountInitializedAlertOpen: false,
      isBridgeFailureErrorAlertOpen: false,
      isAlgoToSolBridgingDialogOpen: false,
      isSolToAlgoBridgingDialogOpen: false,
      isSolanaOptInAlertOpen: false,
      isXsolOptInAlertOpen: false,
      isOptInSuccessfulAlertOpen: false,
      notEnoughNativeBalanceAlertOpen: false,
      moreThanEnoughBalanceAlert: false,
      notEnoughTokenBalanceAlertOpen: false,
      MoreThanBalanceAlertOpen: false,
      hasWalletBalanceAlert: false,

      // Waiting dialog states
      isWaitingForOptInDialogOpen: false,
      isLoading: false,
      isWaitingForBalanceUpdate: false,

      // Bridge transaction states
      isBridgingInProgress: false,
      isAlgorandTxnSuccesfull: false,
      isAlgorandAckTxnSuccesfull: false,
      isAlgoBridgeSuccesfull: false,
      isSolanaTxnSuccesfull: false,
      isSolanaAckTxnSuccesfull: false,
      isSolBridgeSuccesfull: false,

      solanaSignedTxn: false,
      solanaInitTxnSignature: null,
      solanaAckTxnSignature: null,
      algorandTxId: null,

      isSolanaOptIn: false,
      isAlgoWalletOptIn: false,

      algorandAssetInfo: configData.algorand[this.props.network].assets_info[0],
      solanaAssetInfo: solAssetsInfo(this.props.network)[0],
      assetInfoAccount: null,
      userTokenPubkey: null,
      solanaEscrowAccountPublicKey: null,
      escrowTokenPubkey: null,
      escrowList: [],

      proceedButtonDisabled: true,
      isAlgorandTxnError: false,
      isSolanaTxnError: false,
      displayReview: false,
      isUserInActive: false,
    };
    this.getServerStatus = this.getServerStatus.bind(this);
    this.idleTimer = null;
    this._displayReview = null;
    this.onIdle = this.onIdle.bind(this);
    this.onActive = this.onActive.bind(this);
    this.setToDefaultState = this.setToDefaultState.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleClickProceedButton = this.handleClickProceedButton.bind(this);
    this.initSolanaNativeBridge = this.initSolanaNativeBridge.bind(this);
    this.initSolanaTokenBridge = this.initSolanaTokenBridge.bind(this);
    this.displayReviewHandler = this.displayReviewHandler.bind(this);
    this.initAlgorandTokenBridge = this.initAlgorandTokenBridge.bind(this);
    this.initAlgorandNativeBridge = this.initAlgorandNativeBridge.bind(this);
    this.checkAssetOptIn = this.checkAssetOptIn.bind(this);
    this.gotoReviewHandler = this.gotoReviewHandler.bind(this);

    this.initSolanaUSDCBridge = this.initSolanaUSDCBridge.bind(this);
    this.initAlgorandUSDCTokenBridge =
      this.initAlgorandUSDCTokenBridge.bind(this);
  }

  async componentDidMount() {
    const statusPingInterval = setInterval(async () => {
      this.getServerStatus();
    }, 60000);
    this.setState({ statusPingInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.statusPingInterval);
  }
  //LOOSE: update the component state as per described after updation
  componentDidUpdate(prevProps, prevState) {
    const {
      network,
      fromChain,
      toChain,
      fromToken,
      toToken,
      fromWallet,
      toWallet,
      algorandWalletAddress,
      solanaWalletAddress,
      fromNativeBalance,
      fromTokenBalance,
      toNativeBalance,
      amount,
      activeStep,
    } = this.props;

    if (
      this.props !== prevProps ||
      this.state.solanaSignedTxn !== prevState.solanaSignedTxn ||
      this.state.algorandTxId !== prevState.algorandTxId ||
      this.state.isAlgoWalletOptIn !== prevState.isAlgoWalletOptIn ||
      this.state.isXsolOptInAlertOpen !== prevState.isXsolOptInAlertOpen
    ) {
      if (activeStep === 0) {
        if (fromChain && fromToken) {
          console.log("next step ready", activeStep + 1);
          this.props.setActiveStep(activeStep + 1);
        }
        this.setState({ isWalletEmptyAlertOpen: false });
        if (toToken === "" && fromToken === "SOL") {
          this.props.setToToken("xSOL");
        } else if (toToken === "" && fromToken === "ALGO") {
          this.props.setToToken("xALGO");
        } else if (toToken === "" && fromToken === "USDC") {
          this.props.setToToken("USDC");
        }
        this.setState({
          solanaAssetInfo: solAssetsInfo(this.props.network).find(
            (asset) =>
              asset.symbol === (fromChain === "solana" ? fromToken : toToken)
          ),
          algorandAssetInfo: configData.algorand[
            this.props.network
          ].assets_info.find(
            (asset) =>
              asset.symbol === (fromChain === "solana" ? toToken : fromToken)
          ),
        });
      }
      //from wallet step
      if (activeStep === 1) {
        if (fromChain !== "" && fromWallet !== "") {
          console.log("next step ready", activeStep + 2);
          this.props.setActiveStep(activeStep + 2);
        }
        if (fromChain === "algorand" && algorandWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (fromChain === "solana" && solanaWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (algorandWalletAddress !== "" || solanaWalletAddress !== "") {
          this.setState({ isWalletEmptyAlertOpen: false });
        }
      }

      //to wallet step
      if (activeStep === 3) {
        if (toChain !== "" && toWallet !== "") {
          console.log("next step ready", activeStep + 1);
          this.props.setActiveStep(activeStep + 1);
        }
        if (toChain === "algorand" && algorandWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (toChain === "solana" && solanaWalletAddress === "") {
          this.setState({
            isWalletEmptyAlertOpen: true,
            isXsolOptInAlertOpen: false,
            notEnoughNativeBalanceAlertOpen: false,
            notEnoughTokenBalanceAlertOpen: false,
            MoreThanBalanceAlertOpen: false,
          });
        } else if (algorandWalletAddress !== "" || solanaWalletAddress !== "") {
          this.setState({ isWalletEmptyAlertOpen: false });
        }
      }

      if (this.state.solanaSignedTxn || this.state.algorandTxId) {
        this.setState({ isLoading: false });
      }
      if (
        activeStep === 1 &&
        (fromNativeBalance == null || !fromTokenBalance == null)
      ) {
        return;
      }
      if (activeStep === 3 && toNativeBalance == null) {
        return;
      }

      if (fromChain && fromToken && [1, 3, 4].includes(activeStep)) {
        const fromAssetInfo = configData[fromChain][network].assets_info.find(
          (a) => a.symbol === fromToken
        );
        const fromMinBalance = parseFloat(fromAssetInfo.min_balance);
        const bridgeFee = parseFloat(fromMinBalance * fromAssetInfo.fee_rate);
        const amountBridge = parseFloat(amount * 0.005);
        const answer = (amount + amountBridge).toFixed(4);
        const balance =
          fromToken === "ALGO" || fromToken === "SOL"
            ? fromNativeBalance
            : fromToken === "xALGO" ||
              fromToken === "xSOL" ||
              fromToken === "USDC" ||
              fromToken === "EUROC"
            ? fromTokenBalance
            : 0;

        console.log(balance);

        if (fromToken === "ALGO" || fromToken === "SOL") {
          const amountBridge = Number(parseFloat(amount * 0.005).toFixed(4));
          const answer = (amount + amountBridge).toFixed(4);
          this.setState({
            notEnoughNativeBalanceAlertOpen:
              activeStep === 4
                ? amount < fromMinBalance
                : balance < fromMinBalance + bridgeFee + 0.2,
            MoreThanBalanceAlertOpen: activeStep === 4 && amount > balance,
            moreThanEnoughBalanceAlert:
              activeStep === 4 ? balance < answer : "",
          });
        } else if (["USDC", "EUROC"].includes(fromToken)) {
          this.setState({
            notEnoughTokenBalanceAlertOpen:
              activeStep === 4
                ? amount < fromMinBalance
                : balance < fromMinBalance,
            MoreThanBalanceAlertOpen: activeStep === 4 && amount > balance,
            moreThanEnoughBalanceAlert:
              activeStep === 4 ? balance < amount?.toFixed(4) : "",
          });
        } else {
          this.setState({
            notEnoughTokenBalanceAlertOpen:
              activeStep === 4
                ? amount < fromMinBalance
                : balance < fromMinBalance + bridgeFee,
            MoreThanBalanceAlertOpen: activeStep === 4 && amount > balance,
            moreThanEnoughBalanceAlert:
              activeStep === 4 ? balance < answer : "",
          });
        }
      }

      if (toChain && toToken) {
        let toMinBalance = 0;
        if (toChain === "algorand") {
          toMinBalance =
            this.state.isAlgoWalletOptIn || toToken === "ALGO" ? 0 : 0.4;
        } else if (toChain === "solana") {
          toMinBalance =
            this.state.isSolanaOptIn || toToken === "SOL" ? 0 : 0.1;
        }
        if (activeStep === 3) {
          this.setState({
            notEnoughNativeBalanceAlertOpen: toNativeBalance < toMinBalance,
          });
        }
      }
    }
  }

  async getServerStatus() {
    const payload = await request("GET", "health");

    if (payload && payload?.Overall) {
      if (payload?.Overall) {
        const {
          available,
          maintenance,
          degraded,
          lastPinged,
          secondsSincePing,
        } = payload?.Overall;

        this.setState({
          serverStatus: {
            ...this.state.serverStatus,
            overall: {
              available,
              maintenance,
              degraded,
              lastPinged,
              secondsSincePing,
            },
          },
        });
      }
    }

    if (!payload || payload === null || payload === undefined) {
      this.setState({
        serverStatus: {
          ...this.state.serverStatus,
          overall: {
            available: true,
            maintenance: false,
            degraded: false,
            lastPinged: "",
            secondsSincePing: 0,
          },
        },
      });
    }
  }

  gotoReviewHandler = () => {
    this.setState({ displayReview: !this.state.displayReview });
  };
  handleNext(prevActiveStep) {
    this.props.setActiveStep(prevActiveStep + 1);
  }
  handleCloseAlert() {
    this.setState({
      isWalletEmptyAlertOpen: false,
      isTransactionErrorAlertOpen: false,
      isTxnSignAndSendAlertOpen: false,
      isCheckConnectionAlertOpen: false,
      isXsolOptInAlertOpen: false,
      notEnoughNativeBalanceAlertOpen: false,
      isTxnConfirmationErrorAlertOpen: false,
      isAccountInitializedAlertOpen: false,
      isBridgeFailureErrorAlertOpen: false,
      isSolanaOptInAlertOpen: false,
      isOptInSuccessfulAlertOpen: false,
      moreThanEnoughBalanceAlert: false,
    });
  }

  async handleClickProceedButton() {
    const {
      network,
      connection,
      fromChain,
      fromToken,
      fromWallet,
      toWallet,
      solanaWalletObject,
      setActiveStep,
    } = this.props;

    this.setState({
      proceedButtonDisabled: true,
      isLoading: true,
    });

    setActiveStep(5);
    //for Solana Chain
    if (fromChain === "solana") {
      const { solanaEscrowAccount, solanaEscrowTokenAccount } =
        await solanaAccountSeeker(
          network,
          solanaWalletObject.publicKey,
          this.state.solanaAssetInfo.mint
        );
      const { assetInfoAccount } = await solanaAccountSeeker(
        network,
        ownerPubkey(network),
        this.state.solanaAssetInfo.mint
      );

      this.setState({
        solanaEscrowAccountPublicKey: solanaEscrowAccount,
        escrowTokenPubkey: solanaEscrowTokenAccount,
        assetInfoAccount,
      });

      const { isInitialized } = await checkIfSolBridgeInitialized(
        network,
        connection,
        solanaEscrowAccount
      );

      if (fromToken === "SOL") {
        const isXsolOptedin = await this.checkAssetOptIn(
          toWallet,
          parseInt(
            configData.algorand[network].assets_info.find(
              (asset) => asset.symbol === "xSOL"
            ).asset_id
          )
        );
        if (!isXsolOptedin) {
          this.setState({
            isOptinAlertOpen: true,
            isLoading: false,
            isBridgingInProgress: false,
          });
          console.log("No OPTIn");
          return;
        }
      }

      if (isInitialized) {
        this.setState({ isAccountInitializedAlertOpen: true }, () => {
          setTimeout(
            () =>
              this.setState({
                isAccountInitializedAlertOpen: false,
              }),
            parseInt(configData.settings.polling_interval)
          );
        });
      }
      //this.setState({ isLoading: true });
      let instruction = null;
      if (fromToken === "SOL") {
        instruction = this.initSolanaNativeBridge();
      } else if (fromToken === "xALGO" || fromToken === "USDC") {
        let tokenAccountInfo = await getTokenAccountList(
          connection,
          this.state.solanaAssetInfo.mint,
          solanaWalletObject.publicKey
        );
        if (tokenAccountInfo.length === 0) {
          this.setState({
            isBridgeFailureErrorAlertOpen: true,
            isLoading: false,
            isBridgingInProgress: false,
          });
          return;
        }
        this.setState({
          userTokenPubkey: tokenAccountInfo
            ? tokenAccountInfo[0].accountPubkey
            : null,
        });
        if (fromToken === "xALGO") {
          instruction = this.initSolanaTokenBridge();
        } else {
          instruction = this.initSolanaUSDCBridge();
        }
      }

      if (fromToken === "SOL" || fromToken === "xALGO") {
        const that = this;
        this.setState({
          solanaInitTxnSignature: null,
          isBridgingInProgress: true,
          isSolToAlgoBridgingDialogOpen: true,
        });
        const initTxn = await transactionHandler(
          connection,
          solanaWalletObject,
          [instruction],
          "confirmed",
          that
        );
        this.setState({
          solanaInitTxnSignature: initTxn,
          isBridgingInProgress: true,
          isSolToAlgoBridgingDialogOpen: true,
        });
        const solanaAckResult = await bridgeStatusInitializedFromSolana(
          network,
          connection,
          solanaWalletObject.publicKey,
          this.state?.solanaAssetInfo?.mint
        );
        if (solanaAckResult.isFinalized) {
          this.setState(
            {
              solanaAckTxnSignature: solanaAckResult.signature,
              algorandTxId: solanaAckResult.algorandTxnId,
            },
            () => {
              this.setState({
                isSolanaTxnSuccesfull: true,
                isAlgorandAckTxnSuccesfull: true,
                isBridgingInProgress: false,
              });
            }
          );
        } else {
          if (
            solanaAckResult.signature !== null &&
            solanaAckResult.signature !== "null" &&
            solanaAckResult.signature !== ""
          ) {
            this.setState({
              isSolToAlgoBridgingDialogOpen: false,
              isBridgeFailureErrorAlertOpen: true,
              isSolanaTxnError: true,
            });
          } else {
            this.setState({
              isBridgingInProgress: false,
              isLoading: false,
              proceedButtonDisabled: true,
              isTxnConfirmationErrorAlertOpen: true,
            });
          }
        }
      } else {
        const that = this;
        const { fromTokenBalance, network } = this.props;

        const initTxn = await transactionHandler(
          connection,
          solanaWalletObject,
          [...instruction],
          "confirmed",
          that,
          true
        );

        if (initTxn) {
          this.setState({
            isUSDCBridgeLoading: true,
          });
          this.setState({
            solanaInitTxnSignature: initTxn,
            isUSDCSolanaDialogOpen: true,
          });

          if (network === "mainnet") {
            const { isFinalized } =
              await checkUSDCSolanaBalanceAfterTransaction(
                fromChain,
                this.props.toChain,
                connection,
                solanaWalletObject,
                fromToken,
                fromTokenBalance,
                network,
                initTxn
              );

            if (!isFinalized) {
              this.setState({
                USDCBridgeError: true,
              });
            }
          }

          setTimeout(() => {
            this.setState({
              isUSDCBridgeLoading: false,
              isUSDCSolanaDialogOpen: true,
            });
          }, TX_WAIT_TIME);
        }
      }
    } else if (fromChain === "algorand") {
      if (fromToken === "xSOL" || fromToken === "USDC") {
        const algorandSymbol = fromToken === "USDC" ? "USDC" : "xSOL";
        const isXsolOptedin = await this.checkAssetOptIn(
          fromWallet,
          parseInt(
            configData.algorand[network].assets_info.find(
              (asset) => asset.symbol === algorandSymbol
            ).asset_id
          )
        );
        if (!isXsolOptedin) {
          this.setState({ isOptinAlertOpen: true });
          return;
        }
      }
      if (fromToken === "ALGO" || fromToken === "xSOL") {
        this.setState({
          isAlgoToSolBridgingDialogOpen: true,
          isBridgingInProgress: true,
        });
      }

      try {
        if (fromToken === "ALGO") {
          await this.initAlgorandNativeBridge();
        } else if (fromToken === "xSOL") {
          await this.initAlgorandTokenBridge();
        } else if (fromToken === "USDC") {
          await this.initAlgorandUSDCTokenBridge();
        }
      } catch (error) {
        console.log("Algorand Bridge Error: ", error);
        this.setState({
          isLoading: false,
          isAlgoToSolBridgingDialogOpen: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          isAlgoBridgeSuccesfull: false,
          isAlgorandTxnError: true,
          proceedButtonDisabled: false,
        });
      }
      // let algorand_pubkey;
      // let algorand_pubkey_object = algosdk.decodeAddress(this.props.fromWallet)
      // if (algorand_pubkey_object) {
      //   algorand_pubkey = algorand_pubkey_object.publicKey
      // }

      if (fromToken === "xSOL" || fromToken === "ALGO") {
        let finalBridgeStatus = await bridgeStatusInitializedFromAlgorand(
          connection,
          solanaWalletObject.publicKey,
          this.state.algorandTxId
        );
        if (finalBridgeStatus.isFinalized) {
          this.setState({
            isSolanaAckTxnSuccesfull: true,
            isBridgingInProgress: false,
            solanaAckTxnSignature: finalBridgeStatus.signature,
          });
        } else {
          this.setState({
            isTxnConfirmationErrorAlertOpen: true,
          });
        }
      } else {
        const { algorandWalletAddress, network, fromTokenBalance } = this.props;
        if (network === "mainnet") {
          this.setState({
            isUSDCAlgorandDialogOpen: true,
          });

          if (this.state.algorandTxId) {
            this.setState({
              isUSDCBridgeLoading: true,
            });

            const { isFinalized } =
              await checkUSDCAlgorandBalanceAfterTransaction(
                fromChain,
                this.props.toChain,
                fromTokenBalance,
                algorandWalletAddress,
                network,
                this.state.algorandTxId
              );

            if (!isFinalized) {
              this.setState({
                USDCBridgeError: true,
              });
            }
          }

          this.setState({
            isUSDCBridgeLoading: false,
          });
          this.setState({
            isUSDCAlgorandDialogOpen: true,
          });
        } else {
          this.setState({
            isUSDCAlgorandDialogOpen: true,
          });
          this.setState({
            isUSDCBridgeLoading: true,
          });

          setTimeout(() => {
            this.setState({
              isUSDCBridgeLoading: false,
              isUSDCAlgorandDialogOpen: true,
            });
          }, TX_WAIT_TIME);
        }
      }
    } else if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
      const { toChain, ethereumWalletObject, toWallet, amount } = this.props;
      console.log({ toChain, ethereumWalletObject, toWallet, amount });

      const lilHelper = (chain) => {
        return chain === "algorand"
          ? "Algorand"
          : chain === "solana"
          ? "Solana"
          : chain;
      };

      let _toChain = lilHelper(toChain);
      let _fromChain = lilHelper(fromChain);

      // debugger;
      try {
        let tx = await bridgeEvmToEvm(
          _fromChain,
          _toChain,
          toWallet,
          amount,
          ethereumWalletObject,
          network,
          fromToken
        );

        console.log("tx sucessfull", tx?.hash);

        this.setState({
          isUSDCAlgorandDialogOpen: true,
          isUSDCBridgeLoading: true,
        });

        await tx?.wait();

        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: tx?.hash,
          isAlgoBridgeSuccesfull: true,
        });

        console.log("tx confirmed");

        setTimeout(() => {
          this.setState({
            isUSDCBridgeLoading: false,
            isUSDCAlgorandDialogOpen: true,
          });
        }, TX_WAIT_TIME);
      } catch (error) {
        console.log("Ethereum Bridge Error: ", error);
        this.setState({
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: false,
        });
      }
    } else if (fromChain === TRON) {
      const { tronWalletObject, toWallet, amount, toChain, tronWalletAddress } =
        this.props;

      const lilHelper = (chain) => {
        return chain === "algorand"
          ? "Algorand"
          : chain === "solana"
          ? "Solana"
          : chain;
      };

      let _toChain = lilHelper(toChain);
      let _fromChain = lilHelper(fromChain);

      try {
        let txDetails = await bridgeTron(
          tronWalletObject,
          tronWalletAddress,
          _fromChain,
          _toChain,
          toWallet,
          amount,
          network
        );
        console.log("tx sucessfull", txDetails?.txid);

        this.setState({
          isUSDCAlgorandDialogOpen: true,
        });
        this.setState({
          isUSDCBridgeLoading: true,
        });

        let counter = 0;
        let txRec;
        let isFinalized = false;
        if (txDetails?.txid) {
          while (!isFinalized) {
            await sleep(parseInt(configData.settings.polling_interval));
            counter += 1;
            if (counter > parseInt(configData.settings.max_retries)) {
              break;
            }

            try {
              txRec = await tronWalletObject.trx.getTransaction(
                txDetails?.txid
              );

              if ("ret" in txRec) {
                const success = txRec.ret.find(
                  (x) => x.contractRet === "SUCCESS"
                );
                if (success) {
                  console.log("tx confirmed, txRect:", txRec);
                  isFinalized = true;
                  break;
                } else {
                  isFinalized = false;
                }
              }
            } catch (error) {
              console.log("error at txRex", error);
            }
          }
        } else {
          if (!isFinalized) {
            console.log("Tron tx not confirmed: ", txDetails?.txid);
            this.setState({
              isLoading: false,
              isBridgingInProgress: false,
              isBridgeFailureErrorAlertOpen: true,
              proceedButtonDisabled: false,
            });
            return;
          }
        }
        console.log("tx confirmed", txDetails?.txid);

        this.setState({
          isUSDCBridgeLoading: true,
        });

        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: txDetails?.txid,
          isAlgoBridgeSuccesfull: true,
        });

        setTimeout(() => {
          this.setState({
            isUSDCBridgeLoading: false,
            isUSDCAlgorandDialogOpen: true,
          });
        }, TX_WAIT_TIME);
      } catch (error) {
        console.log("Ethereum Bridge Error: ", error);
        this.setState({
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: false,
        });
      }
    }
  }

  // All Tx execution stuff from Algo Native bridge side
  async initAlgorandNativeBridge() {
    //Get User Addresses
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      network,
      algorandWalletType,
    } = this.props;

    const algodClient = getAlgodClient(network);

    //Get Glitter Addresses
    const algorandVaultMSigAddress = getAlgoVaultMSigAddress(network);
    const algorandFeeOwnerAddress = getFeeReceiverOwnerAddress(network);

    //Get Amount
    const microAlgoCorrection = 1000000;
    const amount = this.props.amount;
    const amount_microAlgos = Math.round(amount * microAlgoCorrection);
    const fee_microAlgos = Math.trunc(amount_microAlgos * 0.005); //Applies 0.5% fee to the transaction
    //Set up Transactions
    const appcall = await AppTransaction(
      this.props.network,
      algodClient,
      algorandWalletAddress,
      [algorandWalletAddress, algorandVaultMSigAddress],
      "algo",
      "algo-deposit",
      amount_microAlgos,
      "Depositing Algo",
      solanaWalletAddress,
      this.state.solanaAssetInfo.mint.toString(),
      "null"
    );
    let txnFee = await sendAlgoTransaction(
      algodClient,
      algorandWalletAddress,
      algorandFeeOwnerAddress,
      fee_microAlgos,
      "Depositing Algo"
    );
    let txnDeposit = await sendAlgoTransaction(
      algodClient,
      algorandWalletAddress,
      algorandVaultMSigAddress,
      amount_microAlgos,
      "Depositing Algo"
    );
    //Group Transactions
    let txnsArray = [appcall, txnFee, txnDeposit];
    const groupID = algosdk.computeGroupID(txnsArray);
    for (let i = 0; i < 3; i++) txnsArray[i].group = groupID;

    let rawSignedTxn;
    let sentTxn;
    // debugger;
    if (["myAlgoConnect", "deflyConnect"].includes(algorandWalletType)) {
      try {
        //Sign and Send
        if (algorandWalletType === "myAlgoConnect") {
          rawSignedTxn = await this.props.algorandWalletObject.signTransaction(
            txnsArray.map((txn) => txn.toByte())
          );
          sentTxn = await algodClient
            .sendRawTransaction(rawSignedTxn.map((tx) => tx.blob))
            .do();
        } else if (algorandWalletType === "deflyConnect") {
          let txs = txnsArray.map((txn) => {
            return {
              txn: txn,
            };
          });
          rawSignedTxn = await this.props.algorandWalletObject.signTransaction([
            txs,
          ]);
          sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
        }
      } catch (error) {
        this.setState({
          isAlgoToSolBridgingDialogOpen: false,
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: true,
        });
        console.log(
          `sign and send transaction group with ${
            algorandWalletType === "myAlgoConnect" ? "MyAlgo" : "Defly"
          } wallet failed: `,
          error
        );
      }
    } else {
      //Encode transactions
      const txnsToSign = txnsArray.map((txn) => {
        const encodedTxn = Buffer.from(
          algosdk.encodeUnsignedTransaction(txn)
        ).toString("base64");

        return {
          txn: encodedTxn,
          message: "Depositing Algo to bridge vault",
        };
      });

      //Request Sign
      const requestParams = [txnsToSign];
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result = await this.props.algorandWalletObject.sendCustomRequest(
        request
      );

      //Sign and Send
      rawSignedTxn = result.map((element) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });
      sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
    }

    //Check Sent Transaction
    if (sentTxn) {
      let txId = sentTxn.txId;

      console.log("Wallet's deposit transaction sent to Algorand! ", txId);

      await waitForConfirmation(algodClient, txId);
      let ptx = await algodClient.pendingTransactionInformation(txId).do();
      if (ptx) {
        if (ptx.txn) {
          //this.props.setIsXsolOptInAlertOpen(false);
          //https://testnet.algoexplorer.io/tx/${txid}
          this.setState({
            isAlgorandTxnSuccesfull: true,
            algorandTxId: txId,
            isAlgoBridgeSuccesfull: true,
          });
        }
      }
    }
  }

  // All Tx execution stuff from Algo token side - i.e sending xSOL from algorand to Solflare
  async initAlgorandTokenBridge() {
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      network,
      algorandWalletType,
    } = this.props;

    const algodClient = getAlgodClient(network);
    const algorandAsaVaultMSigAddress = getASAVaultMSigAddress(network);
    const algorandFeeOwnerAddress = getFeeReceiverOwnerAddress(network);

    //Get Amount
    const tokenDecimal = parseInt(
      configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "xSOL"
      ).decimal
    );
    const amount_nanoxSol = Math.round(this.props.amount * 10 ** tokenDecimal);
    const fee_nanoxSol = Math.trunc(amount_nanoxSol * 0.005); //Applies 0.5% fee to the transaction

    //Set up Transactions
    let appcall = await AppTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      [algorandWalletAddress, algorandAsaVaultMSigAddress],
      "xSOL",
      "xSOL-deposit",
      amount_nanoxSol,
      "Depositing xSOL token",
      solanaWalletAddress,
      "sol",
      "null"
    );
    let txnFee = await sendTokenTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      algorandFeeOwnerAddress,
      fee_nanoxSol,
      "Depositing xSol token"
    );
    let txnDeposit = await sendTokenTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      algorandAsaVaultMSigAddress,
      amount_nanoxSol,
      "Depositing xSol token"
    );

    //Group Transactions
    let txnsArray = [appcall, txnFee, txnDeposit];
    const groupID = algosdk.computeGroupID(txnsArray);
    for (let i = 0; i < 3; i++) txnsArray[i].group = groupID;

    let rawSignedTxn;
    let sentTxn;

    if (["myAlgoConnect", "deflyConnect"].includes(algorandWalletType)) {
      try {
        //Sign and Send
        if (algorandWalletType === "myAlgoConnect") {
          rawSignedTxn = await this.props.algorandWalletObject.signTransaction(
            txnsArray.map((txn) => txn.toByte())
          );
          sentTxn = await algodClient
            .sendRawTransaction(rawSignedTxn.map((tx) => tx.blob))
            .do();
        } else if (algorandWalletType === "deflyConnect") {
          let txs = txnsArray.map((txn) => {
            return {
              txn: txn,
            };
          });
          rawSignedTxn = await this.props.algorandWalletObject.signTransaction([
            txs,
          ]);
          sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
        }
      } catch (error) {
        this.setState({
          isAlgoToSolBridgingDialogOpen: false,
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: true,
        });
        console.log(
          `sign and send transaction group with ${
            algorandWalletType === "myAlgoConnect" ? "MyAlgo" : "Defly"
          } wallet failed: `,
          error
        );
      }
    } else {
      //Encode Transactions
      const txnsToSign = txnsArray.map((txn) => {
        const encodedTxn = Buffer.from(
          algosdk.encodeUnsignedTransaction(txn)
        ).toString("base64");

        return {
          txn: encodedTxn,
          message: "Depositing Algo to bridge vault",
        };
      });

      //Request Sign
      const requestParams = [txnsToSign];
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result = await this.props.algorandWalletObject.sendCustomRequest(
        request
      );

      //Sign and Send
      rawSignedTxn = result.map((element) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });
      sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
    }

    let txId = sentTxn.txId;

    console.log("Wallet's deposit transaction sent to Algorand! ", txId);

    await waitForConfirmation(algodClient, txId);
    let ptx = await algodClient.pendingTransactionInformation(txId).do();
    if (ptx) {
      if (ptx.txn) {
        //this.props.setIsXsolOptInAlertOpen(false);
        //https://testnet.algoexplorer.io/tx/${txid}
        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: txId,
          isAlgoBridgeSuccesfull: true,
        });
      }
    }
  }

  async initAlgorandUSDCTokenBridge() {
    const {
      algorandWalletAddress,
      network,
      toChain,
      toWallet,
      algorandWalletType,
    } = this.props;
    const usdcAddressReceiver = getUsdcRecieverAddress(network);
    const algodClient = getAlgodClient(network);

    //Get Amount
    const tokenDecimal = parseInt(
      configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "USDC"
      ).decimal
    );
    const amount_nanoUsdc = Math.round(this.props.amount * 10 ** tokenDecimal);

    const routingData = {
      from: {
        token: "USDC",
        network: "algorand",
        address: algorandWalletAddress,
        txn_signature: "",
      },
      to: {
        token: "USDC",
        network: toChain,
        address: toWallet,
        txn_signature: "",
      },
      amount: String(+this.props.amount),
      units: String(Number(amount_nanoUsdc).toFixed(0)),
    };

    let txnDeposit = await sendUSDCTokenTransaction(
      network,
      algodClient,
      algorandWalletAddress,
      usdcAddressReceiver,
      amount_nanoUsdc,
      routingData
    );

    //Group Transactions
    let txnsArray = [txnDeposit];
    const groupID = algosdk.computeGroupID(txnsArray);
    for (let i = 0; i < 1; i++) txnsArray[i].group = groupID;

    let rawSignedTxn;
    let sentTxn;
    if (["myAlgoConnect", "deflyConnect"].includes(algorandWalletType)) {
      try {
        //Sign and Send
        if (algorandWalletType === "myAlgoConnect") {
          rawSignedTxn = await this.props.algorandWalletObject.signTransaction(
            txnsArray.map((txn) => txn.toByte())
          );
          sentTxn = await algodClient
            .sendRawTransaction(rawSignedTxn.map((tx) => tx.blob))
            .do();
        } else if (algorandWalletType === "deflyConnect") {
          let txs = txnsArray.map((txn) => {
            return {
              txn: txn,
            };
          });
          rawSignedTxn = await this.props.algorandWalletObject.signTransaction([
            txs,
          ]);
          sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
        }
      } catch (error) {
        this.setState({
          isAlgoToSolBridgingDialogOpen: false,
          isLoading: false,
          isBridgingInProgress: false,
          isBridgeFailureErrorAlertOpen: true,
          proceedButtonDisabled: true,
        });
        console.log(
          `sign and send transaction group with ${
            algorandWalletType === "myAlgoConnect" ? "MyAlgo" : "Defly"
          } wallet failed: `,
          error
        );
      }
    } else {
      //Encode Transactions
      const txnsToSign = txnsArray.map((txn) => {
        const encodedTxn = Buffer.from(
          algosdk.encodeUnsignedTransaction(txn)
        ).toString("base64");

        return {
          txn: encodedTxn,
          message: "Depositing Algo to bridge vault",
        };
      });

      //Request Sign
      const requestParams = [txnsToSign];
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result = await this.props.algorandWalletObject.sendCustomRequest(
        request
      );

      //Sign and Send
      rawSignedTxn = result.map((element) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });
      sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
    }

    let txId = sentTxn.txId;

    console.log("Wallet's deposit transaction sent to Algorand! ", txId);

    await waitForConfirmation(algodClient, txId);
    let ptx = await algodClient.pendingTransactionInformation(txId).do();
    if (ptx) {
      if (ptx.txn) {
        //this.props.setIsXsolOptInAlertOpen(false);
        //https://testnet.algoexplorer.io/tx/${txid}
        this.setState({
          isAlgorandTxnSuccesfull: true,
          algorandTxId: txId,
          isAlgoBridgeSuccesfull: true,
        });
      }
    }
  }

  async checkAssetOptIn(wallet, asset) {
    const result = await isOptedIn(this.props.network, wallet, asset);
    this.props.setxSolOptIn(result);
    return result;
  }

  /* ------------------------------------------------------------ 
      Get bridging instructions for Solana
  --------------------------------------------------------------- */
  initSolanaNativeBridge() {
    const accounts = [
      bridgeProgramId(this.props.network),
      this.props.solanaWalletObject.publicKey,
      this.state.solanaEscrowAccountPublicKey,
      this.state.assetInfoAccount,
      SystemProgram.programId,
    ];

    const addr = algosdk.decodeAddress(this.props.algorandWalletAddress);
    return initNativeBridgeIx(
      accounts,
      addr.publicKey,
      this.props.amount *
        10 **
          parseInt(
            configData.solana[this.props.network].assets_info.find(
              (asset) => asset.symbol === this.props.fromToken
            ).decimal
          )
    );
  }

  initSolanaTokenBridge() {
    const accounts = [
      bridgeProgramId(this.props.network),
      this.props.solanaWalletObject.publicKey,
      this.state.userTokenPubkey,
      this.state.solanaEscrowAccountPublicKey,
      this.state.escrowTokenPubkey,
      this.state.assetInfoAccount,
      this.state.solanaAssetInfo.mint,
      TOKEN_PROGRAM_ID,
      SystemProgram.programId,
    ];
    const addr = algosdk.decodeAddress(this.props.algorandWalletAddress);
    return initTokenBridgeIx(
      accounts,
      addr.publicKey,
      this.props.amount *
        10 **
          parseInt(
            configData.solana[this.props.network].assets_info.find(
              (asset) => asset.symbol === this.props.fromToken
            ).decimal
          )
    );
  }

  initSolanaUSDCBridge() {
    const accounts = [
      this.props.solanaWalletAddress,
      this.props.solanaWalletObject.publicKey,
      this.state.userTokenPubkey,
    ];
    const addr = this.props.toWallet;
    return initTokenUSDCBridgeIx(
      accounts,
      addr,
      this.props.amount *
        10 **
          parseInt(
            configData.solana[this.props.network].assets_info.find(
              (asset) => asset.symbol === this.props.fromToken
            ).decimal
          ),
      this.props.network,
      this.props.amount,
      this.props.toChain
    );
  }

  displayReviewHandler() {
    this.setState({ displayReview: false });
    this.props.setActiveStep(4);
  }
  onIdle() {
    this.setState({ isUserInActive: true });
  }
  onActive() {
    const { algorandWalletAddress, solanaWalletAddress } = this.props;

    if (!algorandWalletAddress || !solanaWalletAddress) {
      this.setState({ isUserInActive: false });
    }
  }

  setToDefaultState() {
    this.setState({
      isLoading: false,
      isWaitingForOptInDialogOpen: false,
      isAlgoToSolBridgingDialogOpen: false,
      isSolToAlgoBridgingDialogOpen: false,
      isAlgorandTxnSuccesfull: false,
      isSolanaAckTxnSuccesfull: false,
      proceedButtonDisabled: true,
      isBridgingInProgress: false,
      isSolanaClusterTpsAlertOpen: false,
      isUSDCSolanaDialogOpen: false,
      isUSDCAlgorandDialogOpen: false,
      isUSDCBridgeLoading: false,
      isDisconnectError: false,
      isSolanaTxnSuccesfull: false,
      isAlgorandAckTxnSuccesfull: false,
      solanaAckTxnSignature: null,
      algorandTxId: null,
      bridgingProcessComplete: false,
      solanaSignedTxn: false,
      fromNativeBalance: null,
      fromTokenBalance: null,
      toNativeBalance: null,
      toTokenBalance: null,
      isAlgorandTxnError: false,
      isSolanaTxnError: false,
      displayReview: false,
      isUserInActive: false,
      USDCBridgeError: false,
    });
    this.props.setActiveStep(0);
    this.props.setAmount(0);
    this.props.setSolanaWallet("");
    this.props.setAlgoWallet("");
    this.props.setFromWallet("");
    this.props.setToWallet("");
    this.props.setAlgoWallet("");
    this.props.setAlgorandWalletType("");
    this.props.setFromToken("");
    this.props.setToToken("");
    this.props.setFromChain("algorand");
    this.props.setToChain("solana");
    this.props.setFromNativeBalance(null);
    this.props.setFromTokenBalance(null);
    this.props.setToNativeBalance(null);
    this.props.setToTokenBalance(null);
    this.props.setFromNativeMinApiBalance(null);
    this.props.setFromNativeMinBalance(null);

    this.props.setEthereumWalletType("");
    this.props.setEthereumWalletAddress("");
    this.props.setEthereumWalletObject("");
    this.props?.setAvalancheWalletType("");
    this.props?.setAvalancheWalletAddress("");
    this.props?.setAvalancheWalletObject("");

    this.props.setTronWalletType("");
    this.props.setTronWalletAddress("");
    this.props.setTronWalletObject("");
  }

  render() {
    const {
      serverStatus,
      isLoading,
      proceedButtonDisabled,
      isWalletEmptyAlertOpen,
      isTransactionErrorAlertOpen,
      isTxnSignAndSendAlertOpen,
      isAccountInitializedAlertOpen,
      isSolanaClusterTpsAlertOpen,
      isDisconnectError,
      isCheckConnectionAlertOpen,
      isTxnConfirmationErrorAlertOpen,
      isAlgoToSolBridgingDialogOpen,
      isSolToAlgoBridgingDialogOpen,
      isBridgingInProgress,
      isBridgeFailureErrorAlertOpen,
      isAlgorandTxnSuccesfull,
      isSolanaAckTxnSuccesfull,
      isAlgoBridgeSuccesfull,
      isAlgorandAckTxnSuccesfull,
      isSolanaTxnSuccesfull,
      isSolanaOptInAlertOpen,
      isSolanaOptIn,
      isOptInAlertOpen,
      isXsolOptInAlertOpen,
      isOptInSuccessfulAlertOpen,
      isWaitingForOptInDialogOpen,
      notEnoughNativeBalanceAlertOpen,
      notEnoughTokenBalanceAlertOpen,
      MoreThanBalanceAlertOpen,
      moreThanEnoughBalanceAlert,

      solanaAssetInfo,
      algorandAssetInfo,
      isWaitingForBalanceUpdate,

      algorandTxId,
      solanaAckTxnSignature,
      solanaSignedTxn,
      isAlgorandTxnError,
      isSolanaTxnError,
      isUserInActive,
    } = this.state;

    const {
      classes,
      isDark,
      network,
      fromToken,
      fromWallet,
      fromChain,
      toChain,
      toToken,
      toWallet,
      amount,
      setAlgoWallet,
      setFromWallet,
      setFromChain,
      setFromToken,
      setToToken,
      setToChain,
      setToWallet,
      setAmount,
      algorandWalletAddress,
      algorandWalletType,
      algorandWalletObject,
      setAlgorandWalletType,
      setAlgorandWalletObject,
      solanaWalletType,
      solanaWalletAddress,
      solanaWalletObject,
      setSolanaWallet,
      connection,
      //Ethereum
      ethereumWalletAddress,
      setEthereumWalletAddress,
      ethereumWalletType,
      setEthereumWalletType,
      ethereumWalletObject,
      setEthereumWalletObject,
      // Avalanche
      avalancheWalletAddress,
      setAvalancheWalletAddress,
      avalancheWalletType,
      setAvalancheWalletType,
      avalancheWalletObject,
      setAvalancheWalletObject,
      //TRON
      tronWalletAddress,
      setTronWalletAddress,
      tronWalletType,
      setTronWalletType,
      tronWalletObject,
      setTronWalletObject,
      fromNativeBalance,
      fromNativeMinBalance,
      fromNativeMinApiBalance,
      toNativeBalance,
      fromTokenBalance,
      fromTokenMinBalance,
      toTokenBalance,
      setFromNativeBalance,
      setFromNativeMinBalance,
      setFromNativeMinApiBalance,
      setToNativeBalance,
      setToNativeMinBalance,
      setFromTokenBalance,
      setFromTokenMinBalance,
      setToTokenBalance,
      xSolOptIn,
      setxSolOptIn,
      activeStep,
      xSolAssetId,
      walletBalanceForMax,
      exchangeChainValuesHandler,
    } = this.props;

    const checkStatus = (service) => {
      let status = EXPLORER_STATUS[0];

      if (service?.available === true) status = EXPLORER_STATUS[0];
      if (service?.degraded === true) status = EXPLORER_STATUS[1];
      if (service?.maintenance === true) status = EXPLORER_STATUS[3];
      if (service?.available === false) status = EXPLORER_STATUS[2];
      return status;
    };

    const solanaTxnSignatureUrl = `https://solscan.io/tx/${solanaAckTxnSignature}?${
      network === "mainnet" ? "" : "cluster=testnet"
    }`;

    //function to handle dynamic image
    const chainLogoList = [
      {
        key: "ALGO",
        icon: isDark ? algoLogoWhite : algoLogoDark,
        url: `https://${
          network === "testnet" ? "testnet." : ""
        }algoexplorer.io/tx/${algorandTxId}`,
      },
      {
        key: "SOL",
        icon: solanaLogo,
        url: solanaTxnSignatureUrl,
      },
      {
        key: "xALGO",
        icon: xAlgoLogo,
        url: solanaTxnSignatureUrl,
      },
      {
        key: "xSOL",
        icon: xSolLogo,
        url: `https://${
          network === "testnet" ? "testnet." : ""
        }algoexplorer.io/tx/${algorandTxId}`,
      },
    ];

    const selectChainLogo = (chainKey) =>
      chainLogoList.find(({ key }) => key === chainKey)?.icon;
    const selectChainUrl = (chainKey) =>
      chainLogoList.find(({ key }) => key === chainKey)?.url;
    const sourceRequestPending =
      fromChain === "algorand"
        ? isAlgorandTxnSuccesfull
        : isSolanaTxnSuccesfull;
    const targetRequestPending =
      toChain === "solana"
        ? isSolanaAckTxnSuccesfull
        : isAlgorandTxnSuccesfull || isAlgorandAckTxnSuccesfull;
    const bridgingProcessComplete =
      !isBridgingInProgress &&
      ((isAlgorandTxnSuccesfull && isSolanaAckTxnSuccesfull) ||
        (isAlgorandAckTxnSuccesfull && isSolanaTxnSuccesfull));

    const sourceRequestFailure =
      fromChain === "algorand" ? isAlgorandTxnError : isSolanaTxnError;
    const targetRequestFailure =
      toChain === "solana" ? isSolanaTxnError : isAlgorandTxnError;

    const errorCheck =
      isTxnSignAndSendAlertOpen ||
      isCheckConnectionAlertOpen ||
      isTxnConfirmationErrorAlertOpen ||
      isAccountInitializedAlertOpen ||
      isBridgeFailureErrorAlertOpen;

    const { feeRate, algoGasFee, solGasFee, solMinBalance } =
      configData.constants;
    const bridgeFee = parseFloat(amount * feeRate);

    const disabled = () => {
      const maxBalanceFixed = Math.floor(maxBalance() * 10000) / 10000;
      if (activeStep === 0 && fromToken === "") return true;
      if (toToken === "" || toWallet === "") return true;
      //from wallet step
      const balance =
        fromToken === "ALGO" || fromToken === "SOL"
          ? fromNativeBalance
          : fromToken === "xALGO" ||
            fromToken === "xSOL" ||
            fromToken === "USDC" ||
            fromToken === "EUROC"
          ? fromTokenBalance
          : 0;

      const fromAssetInfo = configData[fromChain][network].assets_info.find(
        (a) => a.symbol.toLowerCase() === fromToken.toLowerCase()
      );

      const minBalance = Number(
        configData[fromChain][network].assets_info.find(
          (a) => a.symbol === fromToken
        )?.min_balance
      );

      const bridgeFee = parseFloat(minBalance * fromAssetInfo?.fee_rate);

      if (balance < minBalance + bridgeFee) return true;

      //from chain
      if (fromChain === "algorand") {
        if (algorandWalletAddress === "") {
          return true;
        }
      } else if (fromChain === "solana") {
        if (solanaWalletAddress === "") {
          return true;
        }
        if (isSolanaOptInAlertOpen) {
          return true;
        }
      }

      //to chain step
      if (toChain === "algorand") {
        if (algorandWalletAddress === "") {
          return true;
        }
        if (isXsolOptInAlertOpen) {
          return true;
        }
      } else if (toChain === "solana") {
        if (solanaWalletAddress === "") {
          return true;
        }
        if (isSolanaOptInAlertOpen) {
          return true;
        }
      }

      //amount step
      if (
        amount < minBalance ||
        amount > maxBalanceFixed ||
        moreThanEnoughBalanceAlert
      )
        return true;

      if (
        ["USDC", "EUROC"].includes(fromToken) &&
        ((fromChain === "ethereum" && amount < 10) ||
          (toChain === "ethereum" && amount < 20))
      ) {
        return true;
      }

      return false;
    };

    const maxBalance = () => {
      if (fromToken === "ALGO") {
        return (
          (walletBalanceForMax - algoGasFee - fromNativeMinApiBalance) / 1.005
        );
      }
      if (fromToken === "SOL") {
        return (walletBalanceForMax - solGasFee - solMinBalance) / 1.005;
      }
      if (fromToken === "xALGO") {
        return walletBalanceForMax / 1.005;
      }
      if (fromToken === "xSOL") {
        return walletBalanceForMax / 1.005;
      }
      if (fromToken === "USDC") {
        return walletBalanceForMax / 1.005;
      }
      if (fromToken === "EUROC") {
        return walletBalanceForMax / 1.005;
      }
    };

    const ExplorerStatus = ({ classes, isDark, serverStatus }) => {
      const checkColor = (status) => {
        switch (status) {
          case STATUS[0]:
            return {
              msg: "All services online",
              color: "#5AC491",
            };
          case STATUS[1]:
            return {
              msg: "Some services are currently degraded. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
              color: "#f49342",
            };
          case STATUS[2]:
            return {
              msg: "Some services are currently unavailable. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
              color: "#E84242",
            };
          case STATUS[3]:
            return {
              msg: "Some services are currently under maintenance",
              color: "#5F59F7",
            };
          default:
            return {
              msg: "All services are online",
              color: "#fff",
            };
        }
      };

      let msg = checkColor(serverStatus)?.msg;
      return (
        <Grid style={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Typography
            variant="h6"
            className={classes.status}
            style={{
              color: isDark ? "#ffffff" : "black",
            }}
          >
            Health:
            <a
              href="https://explorer.glitterfinance.org/status"
              target="_blank"
            >
              <Tooltip
                title={msg + ". Click to see details" ?? "--"}
                placement="top"
              >
                <Typography
                  component="div"
                  className={classes.statusIcon}
                  style={{
                    backgroundColor: `${checkColor(serverStatus)?.color}`,
                  }}
                />
              </Tooltip>
            </a>
          </Typography>
        </Grid>
      );
    };

    return (
      <>
        {(this.state.isUSDCSolanaDialogOpen ||
          this.state.isUSDCAlgorandDialogOpen) && (
          <USDC2Dialog
            classes={classes}
            isDark={isDark}
            toChain={toChain}
            network={network}
            fromChain={fromChain}
            isUSDCBridgeLoading={this.state.isUSDCBridgeLoading}
            USDCBridgeError={this.state.USDCBridgeError}
            solanaInitTxnSignature={this.state.solanaInitTxnSignature}
            algorandTxId={this.state.algorandTxId}
            setToDefualtState={this.setToDefaultState}
            isUSDCSolanaDialogOpen={this.state.isUSDCSolanaDialogOpen}
            isUSDCAlgorandDialogOpen={this.state.isUSDCAlgorandDialogOpen}
          />
        )}
        {isSolanaClusterTpsAlertOpen && !isBridgingInProgress && (
          <IssueAlertDialog
            classes={classes}
            isSolanaClusterTpsAlertOpen={isSolanaClusterTpsAlertOpen}
            isBridgingInProgress={isBridgingInProgress}
            isDark={isDark}
            setStateHanlder={() =>
              this.setState({ isSolanaClusterTpsAlertOpen: false })
            }
            networkDown={networkDown}
            oopsDark={oopsDark}
          />
        )}
        {isUserInActive &&
          algorandWalletAddress &&
          solanaWalletAddress &&
          !isBridgingInProgress && (
            <InactivityDisconnectDialog
              setToDefaultState={this.setToDefaultState}
              disconnectIcon={disconnectIcon}
              classes={classes}
              isDark={isDark}
            />
          )}
        {false && isDisconnectError && !isBridgingInProgress && (
          <DisconnectDialog
            classes={classes}
            isDisconnectError={isDisconnectError}
            isBridgingInProgress={isBridgingInProgress}
            setStateHandler={() => this.setState({ isDisconnectError: false })}
          />
        )}
        {(isLoading || isWaitingForOptInDialogOpen) && (
          <LoadOrWaitDialog
            classes={classes}
            isLoading={isLoading}
            setToDefaultState={this.setToDefaultState}
            isWaitingForBalanceUpdate={isWaitingForBalanceUpdate}
            isWaitingForOptInDialogOpen={isWaitingForOptInDialogOpen}
            isDark={isDark}
            activeStep={activeStep}
            toToken={toToken}
          />
        )}
        {!isLoading &&
          (isAlgoToSolBridgingDialogOpen || isSolToAlgoBridgingDialogOpen) && (
            <AlgoSolBridgingDialog
              classes={classes}
              isDark={isDark}
              sourceRequestFailure={sourceRequestFailure}
              setToDefaultState={this.setToDefaultState}
              bridgingProcessComplete={bridgingProcessComplete}
              isBridgingInProgress={isBridgingInProgress}
              solanaSignedTxn={solanaSignedTxn}
              algorandTxId={algorandTxId}
              isSolanaAckTxnSuccesfull={isSolanaAckTxnSuccesfull}
              isAlgorandAckTxnSuccesfull={isAlgorandAckTxnSuccesfull}
              selectChainLogo={selectChainLogo}
              fromToken={fromToken}
              toToken={toToken}
              targetRequestFailure={targetRequestFailure}
              targetRequestPending={targetRequestPending}
              selectChainUrl={selectChainUrl}
              sourceRequestPending={sourceRequestPending}
            />
          )}
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          onActive={this.onActive}
          debounce={1000}
          timeout={2 * MIN}
        />

        <Grid
          container
          direction="row"
          className={classes.grid}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            component="div"
            style={{ padding: "0 !important" }}
          >
            <Paper
              id="bridgePanel-paper"
              className={
                activeStep === 5 && !isAlgoBridgeSuccesfull
                  ? clsx(classes.xsRoot, classes.appContainer)
                  : clsx(classes.root, classes.appContainer)
              }
              elevation={4}
            >
              <Box sx={{ position: "relative" }}>
                {!this.state.displayReview && (
                  <div>
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <ExplorerStatus
                        classes={classes}
                        isDark={isDark}
                        serverStatus={checkStatus(serverStatus?.overall)}
                      />
                      <Typography
                        variant="h1"
                        style={{
                          color: "#ff99d6",
                        }}
                      >
                        GLITTER BRIDGE
                      </Typography>
                      <ButtonComp
                        href={"https://explorer.glitterfinance.org"}
                        target="_blank"
                        className={classes.explorerButton}
                      >
                        Bridge Explorer
                      </ButtonComp>
                    </div>
                    <Box
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        margin: "10px",
                      }}
                    >
                      <StatusBar isDark={isDark} connection={connection} />
                    </Box>
                    {(isTransactionErrorAlertOpen ||
                      isTxnSignAndSendAlertOpen ||
                      isCheckConnectionAlertOpen ||
                      isTxnConfirmationErrorAlertOpen ||
                      isBridgeFailureErrorAlertOpen ||
                      isAccountInitializedAlertOpen ||
                      isWalletEmptyAlertOpen ||
                      moreThanEnoughBalanceAlert ||
                      notEnoughNativeBalanceAlertOpen ||
                      notEnoughTokenBalanceAlertOpen ||
                      notEnoughNativeBalanceAlertOpen ||
                      MoreThanBalanceAlertOpen ||
                      isSolanaClusterTpsAlertOpen) && (
                      <Box className={classes.xsBox}>
                        <AlertBox
                          isDark={isDark}
                          activeStep={activeStep}
                          network={network}
                          connection={connection}
                          fromChain={fromChain}
                          toChain={toChain}
                          fromToken={fromToken}
                          toToken={toToken}
                          fromWallet={fromWallet}
                          toWallet={toWallet}
                          fromNativeMinBalance={fromNativeMinBalance}
                          fromTokenMinBalance={fromTokenMinBalance}
                          setToNativeBalance={setToNativeBalance}
                          setToTokenBalance={setToTokenBalance}
                          solanaWalletObject={solanaWalletObject}
                          algorandWalletAddress={algorandWalletAddress}
                          algorandWalletType={algorandWalletType}
                          solanaAssetInfo={solanaAssetInfo}
                          isBridgingInProgress={isBridgingInProgress}
                          isWalletEmptyAlertOpen={isWalletEmptyAlertOpen}
                          isTransactionErrorAlertOpen={
                            isTransactionErrorAlertOpen
                          }
                          isTxnSignAndSendAlertOpen={isTxnSignAndSendAlertOpen}
                          isAccountInitializedAlertOpen={
                            isAccountInitializedAlertOpen
                          }
                          isCheckConnectionAlertOpen={
                            isCheckConnectionAlertOpen
                          }
                          isTxnConfirmationErrorAlertOpen={
                            isTxnConfirmationErrorAlertOpen
                          }
                          isBridgeFailureErrorAlertOpen={
                            isBridgeFailureErrorAlertOpen
                          }
                          toNativeBalance={toNativeBalance}
                          xSolAssetId={xSolAssetId}
                          notEnoughNativeBalanceAlertOpen={
                            notEnoughNativeBalanceAlertOpen
                          }
                          moreThanEnoughBalanceAlert={
                            moreThanEnoughBalanceAlert
                          }
                          notEnoughTokenBalanceAlertOpen={
                            notEnoughTokenBalanceAlertOpen
                          }
                          MoreThanBalanceAlertOpen={MoreThanBalanceAlertOpen}
                          handleCloseAlert={this.handleCloseAlert}
                          isSolanaClusterTpsAlertOpen={
                            isSolanaClusterTpsAlertOpen
                          }
                          isDisconnectError={isDisconnectError}
                          setIsDisconnectError={(isOpen) =>
                            this.setState({
                              isDisconnectError: isOpen,
                            })
                          }
                          setIsSolanaClusterTpsAlertOpen={(isOpen) =>
                            this.setState({
                              isSolanaClusterTpsAlertOpen: isOpen,
                            })
                          }
                        />
                      </Box>
                    )}
                    <div className={classes.fromChainContainer}>
                      <div>
                        <FromChainStep
                          isDark={isDark}
                          // activeStep={activeStep}
                          // handleNext={this.handleNext}
                          fromChain={fromChain}
                          fromToken={fromToken}
                          setFromChain={setFromChain}
                          setFromToken={setFromToken}
                          setToToken={setToToken}
                          setToChain={setToChain}
                          setAlgorandWalletType={setAlgorandWalletType}
                          setAlgoWallet={setAlgoWallet}
                          setSolanaWallet={setSolanaWallet}
                          //Ethereum
                          setEthereumWalletObject={setEthereumWalletObject}
                          setEthereumWalletAddress={setEthereumWalletAddress}
                          //TRON
                          setTronWalletObject={setTronWalletObject}
                          setTronWalletAddress={setTronWalletAddress}
                          setIsXsolOptInAlertOpen={(isOpen) =>
                            this.setState({ isXsolOptInAlertOpen: isOpen })
                          }
                          setAmount={setAmount}
                          network={network}
                          fromWallet={fromWallet}
                          algorandWalletAddress={algorandWalletAddress}
                          setFromWallet={setFromWallet}
                          setAlgorandWalletObject={setAlgorandWalletObject}
                          setFromNativeMinBalance={setFromNativeMinBalance}
                          setFromNativeMinApiBalance={
                            setFromNativeMinApiBalance
                          }
                          setFromTokenMinBalance={setFromTokenMinBalance}
                        />
                      </div>
                      <br />
                      <FromWalletStep
                        isDark={isDark}
                        setIsLoading={(status) =>
                          this.setState({ isLoading: status })
                        }
                        network={network}
                        connection={connection}
                        fromNativeBalance={fromNativeBalance}
                        fromTokenBalance={fromTokenBalance}
                        setIsWaitingForBalanceUpdate={(status) =>
                          this.setState({ isWaitingForBalanceUpdate: status })
                        }
                        fromNativeMinBalance={fromNativeMinBalance}
                        fromTokenMinBalance={fromTokenMinBalance}
                        fromNativeMinApiBalance={fromNativeMinApiBalance}
                        setFromNativeBalance={setFromNativeBalance}
                        setFromTokenBalance={setFromTokenBalance}
                        setFromNativeMinBalance={setFromNativeMinBalance}
                        setFromNativeMinApiBalance={setFromNativeMinApiBalance}
                        setIsWaitingForOptIn={(status) =>
                          this.setState({ isWaitingForOptInDialogOpen: status })
                        }
                        setIsXsolOptInAlertOpen={(isOpen) =>
                          this.setState({ isXsolOptInAlertOpen: isOpen })
                        }
                        isXsolOptInAlertOpen={isXsolOptInAlertOpen}
                        xSolOptIn={xSolOptIn}
                        setxSolOptIn={setxSolOptIn}
                        solanaAssetInfo={solanaAssetInfo}
                        algorandAssetInfo={algorandAssetInfo}
                        fromWallet={fromWallet}
                        fromToken={fromToken}
                        toToken={toToken}
                        fromChain={fromChain}
                        setFromWallet={setFromWallet}
                        algorandWalletObject={algorandWalletObject}
                        algorandWalletType={algorandWalletType}
                        algorandWalletAddress={algorandWalletAddress}
                        setAlgorandWalletObject={setAlgorandWalletObject}
                        setAlgorandWalletType={setAlgorandWalletType}
                        setAlgoWallet={setAlgoWallet}
                        solanaWalletType={solanaWalletType}
                        solanaWalletAddress={solanaWalletAddress}
                        solanaWalletObject={solanaWalletObject}
                        setSolanaWallet={setSolanaWallet}
                        ethereumWalletAddress={ethereumWalletAddress}
                        setEthereumWalletAddress={setEthereumWalletAddress}
                        ethereumWalletType={ethereumWalletType}
                        setEthereumWalletType={setEthereumWalletType}
                        ethereumWalletObject={ethereumWalletObject}
                        setEthereumWalletObject={setEthereumWalletObject}
                        //TRON
                        tronWalletAddress={tronWalletAddress}
                        setTronWalletAddress={setTronWalletAddress}
                        tronWalletType={tronWalletType}
                        setTronWalletType={setTronWalletType}
                        tronWalletObject={tronWalletObject}
                        setTronWalletObject={setTronWalletObject}
                        setIsWalletEmptyAlertOpen={(isOpen) =>
                          this.setState({ isWalletEmptyAlertOpen: isOpen })
                        }
                        setAmount={setAmount}
                        isSolanaOptIn={isSolanaOptIn}
                        setIsSolanaOptIn={(isOptIn) =>
                          this.setState({ isSolanaOptIn: isOptIn })
                        }
                        isSolanaOptInAlertOpen={isSolanaOptInAlertOpen}
                        setSolanaOptInAlertOpen={(isOpen) =>
                          this.setState({ isSolanaOptInAlertOpen: isOpen })
                        }
                        setIsAlgoWalletOptIn={(isOptIn) =>
                          this.setState({ isAlgoWalletOptIn: isOptIn })
                        }
                        setIsCheckConnectionAlertOpen={(isOpen) =>
                          this.setState({ isCheckConnectionAlertOpen: isOpen })
                        }
                      />
                    </div>
                    <div className={classes.dividerContainer}>
                      <Divider>
                        <Tooltip title="Exchange values">
                          <img
                            alt="swap button"
                            src={DividerImage}
                            style={{
                              cursor: "pointer",
                              width: "40px",
                            }}
                            onClick={() => exchangeChainValuesHandler()}
                          />
                        </Tooltip>
                      </Divider>
                    </div>
                    <div className={classes.fromChainContainer}>
                      <div>
                        <ToChainStep
                          isDark={isDark}
                          toChain={toChain}
                          fromChain={fromChain}
                          setToChain={setToChain}
                          setToToken={setToToken}
                          setAvalancheWalletObject={setAvalancheWalletObject}
                          setAvalancheWalletAddress={setAvalancheWalletAddress}
                          //TRON
                          setTronWalletObject={setTronWalletObject}
                          setTronWalletAddress={setTronWalletAddress}
                          notEnoughNativeBalanceAlertOpen={
                            notEnoughNativeBalanceAlertOpen
                          }
                          network={network}
                          toToken={toToken}
                          fromToken={fromToken}
                          setToNativeMinBalance={setToNativeMinBalance}
                        />
                      </div>
                      <br />
                      <ToWalletStep
                        isDark={isDark}
                        setIsLoading={(status) =>
                          this.setState({ isLoading: status })
                        }
                        network={network}
                        connection={connection}
                        activeStep={activeStep}
                        fromChain={fromChain}
                        toChain={toChain}
                        toToken={toToken}
                        fromToken={fromToken}
                        fromWallet={fromWallet}
                        setIsWaitingForBalanceUpdate={(status) =>
                          this.setState({ isWaitingForBalanceUpdate: status })
                        }
                        toWallet={toWallet}
                        setToWallet={setToWallet}
                        isOptInAlertOpen={isOptInAlertOpen}
                        isXsolOptInAlertOpen={isXsolOptInAlertOpen}
                        setIsXsolOptInAlertOpen={(isOpen) =>
                          this.setState({ isXsolOptInAlertOpen: isOpen })
                        }
                        isOptInSuccessfulAlertOpen={isOptInSuccessfulAlertOpen}
                        setIsOptInSuccessfulAlertOpen={(isOpen) =>
                          this.setState({
                            isOptInSuccessfulAlertOpen: isOpen,
                          })
                        }
                        setIsWaitingForOptInDialogOpen={(isOpen) =>
                          this.setState({
                            isWaitingForOptInDialogOpen: isOpen,
                          })
                        }
                        xSolOptIn={xSolOptIn}
                        setxSolOptIn={setxSolOptIn}
                        solanaAssetInfo={solanaAssetInfo}
                        algorandAssetInfo={algorandAssetInfo}
                        toNativeBalance={toNativeBalance}
                        toTokenBalance={toTokenBalance}
                        setToNativeBalance={setToNativeBalance}
                        setToTokenBalance={setToTokenBalance}
                        notEnoughNativeBalanceAlertOpen={
                          notEnoughNativeBalanceAlertOpen
                        }
                        algorandWalletAddress={algorandWalletAddress}
                        algorandWalletType={algorandWalletType}
                        setAlgoWallet={setAlgoWallet}
                        setAlgorandWalletObject={setAlgorandWalletObject}
                        setAlgorandWalletType={setAlgorandWalletType}
                        solanaWalletType={solanaWalletType}
                        solanaWalletAddress={solanaWalletAddress}
                        solanaWalletObject={solanaWalletObject}
                        setSolanaWallet={setSolanaWallet}
                        //Avalanche
                        fromTokenBalance={fromTokenBalance}
                        ethereumWalletAddress={ethereumWalletAddress}
                        ethereumWalletType={ethereumWalletType}
                        ethereumWalletObject={ethereumWalletObject}
                        avalancheWalletAddress={avalancheWalletAddress}
                        setAvalancheWalletAddress={setAvalancheWalletAddress}
                        avalancheWalletType={avalancheWalletType}
                        setAvalancheWalletType={setAvalancheWalletType}
                        avalancheWalletObject={avalancheWalletObject}
                        setAvalancheWalletObject={setAvalancheWalletObject}
                        //TRON
                        tronWalletAddress={tronWalletAddress}
                        setTronWalletAddress={setTronWalletAddress}
                        tronWalletType={tronWalletType}
                        setTronWalletType={setTronWalletType}
                        tronWalletObject={tronWalletObject}
                        setTronWalletObject={setTronWalletObject}
                        setIsWalletEmptyAlertOpen={(isOpen) =>
                          this.setState({ isWalletEmptyAlertOpen: isOpen })
                        }
                        isSolanaOptIn={isSolanaOptIn}
                        setIsSolanaOptIn={(isOptIn) =>
                          this.setState({ isSolanaOptIn: isOptIn })
                        }
                        isSolanaOptInAlertOpen={isSolanaOptInAlertOpen}
                        setSolanaOptInAlertOpen={(isOpen) =>
                          this.setState({ isSolanaOptInAlertOpen: isOpen })
                        }
                        setIsAlgoWalletOptIn={(isOptIn) =>
                          this.setState({ isAlgoWalletOptIn: isOptIn })
                        }
                        setIsCheckConnectionAlertOpen={(isOpen) =>
                          this.setState({ isCheckConnectionAlertOpen: isOpen })
                        }
                      />
                    </div>
                    <MaxInput
                      fromToken={fromToken}
                      isDark={isDark}
                      amount={amount}
                      fromChain={fromChain}
                      toChain={toChain}
                      setAmount={this.props.setAmount}
                      maxBalance={maxBalance}
                    />
                    <div className={classes.footerContainer}>
                      <ColorButtonComp
                        variant="filled"
                        color="primary"
                        disabled={disabled()}
                        className={classes.proceedButton}
                        onClick={this.gotoReviewHandler}
                      >
                        PROCEED
                      </ColorButtonComp>

                      <WidgetIntegration />
                      <Footer />
                    </div>
                  </div>
                )}
              </Box>
              {this.state.displayReview && (
                <Review
                  fromChain={fromChain}
                  toChain={toChain}
                  fromToken={fromToken}
                  toToken={toToken}
                  fromWallet={fromWallet}
                  toWallet={toWallet}
                  amount={amount}
                  bridgeFee={bridgeFee}
                  handleClickProceedButton={this.handleClickProceedButton}
                  errorCheck={errorCheck}
                  proceedButtonDisabled={proceedButtonDisabled}
                  isBridgingInProgress={isBridgingInProgress}
                  displayReviewHandler={this.displayReviewHandler}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
BridgePanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,
  xSolOptIn: PropTypes.bool.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  fromWallet: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  toToken: PropTypes.string.isRequired,
  toWallet: PropTypes.string.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setFromWallet: PropTypes.func.isRequired,
  setFromChain: PropTypes.func.isRequired,
  setFromToken: PropTypes.func.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToChain: PropTypes.func.isRequired,
  setToWallet: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  solanaAssetInfo: PropTypes.object,
  algorandAssetInfo: PropTypes.object,
  algorandWalletAddress: PropTypes.string.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  fromNativeBalance: PropTypes.number,
  toNativeBalance: PropTypes.number,
  fromTokenBalance: PropTypes.number,
  toTokenBalance: PropTypes.number,
  setFromNativeBalance: PropTypes.func.isRequired,
  setToNativeBalance: PropTypes.func.isRequired,
  setFromTokenBalance: PropTypes.func.isRequired,
  setToTokenBalance: PropTypes.func.isRequired,
  setFromNativeMinBalance: PropTypes.func.isRequired,
  setFromNativeMinApiBalance: PropTypes.func.isRequired,
  setToNativeMinBalance: PropTypes.func.isRequired,
  setFromTokenMinBalance: PropTypes.func.isRequired,
};
export default withStyles(styles)(BridgePanel);
