import configData from "../config.json";

const ethereumConfig = configData.ethereum;
const avalancheConfig = configData.avalanche;
const polygonConfig = configData.polygon;

/**
 *
 * Ethereum
 */

export const getEthRPC = (network) => {
  return ethereumConfig[network].server;
};
export const getEthExplorer = (network) => {
  return ethereumConfig[network].explorer;
};
export const getEthAssetInfo = (network, assetname) => {
  return (
    ethereumConfig[network].assets_info.filter(
      (asset) => asset.symbol === assetname
    )[0] || ""
  );
};

/**
 *
 * Avalanche
 */

export const getAvalancheRPC = (network) => {
  return avalancheConfig[network].server;
};
export const getAvalancheExplorer = (network) => {
  return avalancheConfig[network].explorer;
};
export const getAvalanchAssetInfo = (network, assetname) => {
  return (
    avalancheConfig[network].assets_info.filter(
      (asset) => asset.symbol === assetname
    )[0] || ""
  );
};

/**
 *
 * Polygon
 */
export const getPolygonRPC = (network) => {
  return polygonConfig[network].server;
};
export const getPolygonExplorer = (network) => {
  return polygonConfig[network].explorer;
};
export const getPolygonAssetInfo = (network, assetname) => {
  return (
    polygonConfig[network].assets_info.filter(
      (asset) => asset.symbol === assetname
    )[0] || ""
  );
};
/**
 *
 * general
 */

export const getUsdcReceiverAddress = (chain, network) => {
  return configData[chain][network].usdcAddressReceiver;
};
export const getBridgeOwnerAddress = (chain, network) => {
  return configData[chain][network].bridgeOwnerAddress;
};

export const getEurocReceiverAddress = (chain, network) => {
  return configData[chain][network].usdcAddressReceiver;
};
