import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  MenuItem,
  InputAdornment,
  FormControl,
  TextField,
  Select,
  Tooltip,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";

import solanaLogo from "../../../assets/images/solana.svg";
import algorandDropdown from "../../../assets/images/algorandDropdown.svg";
import solanaDropdown from "../../../assets/images/solanaDropdown.svg";
import algoLogoDark from "../../../assets/images/algo.png";
import algoLogoWhite from "../../../assets/images/algo-white.png";
import xAlgoLogo from "../../../assets/images/xalgo.png";
import xSolLogo from "../../../assets/images/xsol.png";
import USDCLogo from "../../../assets/images/usdc.png";
import EUROCLogo from "../../../assets/images/euroc.svg";
import styles from "./ToChainStepStyles";
import { allChains } from "../../../utils/data";

const ToChainStep = (props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const chainRef = useRef(null);

  const handleChangeToChain = (event) => {
    props.setToChain(event.target.value);
  };
  //toToken
  const handleChange = (event) => {
    const { setToToken, setToNativeMinBalance } = props;

    setToToken(event.target.value);
    setToNativeMinBalance(0.1);
  };

  const { classes, isDark, toChain, fromChain, toToken, fromToken } = props;

  const walletAssetLogo = (asset) => {
    switch (asset) {
      case "ALGO":
        return isDark ? algoLogoDark : algoLogoWhite;
      case "xALGO":
        return xAlgoLogo;
      case "SOL":
        return solanaLogo;
      case "xSOL":
        return xSolLogo;
      case "USDC":
        return USDCLogo;
      case "EUROC":
        return EUROCLogo;
      default:
        return "";
    }
  };

  let ToChains = [];
  if (fromToken === "USDC") {
    ToChains = allChains.filter((c) => c.value !== fromChain);
  } else if (["ALGO", "xSOL"].includes(fromToken)) {
    ToChains = [allChains[1]];
  } else if (["SOL", "xALGO"].includes(fromToken)) {
    ToChains = [allChains[0]];
  } else if (["EUROC"].includes(fromToken)) {
    ToChains =
      fromChain === "ethereum"
        ? allChains.filter((c) => c.value === "avalanche")
        : allChains.filter((c) => c.value === "ethereum");
  } else {
    ToChains = [];
  }

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      className={classes.grid}
      justifyContent="center"
    >
      <Grid item xs={7} sm={7} md={7} className={classes.gridItem}>
        <FormControl className={classes.formControlRoot}>
          <Select
            labelId="tochain-simple-select-label"
            id="tochain-simple-select"
            onOpen={() => setDropDownOpen(true)}
            onClose={() => setDropDownOpen(false)}
            open={dropDownOpen}
            native={false}
            ref={chainRef}
            style={{ color: "white", width: "100%" }}
            className={isDark ? classes.selectDark : classes.select}
            value={toChain}
            onChange={(e) => handleChangeToChain(e)}
            variant="outlined"
            MenuProps={{
              elevation: 0,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            }}
            disabled={props.notEnoughNativeBalanceAlertOpen === true}
            IconComponent={() => (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <ExpandMoreIcon />
              </InputAdornment>
            )}
          >
            {fromChain === "algorand" && fromToken !== "USDC" ? (
              <MenuItem
                key={"solana"}
                value={"solana"}
                className={classes.menuItemRoot}
                selected={false}
                name={"Solana"}
              >
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={solanaDropdown}
                    alt={"solana"}
                    className={classes.menuItemImgChain}
                  />
                  <span className={classes.inputlable}>Solana</span>
                </Typography>
              </MenuItem>
            ) : fromChain === "solana" && fromToken !== "USDC" ? (
              <MenuItem
                key={"algorand"}
                value={"algorand"}
                className={classes.menuItemRoot}
                selected={false}
                name={"Algorand"}
              >
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={algorandDropdown}
                    alt={"algorand"}
                    className={classes.menuItemImgChain}
                  />
                  <span className={classes.inputlable}>Algorand</span>
                </Typography>
              </MenuItem>
            ) : (
              ToChains.map((chain) => (
                <MenuItem
                  key={chain?.value}
                  value={chain?.value}
                  className={classes.menuItemRoot}
                  selected={false}
                  name={chain?.label}
                >
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={chain?.img}
                      alt={chain?.label}
                      className={classes.menuItemImgChain}
                    />
                    <span className={classes.inputlable}>{chain?.label}</span>
                  </Typography>
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5} sm={5} md={5} className={classes.gridItem}>
        <Tooltip title={!fromToken ? "Choose from token first" : ""}>
          <FormControl className={classes.formControlRoot}>
            <TextField
              variant="outlined"
              select
              label="Token"
              value={toToken}
              onChange={handleChange}
              defaultValue={props.fromToken}
              InputLabelProps={{
                shrink: false,
              }}
              disabled={
                props.notEnoughNativeBalanceAlertOpen === true || !fromToken
              }
              SelectProps={{
                MenuProps: {
                  elevation: 0,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                },
                IconComponent: () => (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <ExpandMoreIcon />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem
                key={toToken}
                value={toToken}
                className={classes.tokenMenuItemRoot}
              >
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <img
                    alt={toToken}
                    src={walletAssetLogo(toToken)}
                    className={classes.menuItemImg}
                  />
                  <span className={classes.inputlable}>{toToken}</span>
                </Typography>
              </MenuItem>
            </TextField>
          </FormControl>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

ToChainStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  fromChain: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  setToChain: PropTypes.func.isRequired,
  network: PropTypes.string.isRequired,
  toToken: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToNativeMinBalance: PropTypes.func,
  setToTokeneMinBalance: PropTypes.func,
};

const _ToChainStep = memo(ToChainStep);
export default withStyles(styles)(_ToChainStep);
