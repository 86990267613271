import { Typography, withStyles } from "@material-ui/core";
import glitterBridge from "../assets/images/Glitter.svg";
import React from "react";

export const styles = (theme) => ({
  poweredByContainer: {
    justifyContent: "center",
    alignItems: "center",
    margin: "25px 0 0",
  },
  poweredByText: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "10px",
    textAlign: "center",
    letterSpacing: "1%",
    color: "#ffffff",
  },
  glitterText: {
    fontFamily: "ProximaNova",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "16px",
    lineHeight: "18.78px",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: theme.palette.update3Palette.text1,
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px !important",
    },
  },
});

function Footer({ classes }) {
  return (
    <div className={classes.poweredByContainer}>
      <Typography className={classes.poweredByText}>Powered By</Typography>
      <Typography className={classes.glitterText}>GLITTER</Typography>

      <Typography className={classes.poweredByText} style={{ opacity: 0.6 }}>
        2022 Glitter Finance. All Rights Reserved.
      </Typography>
    </div>
  );
}

export default withStyles(styles)(Footer);
