const styles = (theme) => ({
  formControlRoot: {
    width: "100%",
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center",
    },
  },

  select: {
    "& .MuiOutlinedInput-input": {
      color: "#5e14a8",
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "& .MuiInputLabel-root": {
      color: "#ffffff",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      color: "#ffffff",
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#ffffff",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "gray",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      color: "#ffffff",
    },
    "& .MuiSelect-icon": {
      display: "none",
    },
    "@media (max-width:400px)": {
      paddingRight: "0px",
      "& .MuiSelect-outlined": {
        paddingRight: "0px",
        paddingLeft: "7px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: "41px !important",
    },
  },
  inputAdornment: {
    marginLeft: "0px",
    fontSize: "20px",
    color: "#5e14a8",
    marginRight: "10px",
  },
  inputlable: {
    fontWeight: "600",
    paddingLeft: "10px",
    lineHeight: "30px",
    "@media (max-width:400px)": {
      paddingLeft: "5px",
    },
  },
  tokenLabel: {
    fontSize: "12px",
    transform: "translate(0, 16px) scale(1)",
    padding: "0 0 0 10px",
  },
  selectDarkToken: {
    height: "44px",
  },
  selectIcon: {
    color: "#000",
  },
  menuItemRoot: {
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "4px",
    [theme.breakpoints.down("xs")]: {
      width: "163px",
    },
  },
  tokenMenuItemRoot: {
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "4px",
    backdropFilter: "blur(9px)",
    [theme.breakpoints.down("xs")]: {
      width: "124px",
    },
  },
  tokenDownArrow: {
    width: 87,
    margin: "0px",
    [theme.breakpoints.down("xs")]: {
      boxSizing: "border-box",
      width: 140,
      padding: 0,
      margin: "0px",
    },
  },
  selectPaper: {
    background: "red",
    color: "white",
  },
  selectInput: {
    color: "red",
    backgroundColor: "rgba(255, 0, 0, 0.4)",
    "&:focus": {
      borderRadius: 4,
      borderColor: "rgba(255,255,255,0.2)",
      boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
      background: "rgba(0,0,0,0)",
    },
  },
  menuItemSelected: {
    background: "#1B1B1B",
    color: "#fff",
  },
  assetText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "5px",
    },
  },
  gridFielset: {
    [theme.breakpoints.up("md")]: {
      float: "center",
    },
  },
  image: {
    width: 60,
    marginTop: 0,
    zIndex: 2,
    cursor: "pointer",
    marginRight: "50px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "45px",
    },
    "@media (max-width:400px)": {
      marginRight: "2px !important",
      width: "40px !important",
      height: "20px !important",
    },
  },
  tokenImage: {
    marginRight: "50px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-9px !important",
    },
    "@media (max-width:400px)": {
      marginLeft: "-3px !important",
      marginRight: "2px !important",
      width: "40px !important",
      height: "20px !important",
    },
  },
  menuItemImgChain: {
    width: 30,
    height: 30,
    zIndex: 2,
    display: "inline-block",
  },
  menuItemImg: {
    width: 30,
    height: 30,
    zIndex: 2,
    display: "inline-block",
    "@media (max-width:400px)": {
      minWidth: 14,
      width: 14,
      height: 14,
    },
  },
});

export default styles;
