export const styles = (theme) => ({
  reviewContainer: {
    marginBottom: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  backButton: {
    position: "absolute",
    left: 10,
    top: 10,
    minWidth: "40px",
  },
  reviewText: {
    color: "#ffffff",
    fontFamily: "ProximaNova",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "25px",
    textAlign: "center",
    letterSpacing: "0.1em",
    lineHeight: "24px",
  },
  containerList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    width: "100%",
    padding: "12px 5px",
  },
  container: {
    background:
      "linear-gradient(81.8deg, rgba(251, 60, 255, 0.5) -9.97%, rgba(50, 112, 205, 0.45) 45.69%, rgba(168, 66, 249, 0.5) 98.31%), #FFFFFF",
    backdropFilter: "blur(21px)",
    borderRadius: "10px",
    paddingTop: "25px",
    paddingBottom: "25px",
    paddingLeft: "25px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "left",
    letterSpacing: "0.01em",
    marginBottom: "8px",
  },
  boldText: {
    fontWeight: "700",
  },
  footerContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  poweredByContainer: {
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 10px",
    padding: "10px 15px",
  },
  poweredByText: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "10px",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#ffffff",
  },
  rightsReservedText: {
    color: "#6B6B6B !important",
  },
  glitterText: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "30px",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#F66FC0",
  },
  menuItemImg: {
    width: 23,
    zIndex: 2,
  },
});
