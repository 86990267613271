import React, { useState } from "react";
import { Grid, Tooltip, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import CopyIcon from "../../../../../assets/images/content-copy.svg";
import { styles } from "../BridgePanelStyles";

const WidgetIntegration = ({ classes }) => {
  const [isCopied, setIsCopied] = useState(false);
  const content = `<iframe src="https://widget.glitterfinance.org/"  title="Glitter Bridge" width="640" height="1040"></iframe>`;

  return (
    <Grid className={classes.integrationParent}>
      <Typography className={classes.integrationTitle}>INTEGRATION</Typography>

      <Grid className={classes.integrationContainer}>
        <Grid container className={classes.firstRow}>
          <Typography className={classes.tileTitle}>EMBED CODE</Typography>
          {isCopied ? (
            <Typography
              component="span"
              variant="p"
              className={classes.copiedText}
            >
              Copied
            </Typography>
          ) : (
            <Tooltip title="Copy snippet">
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(content);
                  setIsCopied(true);
                  setTimeout(() => setIsCopied(false), 2000);
                }}
                style={{
                  padding: "10px",
                  minWidth: "40px",
                }}
                edge="end"
              >
                <img
                  alt="copy"
                  src={CopyIcon}
                  style={{
                    fontSize: "16px",
                  }}
                />
              </Button>
            </Tooltip>
          )}
        </Grid>
        <Grid container className={classes.innerBox}>
          <Typography component="span" variant="p" className={classes.boxText}>
            <span style={{ fontWeight: "bold" }}>Instructions: </span>
            <span>
              Copy the snippet below by clicking on the copy icon, paste it into
              your website, and you are all set. For additional support, email
              support@glitter.finance.
            </span>
            <p className={classes.thinLine}></p>
            {content}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(WidgetIntegration);
