import { Typography } from "@material-ui/core";
import React from "react";

export function ContactGlitter() {
  return (
    <Typography
      variant="body1"
      color="white"
      style={{
        marginTop: "12px",
        textAlign: "center",
        fontWeight: "700",
        fontFamily: "Barlow",
        color: "white",
      }}
    >
      In case of technical issues please contact
      <br /> us at{" "}
      <a
        href="mailto:contact@glitter.finance"
        style={{
          color: "#F66FC0",
        }}
      >
        Glitter
      </a>{" "}
      team.
    </Typography>
  );
}
