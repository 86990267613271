import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  PublicKey,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import { serialize } from "borsh";
import { usdcRecieverAddressSolana } from "./constants";

/* ------------------------------------------------------------ 
  Instruction's struct
--------------------------------------------------------------- */
export class BridgeInstruction {
  constructor(properties) {
    Object.keys(properties).forEach((key) => {
      this[key] = properties[key];
    });
  }
  static set_schema = new Map([
    [
      BridgeInstruction,
      {
        kind: "struct",
        fields: [["validator_address", [32]]],
      },
    ],
  ]);
  static init_schema = new Map([
    [
      BridgeInstruction,
      {
        kind: "struct",
        fields: [
          ["algo_address", [32]],
          ["amount", "u64"],
        ],
      },
    ],
  ]);
  static approve_schema = new Map([
    [
      BridgeInstruction,
      {
        kind: "struct",
        fields: [
          ["algo_address", [32]],
          ["algo_txn_id", [52]],
        ],
      },
    ],
  ]);
  static cancel_schema = new Map([
    [
      BridgeInstruction,
      {
        kind: "struct",
        fields: [["algo_address", [32]]],
      },
    ],
  ]);
  static release_schema = new Map([
    [
      BridgeInstruction,
      {
        kind: "struct",
        fields: [
          ["algo_address", [32]],
          ["algo_txn_id", [52]],
          ["amount", "u64"],
        ],
      },
    ],
  ]);
}

/* ------------------------------------------------------------ 
  Initialize bridiging by creating and initializing the escrow account
  and locking the lamports into the account
--------------------------------------------------------------- */
export function initNativeBridgeIx(accounts, algo_address, amount) {
  let data = serialize(
    BridgeInstruction.init_schema,
    new BridgeInstruction({ algo_address, amount })
  );
  data = new Uint8Array([10, ...data]);
  return new TransactionInstruction({
    programId: accounts[0],
    keys: [
      { pubkey: accounts[1], isSigner: true, isWritable: false },
      { pubkey: accounts[2], isSigner: false, isWritable: true },
      { pubkey: accounts[3], isSigner: false, isWritable: false },
      { pubkey: accounts[4], isSigner: false, isWritable: false },
    ],
    data: data,
  });
}

/* ------------------------------------------------------------ 
  Initialize bridiging by creating and initializing the escrow ata account
  and locking tokens into the account
--------------------------------------------------------------- */
export function initTokenBridgeIx(accounts, algo_address, amount) {
  let data = serialize(
    BridgeInstruction.init_schema,
    new BridgeInstruction({ algo_address, amount })
  );
  data = new Uint8Array([20, ...data]);
  return new TransactionInstruction({
    programId: accounts[0],
    keys: [
      { pubkey: accounts[1], isSigner: true, isWritable: false },
      { pubkey: accounts[2], isSigner: false, isWritable: true },
      { pubkey: accounts[3], isSigner: false, isWritable: true },
      { pubkey: accounts[4], isSigner: false, isWritable: true },
      { pubkey: accounts[5], isSigner: false, isWritable: false },
      { pubkey: accounts[6], isSigner: false, isWritable: false },
      { pubkey: accounts[7], isSigner: false, isWritable: false },
      { pubkey: accounts[8], isSigner: false, isWritable: false },
    ],
    data: data,
  });
}

/* ------------------------------------------------------------ 
  Initialize bridiging by creating and initializing the escrow ata account
  and locking tokens into the account
--------------------------------------------------------------- */
export function initTokenUSDCBridgeIx(
  accounts,
  algo_address,
  amount,
  network,
  numberAmount,
  toChain
) {
  const memoProgramId = "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr";
  const destination = usdcRecieverAddressSolana(network);
  let instructions = [];

  const data = {
    system: {
      from: {
        token: "USDC",
        network: "solana",
        address: accounts[0],
        txn_signature: "",
      },
      to: {
        token: "USDC",
        network: toChain,
        address: algo_address,
        txn_signature: "",
      },
      amount: String(+numberAmount),
      units: String(+amount),
    },
    date: new Date().toISOString(),
  };

  instructions.push(
    Token.createTransferInstruction(
      TOKEN_PROGRAM_ID,
      accounts[2],
      destination,
      accounts[1],
      [],
      amount
    )
  );

  instructions.push(
    new TransactionInstruction({
      keys: [{ pubkey: accounts[1], isSigner: true, isWritable: true }],
      data: Buffer.from(JSON.stringify(data), "utf-8"),
      programId: new PublicKey(memoProgramId),
    })
  );

  return instructions;
}
/* ------------------------------------------------------------ 
  Unlock tokens to the user's ata (Applying vesting contract)
--------------------------------------------------------------- */
export function unlockTokensIx(accounts, seeds) {
  const data = Buffer.concat([Buffer.from(Int8Array.from([2]).buffer), seeds]);
  return new TransactionInstruction({
    programId: accounts[0],
    keys: [
      { pubkey: accounts[1], isSigner: false, isWritable: true },
      { pubkey: accounts[2], isSigner: false, isWritable: true },
      { pubkey: accounts[3], isSigner: false, isWritable: true },
      { pubkey: accounts[4], isSigner: false, isWritable: false },
    ],
    data: data,
  });
}

/* ------------------------------------------------------------ 
  Create a new program derived account
--------------------------------------------------------------- */
export function createPdaIx(accounts, amount) {
  return SystemProgram.createAccountWithSeed({
    programId: accounts[0],
    fromPubkey: accounts[1],
    basePubkey: accounts[1],
    newAccountPubkey: accounts[2],
    lamports: amount,
    seed: "glitterfinance",
    space: 1,
  });
}

/* ------------------------------------------------------------ 
  Create an associated token account
--------------------------------------------------------------- */
export function createAtaIx(accounts) {
  return new TransactionInstruction({
    programId: accounts[0],
    keys: [
      { pubkey: accounts[1], isSigner: true, isWritable: false },
      { pubkey: accounts[2], isSigner: false, isWritable: true },
      { pubkey: accounts[3], isSigner: false, isWritable: true },
      { pubkey: accounts[4], isSigner: false, isWritable: false },
      { pubkey: accounts[5], isSigner: false, isWritable: false },
      { pubkey: accounts[6], isSigner: false, isWritable: false },
      { pubkey: accounts[7], isSigner: false, isWritable: false },
    ],
    data: new Uint8Array([]),
  });
}
