import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, Box, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { clsx } from "clsx";

import algoLogoDark from "../../assets/images/algo.png";
import xAlgoLogo from "../../assets/images/xalgo.png";
import xSolLogo from "../../assets/images/xsol.png";
import solanaLogo from "../../assets/images/solana.svg";
import polygonLogo from "../../assets/images/polygon-icon.svg";
import BackButton from "../../assets/images/BackButton.svg";
import USDCLogo from "../../assets/images/usdc.png";

import { styles } from "./IndexStyles";
import { truncateStr } from "../../solanaFunctions";
import ColorButton from "../../componentsReuse/ColorButton";
import Footer from "../../componentsReuse/Footer";
import request from "../../utils/apisauce";
import { capitalize } from "../../utils/utils";
import { allChains } from "../../utils/data";
import { TRON } from "../../tronFunction/constants";
import { ArrowBack } from "@material-ui/icons";

function Review({
  classes,
  fromChain,
  toChain,
  fromToken,
  toToken,
  fromWallet,
  toWallet,
  amount,
  bridgeFee,
  handleClickProceedButton,
  errorCheck,
  proceedButtonDisabled,
  isBridgingInProgress,
  displayReviewHandler,
}) {
  const [estimatedGasFee, setEstimatedGasFee] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    handleEstimatedGasFee();
  }, []);

  const handleEstimatedGasFee = async () => {
    if ([fromChain, toChain].includes(TRON)) {
      console.log("Tron doesn't support gas fee estimation");
      setLoading(false);
      return;
    }

    try {
      if ([fromChain, toChain].includes("polygon")) {
        console.log("Gas api not working for Plyogon yet");
        setLoading(false);
        return;
      }
      const resp = await request(
        "GET",
        `/txns/estimateGas?from=${fromChain}&to=${toChain}`
      );

      setEstimatedGasFee(resp?.usd);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error in getting estimated gas fee", error);
      setLoading(false);
      setEstimatedGasFee(0);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }} className={classes.containerList}>
        <div className={classes.reviewContainer}>
          <Typography className={classes.reviewText}>REVIEW</Typography>
        </div>
        <Button onClick={displayReviewHandler} className={classes.backButton}>
          <ArrowBack
            style={{
              display: "inline",
              float: "left",
              cursor: "pointer",
              width: "24px",
              height: "24px",
              fontSize: "18px",
            }}
          />
        </Button>

        <Chain classes={classes} fromChain={fromChain} toChain={toChain} />
        <Token classes={classes} fromToken={fromToken} toToken={toToken} />
        <Wallet classes={classes} fromWallet={fromWallet} toWallet={toWallet} />
        <DepositAmount
          classes={classes}
          amount={amount}
          bridgeFee={bridgeFee}
        />
        {/* Receive amount (estimated gas fee is removed in the design) */}
        {/* <ReceiveAmount
          classes={classes}
          amount={amount}
          bridgeFee={bridgeFee}
          estimatedGasFee={estimatedGasFee}
          loading={loading}
        /> */}
        <BridgeButton
          classes={classes}
          handleClickProceedButton={handleClickProceedButton}
          errorCheck={errorCheck}
          proceedButtonDisabled={proceedButtonDisabled}
          isBridgingInProgress={isBridgingInProgress}
        />
      </div>

      <div className={classes.poweredByContainer}>
        <Footer />
      </div>
    </>
  );
}

const Chain = ({ classes, fromChain = "solana", toChain = "algorand" }) => {
  const fromChainImg = allChains.filter(
    (chain) => chain.value === fromChain
  )[0];
  const toChainImg = allChains.filter((chain) => chain.value === toChain)[0];

  return (
    <Grid container className={clsx(classes.container)}>
      <Grid item xs={6}>
        <Typography className={classes.label}>From Chain:</Typography>
        <Box display="flex" alignItems="center">
          <img
            src={fromChainImg.img}
            alt={`${fromChain} logo`}
            width="30"
            height="30"
          />
          <Typography
            style={{ paddingLeft: "10px" }}
            className={clsx(classes.boldText)}
          >
            {capitalize(fromChain)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label}>To Chain:</Typography>
        <Box display="flex">
          <img
            src={toChainImg.img}
            alt={`${fromChain} logo`}
            width="30"
            height="30"
          />
          <Typography
            style={{ paddingLeft: "10px" }}
            className={clsx(classes.boldText)}
          >
            {capitalize(toChain)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const assetLogo = (token) => {
  const logoMap = {
    ALGO: algoLogoDark,
    SOL: solanaLogo,
    xSOL: xSolLogo,
    USDC: USDCLogo,
    xALGO: xAlgoLogo,
  };
  return logoMap[token] || xAlgoLogo;
};

const Token = ({ classes, fromToken = "SOL", toToken = "xSOL" }) => {
  return (
    <Grid container className={clsx(classes.container)}>
      <Grid item xs={6}>
        <Typography className={classes.label}>From Token:</Typography>
        <Box display="flex" alignItems="center">
          <img
            src={assetLogo(fromToken)}
            alt={`${fromToken} logo`}
            className={classes.menuItemImg}
            width="30"
            height="30"
          />
          <Typography
            style={{ paddingLeft: "10px" }}
            className={clsx(classes.boldText)}
          >
            {fromToken || "ALGO"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label}>To Token:</Typography>
        <Box display="flex" alignItems="center">
          <img
            src={assetLogo(toToken)}
            alt={`${toToken} logo`}
            className={classes.menuItemImg}
            width="30"
            height="30"
          />
          <Typography
            style={{ paddingLeft: "10px" }}
            className={clsx(classes.boldText)}
          >
            {toToken || "SOL"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
const Wallet = ({ classes, fromWallet, toWallet }) => {
  return (
    <Grid container className={clsx(classes.container)}>
      <Grid item xs={6}>
        <Typography className={classes.label}>From Wallet:</Typography>
        <Tooltip title={fromWallet}>
          <Typography className={clsx(classes.boldText)}>
            {truncateStr(fromWallet || "Pera", 15)}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label}>To Wallet:</Typography>
        <Tooltip title={toWallet}>
          <Typography className={clsx(classes.boldText)}>
            {truncateStr(toWallet || "Phantom", 15)}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
const DepositAmount = ({ classes, amount, bridgeFee }) => {
  return (
    <Grid container className={clsx(classes.container)}>
      <Grid item xs={6}>
        <Typography className={classes.label}>Deposit Amount:</Typography>
        <Typography className={classes.boldText}>
          {amount?.toFixed(4)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.label}>Bridge Fee:</Typography>
        <Typography className={classes.boldText}>
          {bridgeFee?.toFixed(4) || 0}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BridgeButton = ({
  classes,
  handleClickProceedButton,
  errorCheck,
  proceedButtonDisabled,
  isBridgingInProgress,
}) => {
  return (
    <Grid container className={classes.footerContainer}>
      <ColorButton
        fullWidth
        style={{
          margin: "0",
        }}
        variant="filled"
        onClick={handleClickProceedButton}
        disabled={proceedButtonDisabled && isBridgingInProgress && errorCheck}
      >
        BRIDGE
      </ColorButton>
    </Grid>
  );
};

export default withStyles(styles)(Review);
