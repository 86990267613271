import { withStyles } from "@material-ui/core/styles";
import { Connection, PublicKey } from "@solana/web3.js";
import PropTypes from "prop-types";
import React from "react";
import HomeTabs from "./components/HomeTabs";

import { walletHandler } from "../../solanaFunctions";
import { USDC2Dialog } from "./components/BridgePanel/components/Dialogs";
import { TocNotice } from "./components/TocNotice";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialDialog: true,
      isDark: true,
      isDrawerOpen: false,
      isHomeMode: true,
      isAdvancedMode: false,
      isSolTokenOpsMode: false,
      isSolTokenAccountOpsMode: false,
      isDistributionMode: false,
      isClaimMode: false,
      network: "mainnet",
      fromChain: "algorand",
      toChain: "solana",
      fromToken: "",
      fromWallet: "",
      toToken: "",
      toWallet: "",
      amount: 0,
      fromNativeBalance: null,
      fromNativeMinBalance: null,
      toNativeBalance: null,
      fromNativeMinApiBalance: null,
      toNativeMinBalance: null,
      fromTokenBalance: null,
      fromTokenMinBalance: null,
      toTokenBalance: null,
      toTokenMinBalance: null,
      xSolOptIn: false,
      algorandWalletType: "",
      algorandWalletAddress: "",
      algorandWalletObject: null,
      solanaWalletType: "",
      solanaWalletAddress: "",
      solanaWalletObject: null,
      isAlertDialogOpen: false,
      //Ethereum
      ethereumWalletType: "",
      ethereumWalletAddress: "",
      ethereumWalletObject: null,
      //Avalanche
      avalancheWalletType: "",
      avalancheWalletAddress: "",
      avalancheWalletObject: null,
      //TRON
      tronWalletType: "",
      tronWalletAddress: "",
      tronWalletObject: null,
      isopened: false,
      activeStep: 0,
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.setSolanaWallet = this.setSolanaWallet.bind(this);
    this.exchangeChainValuesHandler =
      this.exchangeChainValuesHandler.bind(this);
  }

  handleActiveStep = (num) => {
    this.setState({ activeStep: num });
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        initialDialog: false,
      });
    }, 2000);
  }

  handleCloseDialog() {
    this.setState({
      initialDialog: false,
    });
  }

  async setSolanaWallet(props) {
    if (props === "") {
      if (this.state.solanaWalletObject) {
        this.state.solanaWalletObject.disconnect();
      }
      this.setState(() => ({
        solanaWalletObject: null,
        solanaWalletType: "",
        solanaWalletAddress: "",
      }));
      return;
    }
    const { solanaWalletObject, solanaWalletType } = await walletHandler(props);
    if (solanaWalletObject == null) {
      return;
    }
    if (!PublicKey.isOnCurve(solanaWalletObject.publicKey)) {
      this.setState(
        {
          isAlertDialogOpen: true,
          errorType: "solanaWalletValidation",
        },
        () => {
          setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
        }
      );
    }
    this.setState({
      solanaWalletObject,
      solanaWalletType,
      solanaWalletAddress: solanaWalletObject.publicKey.toBase58(),
    });
  }
  exchangeChainValuesHandler() {
    this.setState((prevState) => ({
      fromWallet: prevState.toWallet,
      toWallet: prevState.fromWallet,
      fromToken: prevState.toToken,
      toToken: prevState.fromToken,
      fromChain: prevState.toChain,
      toChain: prevState.fromChain,
      fromNativeBalance: prevState.toNativeBalance,
      toNativeBalance: prevState.fromNativeBalance,
      fromTokenBalance: prevState.toTokenBalance,
      toTokenBalance: prevState.fromTokenBalance,
      //doubt
      // fromNativeMinApiBalance: prevState.toNativeMinApiBalance,
      // toNativeMinApiBalance: prevState.fromNativeMinApiBalance,
      fromNativeMinBalance: prevState.toNativeMinBalance,
      toNativeMinBalance: prevState.fromNativeMinBalance,
    }));
  }

  render() {
    const {
      isDark,
      initialDialog,
      isDrawerOpen,
      isHomeMode,
      isAdvancedMode,
      isSolTokenOpsMode,
      isSolTokenAccountOpsMode,
      isDistributionMode,
      isClaimMode,
      network,
      fromToken,
      fromWallet,
      fromChain,
      toChain,
      toToken,
      toWallet,
      amount,
      algorandWalletAddress,
      algorandWalletType,
      algorandWalletObject,
      solanaWalletAddress,
      solanaWalletType,
      solanaWalletObject,
      //Ethereum
      ethereumWalletType,
      ethereumWalletAddress,
      ethereumWalletObject,
      //Avalanche
      avalancheWalletType,
      avalancheWalletAddress,
      avalancheWalletObject,
      //TRON
      tronWalletType,
      tronWalletAddress,
      tronWalletObject,
      fromNativeBalance,
      fromNativeMinBalance,
      toNativeBalance,
      fromNativeMinApiBalance,
      toNativeMinBalance,
      fromTokenBalance,
      fromTokenMinBalance,
      toTokenBalance,
      toTokenMinBalance,
      xSolOptIn,
    } = this.state;

    const { classes } = this.props;

    // mainnet RPC for otherthan main branch: "https://glitter-main-e9a4.mainnet.rpcpool.com/f1fd97fd-5fcc-426f-afd2-0877ab101099"
    // mainnet RPC for main branch: "https://glitter-main-e9a4.mainnet.rpcpool.com"

    const connection = new Connection(
      network === "mainnet"
        ? "https://glitter-main-e9a4.mainnet.rpcpool.com/f1fd97fd-5fcc-426f-afd2-0877ab101099"
        : "https://api.testnet.solana.com",
      "confirmed"
    );

    return (
      <>
        <TocNotice />
        {/* <WalletProvider value={walletProviders}> */}
        <div
          className={classes.root}
          style={{
            position: "relative",
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), radial-gradient(118.99% 113.4% at 112.77% 113.4%, #D688C6 0%, #CA7FC2 25.52%, #DE9CCA 47.92%, #BC75C3 76.04%, #B46EC3 93.42%, #A462C3 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */",
            backdropFilter: "blur(9px)",
          }}
        >
          <div
            className={classes.container}
            style={{
              height: "100%",
              minHeight: "100vh",
            }}
          >
            <main className={classes.main}>
              {isHomeMode && (
                <HomeTabs
                  activeStep={this.state.activeStep}
                  setActiveStep={this.handleActiveStep}
                  isDark={isDark}
                  isAdvancedMode={isAdvancedMode}
                  network={network}
                  connection={connection}
                  xSolOptIn={xSolOptIn}
                  setxSolOptIn={(optIn) => this.setState({ xSolOptIn: optIn })}
                  fromToken={fromToken}
                  fromChain={fromChain}
                  fromWallet={fromWallet}
                  toToken={toToken}
                  toChain={toChain}
                  toWallet={toWallet}
                  amount={amount}
                  fromNativeBalance={fromNativeBalance}
                  fromNativeMinBalance={fromNativeMinBalance}
                  fromNativeMinApiBalance={fromNativeMinApiBalance}
                  toNativeBalance={toNativeBalance}
                  toNativeMinBalance={toNativeMinBalance}
                  fromTokenBalance={fromTokenBalance}
                  fromTokenMinBalance={fromTokenMinBalance}
                  toTokenBalance={toTokenBalance}
                  toTokenMinBalance={toTokenMinBalance}
                  setFromNativeBalance={(balance) =>
                    this.setState({ fromNativeBalance: balance })
                  }
                  setToNativeBalance={(balance) =>
                    this.setState({ toNativeBalance: balance })
                  }
                  setFromTokenBalance={(balance) =>
                    this.setState({ fromTokenBalance: balance })
                  }
                  setToTokenBalance={(balance) =>
                    this.setState({ toTokenBalance: balance })
                  }
                  setFromNativeMinBalance={(minBalance) =>
                    this.setState({ fromNativeMinBalance: minBalance })
                  }
                  setFromNativeMinApiBalance={(minBalance) =>
                    this.setState({ fromNativeMinApiBalance: minBalance })
                  }
                  setToNativeMinBalance={(minBalance) =>
                    this.setState({ toNativeMinBalance: minBalance })
                  }
                  setFromTokenMinBalance={(minBalance) =>
                    this.setState({ fromTokenMinBalance: minBalance })
                  }
                  setToTokenMinBalance={(minBalance) =>
                    this.setState({ toTokenMinBalance: minBalance })
                  }
                  algorandWalletAddress={algorandWalletAddress}
                  algorandWalletObject={algorandWalletObject}
                  setAlgorandWalletObject={(obj) =>
                    this.setState({ algorandWalletObject: obj })
                  }
                  algorandWalletType={algorandWalletType}
                  setAlgorandWalletType={(algorandWalletType) =>
                    this.setState({
                      algorandWalletType: algorandWalletType,
                    })
                  }
                  setAlgoWallet={async (algorandWalletAddress) =>
                    this.setState({
                      algorandWalletAddress: algorandWalletAddress,
                    })
                  }
                  solanaWalletType={solanaWalletType}
                  solanaWalletAddress={solanaWalletAddress}
                  solanaWalletObject={solanaWalletObject}
                  setSolanaWallet={this.setSolanaWallet.bind(this)}
                  //Ethereum
                  ethereumWalletAddress={ethereumWalletAddress}
                  setEthereumWalletAddress={async (obj) =>
                    this.setState({
                      ethereumWalletAddress: obj,
                    })
                  }
                  ethereumWalletType={ethereumWalletType}
                  setEthereumWalletType={(obj) =>
                    this.setState({
                      ethereumWalletType: obj,
                    })
                  }
                  ethereumWalletObject={ethereumWalletObject}
                  setEthereumWalletObject={(obj) =>
                    this.setState({ ethereumWalletObject: obj })
                  }
                  //Avalanche
                  avalancheWalletAddress={avalancheWalletAddress}
                  setAvalancheWalletAddress={async (obj) =>
                    this.setState({
                      avalancheWalletAddress: obj,
                    })
                  }
                  avalancheWalletType={avalancheWalletType}
                  setAvalancheWalletType={(obj) =>
                    this.setState({
                      avalancheWalletType: obj,
                    })
                  }
                  avalancheWalletObject={avalancheWalletObject}
                  setAvalancheWalletObject={(obj) =>
                    this.setState({ avalancheWalletObject: obj })
                  }
                  //TRON
                  tronWalletAddress={tronWalletAddress}
                  setTronWalletAddress={async (obj) =>
                    this.setState({
                      tronWalletAddress: obj,
                    })
                  }
                  tronWalletType={tronWalletType}
                  setTronWalletType={(obj) =>
                    this.setState({
                      tronWalletType: obj,
                    })
                  }
                  tronWalletObject={tronWalletObject}
                  setTronWalletObject={(obj) =>
                    this.setState({ tronWalletObject: obj })
                  }
                  setNetwork={(network) =>
                    this.setState({
                      network,
                    })
                  }
                  setFromChain={(chain) =>
                    this.setState({
                      fromChain: chain,
                    })
                  }
                  setFromToken={(token) =>
                    this.setState({
                      fromToken: token,
                    })
                  }
                  setFromWallet={(wallet) =>
                    this.setState({
                      fromWallet: wallet,
                    })
                  }
                  setToToken={(token) =>
                    this.setState({
                      toToken: token,
                    })
                  }
                  setToChain={(chain) =>
                    this.setState({
                      toChain: chain,
                    })
                  }
                  setToWallet={(wallet) =>
                    this.setState({
                      toWallet: wallet,
                    })
                  }
                  setAmount={(amount) =>
                    this.setState({
                      amount: amount,
                    })
                  }
                  exchangeChainValuesHandler={this.exchangeChainValuesHandler}
                />
              )}
            </main>
          </div>
        </div>
      </>
    );
  }
}
Home.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default withStyles({})(Home);
