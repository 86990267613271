import React from "react";
import EventEmitter from "eventemitter3";
import Alert from "@material-ui/lab/Alert";
import { PublicKey } from "@solana/web3.js";

export class MathWalletAdapter extends EventEmitter {
  constructor() {
    super();
    this.state = {
      isAlertDialogOpen: false,
    };
    this.name = "mathwallet";
    this._connecting = false;
    this._publicKey = null;
    this.connect = this.connect.bind(this);
  }

  get _wallet() {
    if (!window.solana) {
      console.log("Your browser does not support solana");
      return;
    }
    if (window.solana.isMathWallet) {
      return window.solana;
    }
    window.open("https://mathwallet.org", "_blank");
    return false;
  }

  _handleConnect = (...args) => {
    this.emit("connect", ...args);
  };

  _handleDisconnect = (...args) => {
    this.emit("disconnect", ...args);
  };

  get connecting() {
    return this._connecting;
  }

  get connected() {
    return this._wallet.isConnected || false;
  }

  get autoApprove() {
    return this._wallet.autoApprove || false;
  }

  get publicKey() {
    return this._publicKey;
  }

  async connect() {
    if (this.connected || this.connecting) return;
    this._connecting = true;
    if (!window.solana.isMathWallet) {
      window.open("https://mathwallet.org", "_blank");
      return;
    }
    try {
      const account = await window.solana.getAccount();
      console.log(account);
      this._publicKey = new PublicKey(account);
      this._connected = true;
      this.emit("connect", this.publicKey);
    } catch {
      this.disconnect();
    }
  }

  disconnect() {
    if (this._wallet) {
      this._wallet.disconnect();
    }
  }

  render() {
    return (
      this.state.isAlertDialogOpen && (
        <Alert
          severity="error"
          onClose={() => this.setState({ isAlertDialogOpen: false })}
        >
          Please install Math wallet from Chrome
        </Alert>
      )
    );
  }
}
