import React from "react";
import { Grid, Divider as MuiDivider } from "@material-ui/core";

const Divider = ({ children, ...props }) => (
  <Grid
    container
    alignItems="center"
    spacing={3}
    // {...props}
    sx={(theme) => ({
      [theme.breakpoints.down("xs")]: {
        padding: "0px 10px !important",
      },
    })}
  >
    <Grid item xs>
      <MuiDivider style={{ backgroundColor: "#FFFFFF" }} />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <MuiDivider style={{ backgroundColor: "#FFFFFF" }} />
    </Grid>
  </Grid>
);

export default Divider;
