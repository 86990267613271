import algorandDropdown from "../assets/images/algorandDropdown.svg";
import solanaDropdown from "../assets/images/solanaDropdown.svg";
import solDropdown from "../assets/images/solDropdown.svg";
import xalgoDropdown from "../assets/images/xalgoDropdown.svg";
import xsolDropdown from "../assets/images/xsolDropdown.svg";
import usdcDropdown from "../assets/images/usdc.svg";
import etherDropdown from "../assets/images/Ether.svg";
import avaxDropdown from "../assets/images/Avala.svg";
import tronDropDown from "../assets/images/Tron-icon.svg";
import polygonDropdown from "../assets/images/Polygon.svg";
import eurocDropdown from "../assets/images/euroc.svg";

export const TX_STATUS = ["Success", "Failed", "Pending", "Refund", "all"];

export const EXPLORER_STATUS = [
  "operational",
  "degraded",
  "downtime",
  "maintenance",
];

const allChains = [
  {
    value: "algorand",
    label: "Algorand",
    img: algorandDropdown,
  },
  {
    value: "solana",
    label: "Solana",
    img: solanaDropdown,
  },
  {
    value: "ethereum",
    label: "Ethereum",
    img: etherDropdown,
  },
  {
    value: "avalanche",
    label: "Avalanche",
    img: avaxDropdown,
  },
  {
    value: "polygon",
    label: "Polygon",
    img: polygonDropdown,
  },
  {
    value: "tron",
    label: "Tron",
    img: tronDropDown,
  },
];

const usdcAsset = {
  value: "USDC",
  label: "USDC",
  img: usdcDropdown,
};

const eurocAsset = {
  label: "EUROC",
  value: "EUROC",
  img: eurocDropdown,
};

const toAssetAlgo = [
  {
    value: "ALGO",
    label: "ALGO",
    img: algorandDropdown,
  },
  {
    value: "xSOL",
    label: "xSOL",
    img: xsolDropdown,
  },
  {
    ...usdcAsset,
  },
];

const toAssetSol = [
  {
    value: "SOL",
    label: "SOL",
    img: solDropdown,
  },
  {
    value: "xALGO",
    label: "xALGO",
    img: xalgoDropdown,
  },
  {
    ...usdcAsset,
  },
];

const toAssetEth = [
  {
    ...usdcAsset,
  },
  {
    ...eurocAsset,
  },
];
const toAssetAvax = [
  {
    ...usdcAsset,
  },
  {
    ...eurocAsset,
  },
];

const toAssetTron = [
  {
    ...usdcAsset,
  },
];
const toAssetPolygon = [
  {
    ...usdcAsset,
  },
];

export {
  allChains,
  toAssetAlgo,
  toAssetSol,
  toAssetEth,
  toAssetAvax,
  toAssetTron,
  toAssetPolygon,
};
