import { Link, Modal, Typography, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const consentFormStyles = (theme) => {
  return {
    text: {
      color: theme.palette.common.white,
    },
    link: {
      color: theme.palette.update3Palette.text1,
    },
  };
};
function ConsentForm({ classes }) {
  const [isConsented, setIsConsented] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const handleConsent = () => {
    setIsConsented(true);
    setIsOpen(false);
  };

  return !isConsented ? (
    <Dialog open={isOpen}>
      <DialogContent>
        <p>
          By using this interface, you agree to the{" "}
          <Link
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.glitterfinance.org/terms-and-conditions"
          >
            Terms and Conditions
          </Link>
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConsent}>OK</Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

const StyledConsentForm = withStyles(consentFormStyles)(ConsentForm);

export default ConsentForm;

export function TocNotice() {
  return <StyledConsentForm />;
}
