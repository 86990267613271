import { Box, withStyles } from "@material-ui/core";
import React from "react";

const styles = (theme) => {
  return {
    gradientBox: {
      minHeight: "41px",
      background:
        "linear-gradient(170.98deg, rgba(50, 112, 205, 0.45) -12.36%, rgba(168, 66, 249, 0.5) 100.49%), #FFFFFF",
      filter: "drop-shadow(0px 0px 10.1162px rgba(219, 68, 243, 0.8))",
      border: "2px solid rgba(255, 255, 255, 0.9)",
      backdropFilter: "blur(9px)",
      borderRadius: "13px",
      width: "100%",
      padding: "5px 12px 6px 10px",
      marginTop: "22px",
      display: "flex",
      justifyContent: "space-between",
      gap: "8px",
      [theme.breakpoints.down("xs")]: {
        gap: "4px",
        padding: "5px 12px 6px 10px",
        alignItems: "center",
      },
    },
  };
};

function GradientBox({ children, classes }) {
  return <Box className={classes.gradientBox}>{children}</Box>;
}

export default withStyles(styles)(GradientBox);
