import {
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import React, { memo, useRef, useState } from "react";

import configData from "../../../config.json";
import {
  ALL_CHAINS,
  EVM_SUPPORTED_CHAINS,
  EVM_SUPPORTED_WALLETS,
} from "../../../ethereumFunctions/constants";
import { TRON } from "../../../tronFunction/constants";
import {
  allChains,
  toAssetAlgo,
  toAssetAvax,
  toAssetEth,
  toAssetPolygon,
  toAssetSol,
  toAssetTron,
} from "../../../utils/data";
import styles from "./FromChainStepStyles";

const FromChainStep = memo((props) => {
  const ref = useRef(null);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const handleChangeFromChain = (event) => {
    const chainName = event.target.value?.toLowerCase();
    props.setFromChain(event.target.value);

    if (chainName === "ethereum") {
      props.setToChain("avalanche");
    } else if (chainName === "avalanche") {
      props.setToChain("ethereum");
    } else if (chainName === "algorand") {
      props.setToChain("solana");
    } else if (chainName === "solana") {
      props.setToChain("algorand");
    }

    if (EVM_SUPPORTED_CHAINS.includes(chainName) || chainName === TRON) {
      props.setFromToken("USDC");
      props.setToToken("USDC");
    } else {
      props.setFromToken("");
      props.setToToken("");
      props.setEthereumWalletAddress("");
      props.setAmount(0);
    }
    props.setAlgorandWalletType("");
    props.setAlgoWallet("");
    props.setSolanaWallet("");

    props.setIsXsolOptInAlertOpen(false);
  };

  //fromToken
  const handleChange = (event) => {
    const {
      fromChain,
      network,
      setFromToken,
      setToToken,
      setFromNativeMinBalance,
      setFromTokenMinBalance,
    } = props;

    setFromToken(event.target.value);

    if (fromChain === "ethereum") {
      props.setToChain("avalanche");
      props.setToToken(event.target.value);
    } else if (fromChain === "avalanche") {
      props.setToChain("ethereum");
      props.setToToken(event.target.value);
    } else if (fromChain === "algorand" && event.target.value !== "USDC") {
      props.setToToken("");
      props.setToChain("solana");
      props.setSolanaWallet("");
    } else if (fromChain === "solana" && event.target.value !== "USDC") {
      props.setToToken("");
      props.setToChain("algorand");
      props.setAlgorandWalletType("");
      props.setAlgoWallet("");
    } else {
      props.setToToken("");
      props.setToChain("ethereum");
      props.setAlgorandWalletType("");
      props.setAlgoWallet("");
      props.setSolanaWallet("");
    }

    const renderTokenResult =
      fromChain === "algorand" && event.target.value === "ALGO"
        ? "xSOL"
        : fromChain === "solana" && event.target.value === "SOL"
        ? "xALGO"
        : event.target.value;

    const fromTokenAsset = configData[fromChain][network].assets_info.find(
      (a) => a.symbol.toLowerCase() === renderTokenResult.toLowerCase()
    );

    let fromNativeAsset;
    if (fromChain === "algorand" || fromChain === "solana") {
      fromNativeAsset = configData[fromChain][network].assets_info.find(
        (a) => a.type === "native"
      );
      setFromNativeMinBalance(
        parseFloat(
          fromNativeAsset.min_balance * (1 + fromNativeAsset.fee_rate) + 0.1
        ).toFixed(4)
      );
    }

    if (["USDC", "EUROC"].includes(event.target.value)) {
      setFromTokenMinBalance(parseFloat(fromTokenAsset.min_balance).toFixed(4));
    } else {
      setFromTokenMinBalance(
        parseFloat(
          fromTokenAsset.min_balance * (1 + fromTokenAsset.fee_rate)
        ).toFixed(4)
      );
    }

    if (fromChain === "solana") {
      if (event.target.value === "SOL") {
        setToToken("xSOL");
      } else if (event.target.value === "xALGO") {
        setToToken("ALGO");
      } else if (event.target.value === "USDC") {
        setToToken("USDC");
      }
    } else if (fromChain === "algorand") {
      if (event.target.value === "ALGO") {
        setToToken("xALGO");
      } else if (event.target.value === "xSOL") {
        setToToken("SOL");
      } else if (event.target.value === "USDC") {
        setToToken("USDC");
      }
    }

    if (
      !EVM_SUPPORTED_WALLETS.includes(props.fromWallet) &&
      !EVM_SUPPORTED_CHAINS.includes(fromChain)
    ) {
      props.setFromWallet("");
      props.setAlgoWallet("");
      props.setAlgorandWalletType("");
      props.setAlgorandWalletObject("");
      props.setSolanaWallet("");
      props.setEthereumWalletAddress("");
      props.setEthereumWalletObject({});
      props.setIsXsolOptInAlertOpen(false);
    }
  };

  const { classes, fromChain, fromToken } = props;

  let assetsToRender =
    fromChain === ALL_CHAINS[0]
      ? toAssetAlgo
      : fromChain === ALL_CHAINS[1]
      ? toAssetSol
      : fromChain === ALL_CHAINS[2]
      ? toAssetEth
      : fromChain === ALL_CHAINS[3]
      ? toAssetAvax
      : fromChain === ALL_CHAINS[4]
      ? toAssetPolygon
      : toAssetTron;

  return (
    <Grid
      container
      component="div"
      direction="row"
      spacing={3}
      className={classes.grid}
      justifyContent="center"
    >
      <Grid
        component="div"
        item
        xs={7}
        sm={7}
        md={7}
        className={classes.gridItem}
      >
        <FormControl className={classes.formControlRoot}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onOpen={() => setDropDownOpen(true)}
            onClose={() => setDropDownOpen(false)}
            open={dropDownOpen}
            native={false}
            ref={ref}
            style={{ color: "white", width: "100%" }}
            className={classes.select}
            value={fromChain}
            onChange={(e) => handleChangeFromChain(e)}
            variant="outlined"
            defaultValue={props.fromChain}
            IconComponent={FromChainIconWithStyles}
            MenuProps={{
              elevation: 0,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            }}
          >
            {allChains.map((chain) => {
              return (
                <MenuItem
                  key={chain.value}
                  value={chain.value}
                  className={classes.menuItemRoot}
                  selected={false}
                  name={chain.label}
                >
                  <Typography style={{ display: "flex" }}>
                    <img
                      src={chain.img}
                      alt={chain.label}
                      className={classes.menuItemImgChain}
                    />
                    <span className={classes.inputlable}>{chain.label}</span>
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5} sm={5} md={5} className={classes.gridItem}>
        <FormControl className={classes.formControlRoot}>
          <TextField
            variant="outlined"
            select
            label="Token"
            value={fromToken}
            onChange={handleChange}
            defaultValue={props.fromToken}
            InputLabelProps={{
              shrink: false,
            }}
            SelectProps={{
              MenuProps: {
                elevation: 0,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
              IconComponent: () => (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <ExpandMoreIcon />
                </InputAdornment>
              ),
            }}
          >
            {assetsToRender.map((asset) => {
              return (
                <MenuItem
                  key={asset.value}
                  value={asset.value}
                  className={classes.tokenMenuItemRoot}
                  name={asset.label}
                >
                  <img
                    src={asset.img}
                    alt={asset.label}
                    className={classes.menuItemImg}
                  />
                  <Typography style={{ display: "flex" }}>
                    <span className={classes.inputlable}>{asset.label}</span>
                  </Typography>
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
});

FromChainStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  fromChain: PropTypes.string.isRequired,
  setFromChain: PropTypes.func.isRequired,
  fromToken: PropTypes.string.isRequired,
  setFromToken: PropTypes.func.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToChain: PropTypes.func.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setSolanaWallet: PropTypes.func.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setIsXsolOptInAlertOpen: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  network: PropTypes.string.isRequired,
  fromWallet: PropTypes.string.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  setFromNativeMinBalance: PropTypes.func.isRequired,
  setFromTokenMinBalance: PropTypes.func.isRequired,
};

const FromChainIcon = ({ classes }) => {
  return (
    <InputAdornment position="end" className={classes.inputAdornment}>
      <ExpandMoreIcon />
    </InputAdornment>
  );
};
const FromChainIconWithStyles = withStyles(styles)(FromChainIcon);
export default withStyles(styles)(FromChainStep);
