export const styles = (theme) => {
  const {
    subtitle1: { fontSize },
  } = theme.typography;

  return {
    rootBgGradiant: {
      ...theme.palette.update2Palette.bgGradient2BcDrop,
    },
    root: {
      padding: theme.spacing(1),
      paddingTop: 0,
      [theme.breakpoints.down("xs")]: {
        padding: 5,
      },
    },
    xsRoot: {
      padding: theme.spacing(1),
      paddingBottom: 20,
      [theme.breakpoints.down("xs")]: {
        padding: 5,
      },
    },
    grid: {
      margin: "0px auto",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    appContainer: {
      padding: "20px",
      borderRadius: "20px",
      position: "relative",
      boxShadow: "0px 0px 10px rgba(94, 20, 168, 0.5)",
      background: theme.palette.update1Palette.appContainerGradient,
      [theme.breakpoints.down("xs")]: {
        padding: "22px 4px",
      },
    },
    appTitle: {
      color: "#F66FC0",
      fontFamily: "ProximaNova",
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "25px",
      textAlign: "center",
      letterSpacing: "0.1em",
      lineHeight: "51px",
    },
    explorerButton: {
      width: "278px",
      color: "#5E14A8",
      borderRadius: "10px",
      position: "relative",
      border: "2px solid #5E14A8",
      padding: "11px 0",
      background: ({ isDark }) => (isDark ? "white" : "black"),
      marginTop: "12px",
      [theme.breakpoints.down("md")]: {
        marginTop: "2%",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "8%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "165px",
        fontSize: "8px",
        marginTop: "0px",
      },
      "& .MuiButton-label": {
        fontSize: "1rem",
      },
    },
    testTitle: {
      color: theme.palette.purple,
      fontSize: fontSize,
      padding: theme.spacing(0, 20),
      [theme.breakpoints.between("xs", "sm")]: {
        padding: theme.spacing(0, 5),
      },
    },
    fromChainContainer: {
      borderRadius: "10px",
      margin: "10px",
      padding: "16px 16px 24px 16px",
      justifyContent: "center",
      alignItems: "center",
      background:
        "linear-gradient(81.8deg, rgba(251, 60, 255, 0.5) -9.97%, rgba(50, 112, 205, 0.45) 45.69%, rgba(168, 66, 249, 0.5) 98.31%), #FFFFFF;",
      [theme.breakpoints.down("xs")]: {
        padding: "22px 13px",
      },
    },
    dividerContainer: {
      background: "transparent",
      borderRadius: "10px",
      margin: "10px",
      padding: "15px",
      justifyContent: "center",
      alignItems: "center",
    },
    alertMessage: {
      width: "100%",
      "&.MuiAlert-message": {
        wordBreak: "break-word",
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "justify",
      },
    },
    alertAction: {
      "&.MuiAlert-action": {
        [theme.breakpoints.down("xs")]: {
          alignItems: "flex-start",
        },
      },
    },
    alertRoot: {
      display: "flex",
      alignItems: "center",
      "&.MuiAlert-root": {
        [theme.breakpoints.down("xs")]: {
          paddingRight: 4,
          paddingLeft: 4,
        },
      },
    },
    item2: {
      order: 3,
      [theme.breakpoints.up("sm")]: {
        order: 2,
      },
    },
    item3: {
      order: 2,
      [theme.breakpoints.up("sm")]: {
        order: 3,
      },
    },
    linkTeam: {
      textDecoration: "none",
      color: "#fb69c8",
      "&:hover": {
        textDecoration: "none",
        color: "hotPink",
      },
      "&:visited": {
        textDecoration: "none",
        color: "green",
      },
    },
    btn: {
      background: "#28282B",
      color: "#ffffff",
      width: "40%",
      border: "3px solid transparent",
      padding: "7px",
      borderRadius: 50,
      height: "30px",
      fontSize: "15px",
      textDecoration: "none",
    },
    buttonsDiv: {
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "inline-block",
        textAlign: "center",
      },
    },
    activeBtn: {
      color: "#313149",
      padding: "7px",
      paddingRight: "20px",
      paddingLeft: "20px",
      border: "3px solid transparent",
      borderRadius: 50,
      marginLeft: 10,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
        padding: "6px",
        paddingRight: "14px",
        paddingLeft: "14px",
      },
    },
    disconectBtn: {
      fontSize: 11,
      display: "inline",
      color: "#313149",
      padding: "11px",
      fontWeight: "bold",
      border: "3px solid transparent",
      borderRadius: 50,
      marginRight: 5,
      marginLeft: 10,
      marginBottom: 20,
      "&:hover": {
        backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
        color: "#000000",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 11,
      },
    },
    underlineDark: {
      borderBottom: "1px solid #ffffff",
      color: "#ffffff",
      "&:before": {
        borderBottom: "1px solid #fff",
      },
      "&::after": {
        borderBottom: "2px solid #fff",
      },
    },
    underline: {
      color: "#000",
      borderBottom: "1px solid #000000",
      "&:before": {
        borderBottom: "1px solid #000",
      },
      "&::after": {
        borderBottom: "2px solid #000",
      },
    },
    image: {
      width: 20,
      zIndex: 2,
    },
    grid: {
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    addressInput: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.6em",
      },
    },
    addressInputDark: {
      "&::after": {
        borderColor: "#fff",
      },
      color: "#fff",
      borderColor: "#ffff",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.6em",
      },
    },
    inputLabel: {
      color: "#ffffff",
    },
    text: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    input: {
      fontSize: 20,
      fontWeight: "bold",
    },
    textFieldMax: {
      width: "67%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    amountBridge: {
      fontSize: "14px",
      color: "var(--clr-l)",
      textAlign: "justify",
      marginLeft: "50px",
      marginTop: "20px",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        textAlign: "justify",
        marginLeft: "0px",
      },
    },
    maxButton: {
      color: "white",
      border: "1px solid var(--clr-purple)",
      marginBottom: "5px",
      height: "24px",
    },
    alertMessage: {
      "&.MuiAlert-message": {
        wordBreak: "break-word",
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "justify",
      },
    },
    alertRoot: {
      "&.MuiAlert-root": {
        [theme.breakpoints.down("xs")]: {
          paddingRight: 4,
          paddingLeft: 4,
        },
      },
    },
    qrImage: {
      width: "256px",
      height: "256px",
      margin: "auto",
      display: "block",
      [theme.breakpoints.down("xs")]: {
        width: "140px",
        height: "140px",
      },
    },
    title: {
      marginTop: "1%",
      color: "#FF99D6",
      fontWeight: "800",
      fontSize: "25px",
      lineHeight: "29.35px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "2%",
        textAlign: "left",
        fontSize: 16,
      },
    },
    titleXs: {
      float: "left",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    btn: {
      fontSize: 11,
      display: "inline",
      color: "#313149",
      padding: "11px",
      fontWeight: "bold",
      border: "3px solid transparent",
      borderRadius: 50,
      marginRight: 5,
      marginLeft: 10,
      marginBottom: 20,
      "&:hover": {
        backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
        color: "#000000",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 11,
      },
    },
    walletImage: {
      verticalAlign: "bottom",
      marginRight: 5,
      marginLeft: 5,
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        marginRight: 2,
        marginLeft: 2,
        width: 20,
      },
    },
    walletHidden: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    dialogPaper: {
      background: theme.palette.update3Palette.modalGradient,
      color: "#313030",
      backdropFilter: "blur(21px)",
      padding: "15px",
    },
    dialogPaperDarkUSDC: {
      background:
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      border: "1px solid #91847F",
      borderRadius: "23px",
      backdropFilter: "blur(21px)",
      color: "#ffffff",
    },

    dialogPaperDarkUSDCLoadin: {
      background:
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      border: "1px solid #91847F",
      borderRadius: "23px",
      backdropFilter: "blur(21px)",
      color: "#ffffff",
      width: "100%",
    },
    customBadge: {
      color: "#000000",
      backgroundColor: "#62f53d",
    },
    gCustomBadge: {
      color: "#ffffff",
      backgroundColor: "#f76fc1",
    },
    myAlert: {
      display: "flex",
      alignItems: "center",
      height: "40px",
      borderColor: "var(--clr-purple)",
      "& .MuiAlert-icon": {
        color: "var(--clr-purple)",
      },
    },
    balance: {
      display: "flex",
      alignItems: "center",
      fontSize: 16,
      fontWeight: "bold",
      textAlign: "left",
    },
    textFieldBadgeAnchor2: {
      top: "-2px",
      right: 0,
      padding: 0,
    },
    textFieldBadgeAnchor1: {
      top: "-2px",
      right: 8,
      padding: 0,
    },
    iconRoot: {
      fontSize: 12,
    },
    maxField: {
      [theme.breakpoints.down("xs")]: {
        margin: "0px 10px !important",
        padding: "14px 20px !important",
      },
    },
    buttonsDiv: {
      display: "inline",
      [theme.breakpoints.down("xs")]: {
        display: "inline-block",
        textAlign: "center",
      },
    },
    alertMessage: {
      "&.MuiAlert-message": {
        wordBreak: "break-word",
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "justify",
      },
    },
    alertAction: {
      "&.MuiAlert-action": {
        [theme.breakpoints.down("xs")]: {
          alignItems: "flex-start",
        },
      },
    },
    alertRoot: {
      "&.MuiAlert-root": {
        [theme.breakpoints.down("xs")]: {
          paddingRight: 4,
          paddingLeft: 4,
        },
      },
    },
    alertButton: {
      display: "none",
      color: "#313149",
      padding: "7px",
      border: "1px solid #f76fc1",
      borderRadius: 50,
      "&:hover": {
        backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
        color: "#000000",
      },
      [theme.breakpoints.down("xs")]: {
        float: "right",
        display: "block",
      },
    },
    alertBtn: {
      color: "#313149",
      padding: "7px",
      border: "1px solid #f76fc1",
      borderRadius: 50,
      "&:hover": {
        backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
        color: "#000000",
      },
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    circle: {
      stroke: "url(#linearColors)",
    },
    selectDark: {
      "& .MuiOutlinedInput-input": {
        color: "rgba(137, 137, 137, 1)",
      },
      "& fieldset": {
        borderRadius: "13px",
      },
      "& .MuiInputLabel-root": {
        color: "#fff",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(137, 137, 137, 1)",
      },

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "rgba(137, 137, 137, 1)",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#fff",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff",
      },
    },
    select: {
      "& .MuiOutlinedInput-input": {
        color: "rgba(137, 137, 137, 1)",
      },
      "& fieldset": {
        borderRadius: "10px",
      },
      "& .MuiInputLabel-root": {
        color: "#000",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#000",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#000",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
    selectIconDark: {
      color: "#fff",
      border: "1px solid transparent",
      padding: "5px",
      top: "10px",
      background: "linear-gradient(272.86deg, #850277 1.66%, #630A92 51.94%);",
    },
    selectIcon: {
      color: "#000",
    },
    footerContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      margin: "0 10px",
      flexDirection: "column",
      padding: "0",
    },
    proceedButton: {
      margin: "12px 25px",
      lineHeight: "14.09px",
      height: "40px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        margin: "0px 25px",
      },
    },
    disabledBtn: {
      color: "#cfcfcf",
      padding: "7px",
      paddingRight: "20px",
      paddingLeft: "20px",
      border: "1px solid #484747",
      borderRadius: 50,
      marginLeft: 10,
      backgroundColor: "#484747",
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
        padding: "6px",
        paddingRight: "14px",
        paddingLeft: "14px",
      },
    },
    box: {
      padding: 20,
      paddingTop: 5,
      height: "0vh",
      overflowY: "auto",
      overflowX: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: 20,
        height: "40vh",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 20,
        height: "100%",
      },
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 2px #b161ce",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#b161ce",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#b161ce",
      },
      scrollbarColor: "#b161ce",
      scrollbarWidth: "thin",
    },
    xsBox: {
      overflowY: "auto",
      overflowX: "hidden",
      [theme.breakpoints.down("xs")]: {
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 5px #b161ce",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#b161ce",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#b161ce",
        },
        scrollbarColor: "#b161ce",
        scrollbarWidth: "thin",
      },
    },
    submiBtn: {
      backgroundColor: "#404040",
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
    },
    dialogTitleRoot: {
      color: "#62f53d",
      textAlign: "center",
    },
    progress: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    circle: {
      stroke: "url(#linearColors)",
    },
    iconBtnRoot: {
      padding: 0,
      margin: 0,
    },
    svgIcon: {
      height: "150px",
      width: "300px",
      [theme.breakpoints.down("xs")]: {
        height: "80px",
        width: "1500px",
      },
    },
    logo_status: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
    },
    imgLogo: {
      position: "absolute",
      top: "0",
      left: "0",
      display: "block",
      height: "50px",
      width: "50px",
      [theme.breakpoints.down("xs")]: {
        height: "30px",
        width: "30px",
      },
    },
    moreBtn: {
      color: "#cfcfcf",
      padding: "7px",
      paddingRight: "20px",
      paddingLeft: "20px",
      backgroundColor: "#484747",
      border: "3px solid transparent",
      borderRadius: 50,
      border: "1px solid gray",
    },
    btnDiv: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    txnReport: {},

    disabledBtnProceed: {
      color: "#cfcfcf",
      padding: "7px",
      paddingRight: "20px",
      paddingLeft: "20px",
      backgroundColor: "#484747",
      border: "3px solid transparent",
      borderRadius: 50,
    },
    status: {
      display: "flex",
      alignItems: "center",
      fontSize: 12,
      textAlign: "left",
      fontFamily: "Barlow",
      fontWeight: "600",
      fontStyle: "normal",
      lineHeight: "23px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "10px",
        fontSize: "13px",
        lineHeight: "10px",
        position: "relative",
      },
    },
    statusIcon: {
      height: "20px",
      width: "20px",
      marginLeft: "6px",
      borderRadius: 10,
      transition: "all .5s ease",
      WebkitTransition: "all .5s ease",
      MozTransition: "all .5s ease",
      [theme.breakpoints.down("xs")]: {
        marginTop: "2px",
        height: "12px",
        width: "12px",
      },
    },
    bridgingTxSuccessNote: {
      fontFamily: "ProximaNova",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: 8,
      },
    },
    swapIcon: {
      fontSize: "125px",
      [theme.breakpoints.down("xs")]: {
        height: "100px",
      },
    },
    integrationContainer: {
      margin: "0px",
      padding: "15px",
      background:
        "linear-gradient(81.8deg, rgba(251, 60, 255, 0.5) -9.97%, rgba(50, 112, 205, 0.45) 45.69%, rgba(168, 66, 249, 0.5) 98.31%), #FFFFFF",
      borderRadius: "10.0518px",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 13px 25px 15px",
      },
    },
    tileTitle: {
      color: theme.palette.update3Palette.primary,
      fontSize: "14px",
      fontStyle: "normal",
      fontFamily: "ProximaNova",
      fontWeight: "700",
      lineHeight: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
        lineHeight: "15px",
      },
    },
    integrationParent: {
      marginTop: "10%",
    },
    firstRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "15px",
    },
    copiedText: {
      color: "#fff",
      height: "46px",
      width: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    integrationTitle: {
      color: theme.palette.common.white,
      fontSize: "16px ",
      fontWeight: "700",
      textAlign: "center",
      paddingBottom: "10px",
    },
    thinLine: {
      height: "1px",
      width: "22%",
      color: "rgb(255, 255, 255)",
      background: "#fff",
      margin: "20px 0px",
    },
    innerBox: {
      background:
        "radial-gradient(306.75% 102.31% at -35.18% 94.28%, rgba(240, 182, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */",
      padding: "15px 10px",
      border: "1.67529px solid #FFFFFF",
      borderRadius: "10.0518px",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 10px",
      },
    },
    boxText: {
      color: theme.palette.update3Palette.primary,
      padding: "0px 10px",
      [theme.breakpoints.down("xs")]: {
        padding: "0px",
      },
    },
  };
};
