import {
  Button,
  InputAdornment,
  TextField,
  withStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";

const styles = (theme) => ({
  root: {
    borderRadius: "10px",
    margin: "10px",
    padding: "15px 25px 25px",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.update3Palette.bg,
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        "&.Mui-focused": {
          color: theme.palette.update1Palette.text2,
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "25px 12px",
    },
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    fontSize: "12px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255,255,255, 1)",
    },
    "& fieldset": {
      borderRadius: "13px",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255,255,255, 1)",
    },
    "& fieldset": {
      borderRadius: "13px",
    },
  },
  tokenLabel: {
    paddingLeft: "20px",
    top: "-5px",
    position: "absolute",
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: "99",
    right: "0",
  },
}))(Button);

function MaxInput({
  classes,
  fromToken,
  fromChain,
  toChain,
  isDark,
  setAmount,
  maxBalance,
  amount,
}) {
  const theme = useTheme();
  const getMinAmount = () => {
    if (fromToken === "SOL" || fromToken === "xSOL") {
      return "0.0500";
    } else if (
      ["USDC", "EUROC"].includes(fromToken) &&
      fromChain === "ethereum"
    ) {
      return "10";
    } else if (
      ["USDC", "EUROC"].includes(fromToken) &&
      toChain === "ethereum"
    ) {
      return "20";
    } else if (fromToken === "USDC") {
      return "1.0000";
    } else {
      return "5.0000";
    }
  };
  return (
    <div className={classes.root}>
      <Typography
        variant="body1"
        style={{
          textAlign: "center",
          color: theme.palette.update3Palette.primary,
          fontWeight: "700",
          fontFamily: "ProximaNova",
          marginBottom: "10px",
        }}
      >
        {`Min Amount:  ${getMinAmount()} ${fromToken ? fromToken : "ALGO"} `}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        className={isDark ? classes.addressFieldDark : classes.addressField}
        style={{ padding: "0px !important" }}
        value={amount}
        onChange={(e) => {
          setAmount(e.target.value ? Number(e.target.value) : null);
          console.log("here ", Number(e.target.value));
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: 5,
        }}
        type="number"
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <ColorButton
                  variant="filled"
                  color="primary"
                  style={{
                    width: "54px",
                    height: "39px",
                    margin: "0",
                  }}
                  onClick={(e) => {
                    setAmount(Math.floor(maxBalance(amount) * 10000) / 10000);
                  }}
                >
                  Max
                </ColorButton>
              </InputAdornment>
            </>
          ),
          inputProps: {
            min: 0,
            max: Math.floor(maxBalance(amount) * 10000) / 10000,
          },
          classes: {
            input: isDark ? classes.addressInputDark : classes.addressInput,
          },
        }}
      />
    </div>
  );
}

export default withStyles(styles)(MaxInput);
