const styles = (theme) => ({
  qrImage: {
    width: "256px",
    height: "256px",
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: "140px",
      height: "140px",
    },
  },
  addressField: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    fontSize: "12px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
      borderRadius: "10px",
    },
  },
  addressFieldDark: {
    minWidth: "90%",
    width: "100%",
    verticalAlign: "middle",
    margin: "2px",
    marginBottom: "0px",
    fontSize: "12px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
      borderRadius: "10px",
    },
  },
  addressInput: {
    height: "22px",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "500",
    marginRight: "75px",
    textOverflow: "ellipsis",
  },
  myAlert: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    textAlign: "left",
    minHeight: "20px",
    marginTop: "5px",
    marginBottom: "5px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderColor: "transparent",
    backgroundColor: "var(--clr-dkr)",
    "& .MuiAlert-icon": {
      color: "var(--clr-purple)",
      fontSize: "40px",
    },
  },
  alertMessage: {
    "&.MuiAlert-message": {
      wordBreak: "break-word",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
  },
  alertButton: {
    padding: "0 25px",
    marginLeft: "20px",
    border: "1px solid var(--clr-purple)",
    borderRadius: 40,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f60fc1, #5c2b48) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      float: "right",
      display: "block",
      fontSize: "10px",
      padding: "5px",
    },
  },
  myAlertAction: {
    paddingLeft: "0px",
  },
  createXAlgoButton: {
    width: "100%",
    height: " 56px",
    padding: "11px",
    fontWeight: "bold",
    margin: "4px auto",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  alertBgTransparent: {
    ...theme.palette.update2Palette.bgGradient2BcDrop,
  },
  balance: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "500",
    textAlign: "left",
    color: theme.palette.update3Palette.primary,
  },
  inputLabel: {
    color: "#ffffff",
  },
  title: {
    float: "left",
    marginTop: "1%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
      textAlign: "center",
    },
  },
  titleXs: {
    float: "left",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  gCustomBadge: {
    color: "#ffffff",
    backgroundColor: "#f76fc1",
  },
  xAlgoBadgeAnchor: {
    left: "-1px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  xAlgoBalanceBadgeAnchor: {
    left: "18px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  xSolBalanceBadgeAnchor: {
    left: "15px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  xSolBadgeAnchor: {
    left: "1px",
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
  },
  customBadge: {
    color: "#000000",
    backgroundColor: "#62f53d",
  },

  badgeAnchor: {
    bottom: "15px",
    zIndex: 1,
    opacity: "0.5",
    left: 1,
  },
  textFieldBadgeAnchor2: {
    top: "-2px",
    right: 0,
    padding: 0,
  },
  textFieldBadgeAnchor1: {
    top: "-2px",
    right: 8,
    padding: 0,
  },
  textFieldBadgeAnchor: {
    top: "15px",
    right: 1,
  },
  dialogBadgeAnchor: {
    top: "-11px",
    right: "-3px",
  },
  iconRoot: {
    fontSize: 12,
  },
  btn: {
    fontSize: 11,
    display: "inline",
    color: "#313149",
    padding: "11px",
    fontWeight: "bold",
    border: "3px solid transparent",
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 10,
    marginBottom: 20,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  walletImage: {
    verticalAlign: "bottom",
    padding: "0px !important",
    marginRight: "5px !important",
    marginLeft: "5px !important",
    cursor: "pointer",
    width: "23px !important",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      marginRight: "2px !important",
      marginLeft: "2px !important",
      width: "20px !important",
    },
  },
  dialogPaper: {
    background: theme.palette.update3Palette.modalGradient,
    color: "#313030",
    backdropFilter: "blur(21px)",
  },
  iconBtnRoot: {
    padding: 0,
  },
  buttonsDiv: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
      textAlign: "center",
    },
  },
  alertAction: {
    "&.MuiAlert-action": {
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
  },
  alertBtn: {
    color: "#313149",
    padding: "7px",
    border: "1px solid #f76fc1",
    borderRadius: 50,
    "&:hover": {
      backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
      color: "#000000",
    },
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  circle: {
    stroke: "url(#linearColors)",
  },
  tokenLogo: {
    width: 32,
    height: 24,
    zIndex: 2,
    paddingLeft: 10,
    marginTop: "-7px",
  },
});

export default styles;
