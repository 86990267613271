import { Box } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import React from "react";
import tronLogo from "../../../../../assets/images/Tron-icon.svg";
import algorandLogoNew from "../../../../../assets/images/algorand_new.png";
import avalancheLogo from "../../../../../assets/images/avalanche Logo.svg";
import { ReactComponent as BridgeCompleted } from "../../../../../assets/images/bridge-complete.svg";
import { ReactComponent as BridgeNotConfirmed } from "../../../../../assets/images/bridge-not-confirmed.svg";
import ethereumLogo from "../../../../../assets/images/ethereum Logo.svg";
import polygonLogo from "../../../../../assets/images/Polygon.svg";
import solanaLogoNew from "../../../../../assets/images/solana_new.png";

export function ChainLogo({ chain, position }) {
  const logos = {
    algorand: algorandLogoNew,
    solana: solanaLogoNew,
    avalanche: avalancheLogo,
    ethereum: ethereumLogo,
    polygon: polygonLogo,
    tron: tronLogo,
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        [position]: 10,
      }}
    >
      {logos[chain] ? (
        <img
          src={logos[chain]}
          alt={`${chain} logo`}
          style={{
            width: 40,
            height: 40,
          }}
        />
      ) : (
        <Person style={{ width: "40px", height: "40px" }} />
      )}
    </Box>
  );
}

export function SwapIcon(props) {
  const { classes, txnId } = props;
  const Icon = txnId ? (
    <BridgeCompleted
      className={classes.swapIcon}
      style={{
        color: "black",
      }}
    />
  ) : (
    <BridgeNotConfirmed
      className={classes.swapIcon}
      style={{
        color: "black",
      }}
    />
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "24px",
        marginTop: 4,
      }}
    >
      {Icon}
    </div>
  );
}

export function SwapIconContainer(props) {
  const {
    isUSDCSolanaDialogOpen,
    solanaInitTxnSignature,
    isUSDCAlgorandDialogOpen,
    algorandTxId,
    classes,
  } = props;
  const dialogType = [
    { isOpen: isUSDCSolanaDialogOpen, txnId: solanaInitTxnSignature },
    { isOpen: isUSDCAlgorandDialogOpen, txnId: algorandTxId },
  ].find(({ isOpen }) => isOpen);

  return <SwapIcon classes={classes} txnId={dialogType?.txnId} />;
}

function ChainLogoAndSwapIcon({
  fromChain,
  toChain,
  isUSDCSolanaDialogOpen,
  solanaInitTxnSignature,
  isUSDCAlgorandDialogOpen,
  algorandTxId,
  classes,
}) {
  console.log({ fromChain, toChain });
  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: "276px",
      }}
    >
      <ChainLogo chain={fromChain} position={"left"} />
      <ChainLogo chain={toChain} position={"right"} />
      <SwapIconContainer
        isUSDCSolanaDialogOpen={isUSDCSolanaDialogOpen}
        solanaInitTxnSignature={solanaInitTxnSignature}
        isUSDCAlgorandDialogOpen={isUSDCAlgorandDialogOpen}
        algorandTxId={algorandTxId}
        classes={classes}
      />
    </Box>
  );
}

export default ChainLogoAndSwapIcon;
