import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ColorButton = withStyles(() => ({
  root: {
    height: "50px",
    margin: "25px",
    padding: "7px 16px",
    lineHeight: "14.09px",
    borderRadius: "10px",
    backdropFilter: "blur(9px)",
  },
}))(Button);

export default withStyles({})(ColorButton);
