import React from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box } from "@material-ui/core";
import BridgePanel from "./BridgePanel/BridgePanel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "40%",
    margin: "auto",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  boxRoot: {
    borderRadius: "30px !important",
  },
  textDiv: {
    textAlign: "center",
    marginBottom: "3%",
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  img: {
    width: 85,
  },
  tabPanel: {
    [theme.breakpoints.down("xs")]: {},
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mvp-tabpanel-${index}`}
      aria-labelledby={`mvp-tab-${index}`}
    >
      {value === index && (
        <Box p={0} classes={{ root: classes.boxRoot }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function HomeTabs(props) {
  const classes = useStyles();
  const {
    isDark,
    isAdvancedMode,
    fromNetwork,
    fromToken,
    fromWallet,
    fromChain,
    toChain,
    toToken,
    toWallet,
    amount,
    algorandWalletAddress,
    setAlgoWallet,
    setFromWallet,
    setFromNetwork,
    setFromChain,
    setFromToken,
    setToToken,
    setToChain,
    setToWallet,
    setAmount,
    algorandWalletType,
    setAlgorandWalletType,
    solanaWalletType,
    solanaWalletAddress,
    solanaWalletObject,
    setSolanaWallet,
    //Ethereum
    ethereumWalletAddress,
    setEthereumWalletAddress,
    ethereumWalletType,
    setEthereumWalletType,
    ethereumWalletObject,
    setEthereumWalletObject,
    //Avalanche
    avalancheWalletAddress,
    setAvalancheWalletAddress,
    avalancheWalletType,
    setAvalancheWalletType,
    avalancheWalletObject,
    setAvalancheWalletObject,
    //TRON
    tronWalletAddress,
    setTronWalletAddress,
    tronWalletType,
    setTronWalletType,
    tronWalletObject,
    setTronWalletObject,
    network,
    connection,
    fromNativeBalance,
    toNativeBalance,
    fromTokenBalance,
    toTokenBalance,
    fromNativeMinBalance,
    fromNativeMinApiBalance,
    toNativeMinBalance,
    fromTokenMinBalance,
    toTokenMinBalance,
    setFromNativeBalance,
    setToNativeBalance,
    setFromTokenBalance,
    setToTokenBalance,
    setFromNativeMinBalance,
    setFromNativeMinApiBalance,
    setToNativeMinBalance,
    setFromTokenMinBalance,
    setToTokenMinBalance,
    xSolOptIn,
    setxSolOptIn,
    algorandWalletObject,
    setAlgorandWalletObject,
    activeStep,
    setActiveStep,
    exchangeChainValuesHandler,
  } = props;
  const [value, setValue] = React.useState(0);

  const getBalance = () => {
    const nativeTokensAsBalance = ["ALGO", "SOL"];
    const tokenBalance = ["xALGO", "xSOL", "USDC", "EUROC"];

    if (nativeTokensAsBalance.includes(fromToken)) {
      return fromNativeBalance;
    }
    if (tokenBalance.includes(fromToken)) {
      return fromTokenBalance;
    }
    return 0;
  };

  const walletBalanceForMax = getBalance();

  return (
    <>
      <div className={classes.root}>
        <TabPanel value={value} index={0}>
          <BridgePanel
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isDark={isDark}
            network={network}
            connection={connection}
            xSolOptIn={xSolOptIn}
            setxSolOptIn={setxSolOptIn}
            fromToken={fromToken}
            fromNetwork={fromNetwork}
            fromChain={fromChain}
            fromWallet={fromWallet}
            toToken={toToken}
            toChain={toChain}
            toWallet={toWallet}
            amount={amount}
            setAlgoWallet={setAlgoWallet}
            setFromWallet={setFromWallet}
            setFromNetwork={setFromNetwork}
            setFromChain={setFromChain}
            setFromToken={setFromToken}
            setToToken={setToToken}
            setToChain={setToChain}
            setToWallet={setToWallet}
            setAmount={setAmount}
            fromNativeBalance={fromNativeBalance}
            toNativeBalance={toNativeBalance}
            fromTokenBalance={fromTokenBalance}
            toTokenBalance={toTokenBalance}
            fromNativeMinBalance={fromNativeMinBalance}
            fromNativeMinApiBalance={fromNativeMinApiBalance}
            toNativeMinBalance={toNativeMinBalance}
            fromTokenMinBalance={fromTokenMinBalance}
            toTokenMinBalance={toTokenMinBalance}
            setFromNativeBalance={setFromNativeBalance}
            setToNativeBalance={setToNativeBalance}
            setFromTokenBalance={setFromTokenBalance}
            setToTokenBalance={setToTokenBalance}
            setFromNativeMinBalance={setFromNativeMinBalance}
            setFromNativeMinApiBalance={setFromNativeMinApiBalance}
            setToNativeMinBalance={setToNativeMinBalance}
            setFromTokenMinBalance={setFromTokenMinBalance}
            setToTokenMinBalance={setToTokenMinBalance}
            algorandWalletType={algorandWalletType}
            algorandWalletAddress={algorandWalletAddress}
            algorandWalletObject={algorandWalletObject}
            setAlgorandWalletType={setAlgorandWalletType}
            setAlgorandWalletObject={setAlgorandWalletObject}
            solanaWalletType={solanaWalletType}
            solanaWalletAddress={solanaWalletAddress}
            solanaWalletObject={solanaWalletObject}
            setSolanaWallet={setSolanaWallet}
            //Ethereum
            ethereumWalletAddress={ethereumWalletAddress}
            setEthereumWalletAddress={setEthereumWalletAddress}
            ethereumWalletType={ethereumWalletType}
            setEthereumWalletType={setEthereumWalletType}
            ethereumWalletObject={ethereumWalletObject}
            setEthereumWalletObject={setEthereumWalletObject}
            //Avalanche
            avalancheWalletAddress={avalancheWalletAddress}
            setAvalancheWalletAddress={setAvalancheWalletAddress}
            avalancheWalletType={avalancheWalletType}
            setAvalancheWalletType={setAvalancheWalletType}
            avalancheWalletObject={avalancheWalletObject}
            setAvalancheWalletObject={setAvalancheWalletObject}
            //TRON
            tronWalletAddress={tronWalletAddress}
            setTronWalletAddress={setTronWalletAddress}
            tronWalletType={tronWalletType}
            setTronWalletType={setTronWalletType}
            tronWalletObject={tronWalletObject}
            setTronWalletObject={setTronWalletObject}
            walletBalanceForMax={walletBalanceForMax}
            exchangeChainValuesHandler={exchangeChainValuesHandler}
          />
        </TabPanel>
      </div>
    </>
  );
}

HomeTabs.propTypes = {
  isDark: PropTypes.bool.isRequired,
  isAdvancedMode: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,
  xSolOptIn: PropTypes.bool.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  fromWallet: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  toChain: PropTypes.string.isRequired,
  toToken: PropTypes.string.isRequired,
  toWallet: PropTypes.string.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setFromWallet: PropTypes.func.isRequired,
  setFromChain: PropTypes.func.isRequired,
  setFromToken: PropTypes.func.isRequired,
  setToToken: PropTypes.func.isRequired,
  setToChain: PropTypes.func.isRequired,
  setToWallet: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  algorandWalletAddress: PropTypes.string.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  fromNativeBalance: PropTypes.number,
  toNativeBalance: PropTypes.number,
  fromTokenBalance: PropTypes.number,
  toTokenBalance: PropTypes.number,
  setFromNativeBalance: PropTypes.func.isRequired,
  setFromNativeMinBalance: PropTypes.func.isRequired,
  setToNativeBalance: PropTypes.func.isRequired,
  setFromNativeMinApiBalance: PropTypes.func.isRequired,
  setToNativeMinBalance: PropTypes.func.isRequired,
  setFromTokenBalance: PropTypes.func.isRequired,
  setFromTokenMinBalance: PropTypes.func.isRequired,
  setToTokenBalance: PropTypes.func.isRequired,
  setToTokenMinBalance: PropTypes.func.isRequired,
};
