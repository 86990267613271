import configData from "../config.json";

export const getChainIdByNetwork = (network, chain) => {
  return configData[chain][network].chainId;
};

export const ischainAndIdSame = (network, chain, chainId) => {
  let _chainId = getChainIdByNetwork(network, chain);
  if (chain === "ethereum") {
    return chainId === _chainId;
  } else if (chain === "avalanche") {
    return chainId === _chainId;
  } else if (chain === "polygon") {
    return chainId === _chainId;
  } else if (chain === "algorand") {
    return chainId === _chainId;
  } else if (chain === "solana") {
    return chainId === _chainId;
  } else return false;
};
