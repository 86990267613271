import { Button, Tooltip, Typography, withStyles } from "@material-ui/core";
import React from "react";

function Heading({ text, pd }) {
  return (
    <Typography
      style={{
        fontFamily: "ProximaNova",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "19px",
        textAlign: "center",
        textTransform: "uppercase",
        color: "#FFFFFF",
        padding: pd ?? "20px 40px",
      }}
    >
      {text}
    </Typography>
  );
}
const ColorButton = withStyles((theme) => ({
  root: {
    width: "max-content",
    position: "absolute",
    zIndex: "909",
    top: "0",
    bottom: "0",
    right: "0",
    left: "auto",
  },
}))(Button);

const ChooseWallet = ({ onClick, disabled }) => {
  return (
    <ColorButton
      variant="filled"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      Choose Wallet
    </ColorButton>
  );
};

export { Heading, ChooseWallet };
