import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import EcllipseLeftBottom from "../../assets/images/EcllipseLeftBottom.svg";
import EcllipseRightTop from "../../assets/images/EcllipseRightTop.svg";
import EcllipseRightBottom from "../../assets/images/EcllipseRightBottom.svg";

import { Typography } from "@material-ui/core";
import Footer from "../../componentsReuse/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    minHeight: "100vh",
    overflow: "auto",
  },
  container: {
    flex: 1,
  },
  tabMarginTop: {
    marginTop: "15%",
    flexGrow: 1,
    width: "80%",
    margin: "auto",
    marginTop: "3%",
    minHeight: "515px",
    // paddingBottom: "100px",
    boxSizing: "border-box",
    position: "relative",
    backdropFilter: "blur(19px)",
    [theme.breakpoints.down("md")]: {
      minHeight: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "676px",
      width: "83%",
      marginTop: "8%",
      zIndex: 1,
      position: "relative",
      // paddingBottom: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
      paddingBottom: 0,
      marginTop: "25px",
      borderRadius: "10px !important",
    },
  },
  heading: {
    color: "#fff",
    marginTop: "5%",
    marginBottom: "2%",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
      marginBottom: "15%",
    },
  },
  noteText: {
    color: "#fff",
    marginTop: "20px",
    fontSize: "24px",
    minHeight: "515px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "400px",
    },
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
}));

function BlockedPage(props) {
  const isDark = true;
  const { heading, text } = props;
  const classes = useStyles({ isDark: props.isDark });

  const theme = useTheme();

  return (
    <>
      <div className={classes.rootContainer}>
        <div
          className={classes.root}
          style={{
            backgroundImage: `url(${EcllipseLeftBottom}), url(${EcllipseRightTop}), url(${EcllipseRightBottom})`,
            backgroundPosition: "3.4% 85%, 92% 12%, 92% 96%",
            backgroundRepeat: "no-repeat, no-repeat,no-repeat",
            backgroundSize: "14%, 7.5%, 23%",
            backgroundOrigin: "content-box",
            position: "relative",
          }}
        >
          <div
            className={classes.container}
            style={{
              background:
                "radial-gradient(85.53% 81.9% at 46.13% 104.24%, rgba(6, 40, 197, 0.4) 0%, rgba(10, 42, 187, 0.0929615) 50.83%, rgba(12, 43, 184, 0) 85.73%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(184.7% 165.26% at 90.67% 145.05%, rgba(134, 0, 197, 0.486) 20.45%, rgba(85, 40, 201, 0.9) 37.74%, rgba(149, 27, 223, 0.567) 53.48%, rgba(249, 66, 132, 0.54) 64.05%, rgba(92, 0, 165, 0.891) 88.23%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */",
              backgroundBlendMode: "multiply, normal, multiply",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(6px)",
              WebkitBackdropFilter: "blur(6px)",
              height: "100%",
              minHeight: "100vh",
            }}
          >
            <Typography variant="h3" className={classes.heading}>
              {heading}
            </Typography>
            <div
              className={classes.tabMarginTop}
              style={{
                borderRadius: "23px",
                boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.4)",
                border: isDark ? "" : "1px solid rgb(145 144 144 / 19%)",
                background: isDark
                  ? "radial-gradient(147.98% 181.03% at 9.55% -30.29%, rgba(118, 208, 213, 0.6) 0%, rgba(82, 30, 166, 0) 100%), radial-gradient(80.88% 123.11% at 104.61% -3.37%, rgba(180, 41, 133, 0.6) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(53.7% 78.19% at 7.42% 128.04%, rgba(52, 90, 90, 0.7) 0%, rgba(4, 195, 255, 0) 100%), linear-gradient(rgb(146 146 146 / 0%) 0%, rgba(255, 255, 255, 0) 100%)"
                  : "",
                backdropFilter: "opacity(0.9) blur(21px)",
                [theme.breakpoints.down("sm")]: {
                  backdropFilter: "opacity(0.3) blur(21px)",
                },
              }}
            >
              <Typography variant="body1" className={classes.noteText}>
                {text}
              </Typography>
            </div>
            <div></div>
            <div className={classes.footer}>
              <Footer isDark={isDark} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockedPage;
