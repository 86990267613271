import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ButtonComp = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "10px",
    minWidth: "230px",
    [theme.breakpoints.down("md")]: {
      minWidth: "200px",
    },
  },
}))(Button);

export default withStyles({})(ButtonComp);
