import { Dialog, DialogContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const styles = (theme) => ({
  dialogPaper: {
    background: theme.palette.update3Palette.modalGradient,
    color: "#313030",
    backdropFilter: "blur(21px)",
  },
  iconBtnRoot: {
    padding: 0,
  },
  contentRoot: {
    display: "flex",
    flexDirection: "column",
  },
});

const WalletModal = ({ classes, open, handleClose, children }) => {
  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialogPaper,
      }}
      onClose={() => handleClose(false)}
    >
      <DialogContent className={classes.contentRoot}>{children}</DialogContent>
    </Dialog>
  );
};
export default withStyles(styles)(WalletModal);
