import React from "react";
import {
  Tooltip,
  Typography,
  Badge,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Done, Close } from "@material-ui/icons";

import myAlgowallet from "../../../../assets/images/myAlgoWallet.png";
import deflyWalletImg from "../../../../assets/images/defly wallet icon.png";
import peraWallet from "../../../../assets/images/pera.png";
import { WALLET_PROVIDERS } from "../../../../solanaFunctions";
import { ChooseWallet } from "../../../../componentsReuse/NutsAndBolts";
import exodusWalletImg from "../../../../assets/images/Exodus_symbol.svg";
import { EVM_SUPPORTED_CHAINS } from "../../../../ethereumFunctions/constants";
import { ETHEREUM_WALLET_PROVIDERS } from "../../../../ethereumFunctions/walletHandler";
import { TRON_WALLET_PROVIDERS } from "../../../../tronFunction/walletHandler";
import { isMobile } from "../../../../utils/utils";
import { TRON } from "../../../../tronFunction/constants";

const FromWalletDialogs = ({
  classes,
  isDark,
  isWalletQrModalOpen,
  handleCloseDialog,
  fromToken,
  walletDataURL,
  fromChain,
  algorandWalletType,
  solanaWalletType,
  ethereumWalletType,
  tronWalletType,
  handleClickConnectButton,
  isWalletConnectionModalOpen,
  isDisconnectDialogOpen,
  handleClickDisconnectButton,
}) => {
  return (
    <>
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        open={isWalletQrModalOpen}
        onClose={handleCloseDialog}
        aria-labelledby="qr-dialog-title"
        aria-describedby="qr-dialog-description"
      >
        <DialogTitle id="qr-dialog-title">
          {`${fromToken === "ALGO" ? "Algorand" : "Solana"} Address QRcode`}
        </DialogTitle>
        <DialogContent>
          <img
            alt="Wallet QR"
            className={classes.qrImage}
            src={walletDataURL}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isWalletConnectionModalOpen}
        onClose={handleCloseDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
        aria-labelledby="wallet-connection-dialog"
      >
        <DialogTitle>
          <IconButton
            style={{ color: "#ffffff", float: "right" }}
            onClick={handleCloseDialog}
            className={classes.iconBtnRoot}
          >
            <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            style={{
              color: "white",
            }}
          >
            You are already connected to{" "}
            {connectionType({
              fromChain,
              algorandWalletType,
              solanaWalletType,
              ethereumWalletType,
              tronWalletType,
            })}
            , if you proceed you will be disconnected from{" "}
            {connectionType({
              fromChain,
              algorandWalletType,
              solanaWalletType,
              ethereumWalletType,
              tronWalletType,
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleClickConnectButton}>Connect</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDisconnectDialogOpen}
        onClose={handleCloseDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
        aria-labelledby="disconnect-wallet-dialog"
      >
        <DialogTitle>
          <IconButton
            style={{ color: "#ffffff", float: "right" }}
            onClick={handleCloseDialog}
            className={classes.iconBtnRoot}
          >
            <Close style={{ color: isDark ? "#ffffff" : "#000000" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            style={{
              color: "white",
            }}
          >
            You are already connected to{" "}
            {connectionType({
              fromChain,
              algorandWalletType,
              solanaWalletType,
              ethereumWalletType,
              tronWalletType,
            })}
            , do you want to disconnect?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleClickDisconnectButton}>Disconnect</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const InputWalletsLogo = ({
  classes,
  fromChain,
  fromToken,
  algorandWalletAddress,
  algorandWalletType,
  solanaWalletAddress,
  solanaWalletType,
  ethereumWalletAddress,
  ethereumWalletType,
  tronWalletType,
  tronWalletAddress,
  handleClickWalletConnectButton,
  handleClickConnectMyAlgoWalletButton,
  handleModal,
  algoWalletType,
  handleSelectSolanaWalletButton,
  handleSelectEvmWalletButton,
  handleSelectTronWalletButton,
}) => {
  const walletAddressMap = {
    algorand: algorandWalletAddress,
    solana: solanaWalletAddress,
    [TRON]: tronWalletAddress,
    ...EVM_SUPPORTED_CHAINS.reduce(
      (acc, cur) => ({ ...acc, [cur]: ethereumWalletAddress }),
      {}
    ),
  };

  const walletAddressMissing = !walletAddressMap[fromChain];

  if (walletAddressMissing) {
    return <ChooseWallet disabled={fromToken === ""} onClick={handleModal} />;
  }

  return null;
};

function connectionType({
  fromChain,
  algorandWalletType,
  solanaWalletType,
  ethereumWalletType,
  tronWalletType,
}) {
  if (fromChain === "algorand" && algorandWalletType === "walletConnect") {
    return "ALGORAND WALLET";
  } else if (
    fromChain === "algorand" &&
    algorandWalletType === "myAlgoConnect"
  ) {
    return "MY ALGO WALLET";
  } else if (
    fromChain === "algorand" &&
    algorandWalletType === "deflyConnect"
  ) {
    return "Defly Wallet";
  } else if (
    fromChain === "solana" &&
    solanaWalletType.toLowerCase() === "phantom"
  ) {
    return "Phantom";
  } else if (
    fromChain === "solana" &&
    solanaWalletType.toLowerCase() === "sollet.io"
  ) {
    return "Sollet.io";
  } else if (
    fromChain === "solana" &&
    solanaWalletType.toLowerCase() === "solflare"
  ) {
    return "Solflare";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "metamask"
  ) {
    return "MetaMask";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "coin98"
  ) {
    return "Coin98";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "exodus"
  ) {
    return "Exodus";
  } else if (
    EVM_SUPPORTED_CHAINS.includes(fromChain) &&
    ethereumWalletType === "trust-wallet"
  ) {
    return "Trust Wallet";
  } else if (
    fromChain === "solana" &&
    solanaWalletType.toLowerCase() === "mathWallet"
  ) {
    return "MathWallet";
  } else if (fromChain === TRON && tronWalletType === "trust") {
    return "Trust Wallet";
  } else if (fromChain === TRON && tronWalletType === "tronLink") {
    return "Tron Link";
  }
}

const errorText = (errorType) => {
  var copyText = document.getElementById("wallet");
  let text;
  switch (errorType) {
    case "algoWalletValidation":
      text = "The wallet address is not valid";
      break;
    case "solanaWalletValidation":
      text = "The wallet address is not valid";
      break;
    case "phantomWalletError":
      text =
        "Phantom wallet is locked or not installed, please check Phantom wallet status and unlock it, then try again!";
      break;
    case "solletWalletError":
      text =
        "Sollet wallet is locked or not installed, please check Sollet wallet status and unlock it, then try again!";
      break;
    case "ethereumWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again!";
      break;
    case "coreWalletNotAvailable":
      text =
        "Core Wallet is locked or not installed or you are not on the right chain or some other same change wallet exist like Metamask/Coin98/Exodus. Please disable them then switch to correct chain or install wallet, and try again!";
      break;
    case "coreWalletWrongChain":
      text =
        "You are not on the right chain in your wallet. Please switch to correct chain, refersh and try again!";
      break;
    case "metamaskNotAvailable":
      text =
        "Metamask is locked or not installed or some other Ethereum chain wallet exists like Exodus/Coin98/Core. So please disable other Ethereum chain wallet, refresh the page and try again. Or you can use Wallet Connect scan.";
      break;
    case "coin98NotAvailable":
      text =
        "Coin98 is locked or not installed. Please install, refresh the page and try again. Or you can use Wallet Connect scan.";
      break;
    case "avalancheWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again. Or you can use Wallet Connect scan.";
      break;
    case "tronWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again!";
      break;
    case "tronWalletEnergy":
      text =
        "Your wallet has insufficient energy <30,000. Please stake Trx otherwise your Trx balance will be deducted!";
    case "polygonWalletValidation":
      text =
        "Wallet is locked or not installed, please check wallet status, then try again. Or you can use Wallet Connect scan.";
      break;
    case "wrongChainError":
      text =
        "You are not on the right chain at your wallet, please switch to the right chain and then connect. Make sure your wallet supports this chain.";
      break;
    case "copiedWalletAddress":
      text = "Copied the wallet address: " + copyText.value;
      break;
    default:
      text = "You have an error!";
  }
  return text;
};

export { InputWalletsLogo, connectionType, errorText, FromWalletDialogs };
