export const EVM_SUPPORTED_CHAINS = ["ethereum", "avalanche", "polygon"];
export const EVM_SUPPORTED_WALLETS = [
  "metamask",
  "exodus",
  "trust-wallet",
  "coin98",
  "walletConnect",
];
export const ALL_CHAINS = [
  "algorand",
  "solana",
  "ethereum",
  "avalanche",
  "polygon",
  "tron",
];
export const ALGORAND_ASSETS = ["ALGO", "xSOL", "USDC"];
export const SOLANA_ASSETS = ["SOL", "xALGO", "USDC"];
export const ETHEREUM_ASSETS = ["USDC", "EUROC"];
export const AVALANCHE_ASSETS = ["USDC", "EUROC"];
export const POLYGON_ASSETS = ["USDC"];
export const TRON_ASSETS = ["USDC"];
