import React, { memo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MyAlgo from "@randlabs/myalgo-connect";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import QRCodeModalSimple from "@walletconnect/qrcode-modal";
import { toDataURL } from "qrcode";
import algosdk from "algosdk";
import { Refresh, Close } from "@material-ui/icons";
import WalletConnectProvider from "@walletconnect/web3-provider";
import GradientBox from "../../GradientBox";
import {
  getServerURL,
  getIndexerURL,
} from "../../../algoFunctions/agloConnectionPublic";
import { isOptedIn } from "../../../algoFunctions/asset-optin";
import {
  solAssetsInfo,
  getTokenAccountList,
  checkIfxAlgoAccountExist,
  sleep,
} from "../../../solanaFunctions";
import configData from "../../../config.json";
import WalletModal from "../../../componentsReuse/WalletModal";
import { Heading } from "../../../componentsReuse/NutsAndBolts";
import Wallets from "../../ToWallets";
import {
  errorText,
  FromWalletDialogs,
  InputWalletsLogo,
} from "./components/Dialogs";

import algoLogo from "../../../assets/images/algo.png";
import algoLogoWhite from "../../../assets/images/algo-white.png";
import xAlgoLogo from "../../../assets/images/xalgo.png";
import xSolLogo from "../../../assets/images/xsol.png";
import USDCLogo from "../../../assets/images/usdc.png";
import crossIcon from "../../../assets/images/crossIcon.png";
import solanaLogo from "../../../assets/images/solana.svg";
import styles from "./FromWalletStepStyles";
import {
  EVM_SUPPORTED_CHAINS,
  EVM_SUPPORTED_WALLETS,
} from "../../../ethereumFunctions/constants";
import {
  ethereumWalletHandler,
  getEVMChainHelper,
} from "../../../ethereumFunctions/walletHandler";
import {
  getAvalanceTokenBalanceInfo,
  getEthTokenBalanceInfo,
  getPolygonTokenBalanceInfo,
} from "../../../ethereumFunctions/accountDetails";

import { ethers } from "ethers";
import FromWallets from "../../FromWallets";
import { ischainAndIdSame } from "../../../utils/chainUtils";
import {
  getTronUSDCBalanceByAbi,
  isEnoughEnergy,
} from "../../../tronFunction/walletHandler";
import TronWebNode from "tronweb";
import { TRON } from "../../../tronFunction/constants";

class FromWalletStep extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      algorandWalletAddress: this.props.algorandWalletAddress,
      solanaWalletAddress: this.props.solanaWalletAddress,
      ethereumWalletAddress: this.props.ethereumWalletAddress,
      tronWalletAddress: this.props.tronWalletAddress,
      selectedWalletType: null,
      algoWalletType: null,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
      isAlertDialogOpen: false,
      xSolAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "xSOL"
      ).asset_id,
      USDCAssetId: configData.algorand[this.props.network].assets_info.find(
        (a) => a.symbol === "USDC"
      ).asset_id,
      isAlgoInput: false,
      isSolInput: false,
      isConnectedToWallet: false,
      errorType: "",
      label: "Wallet Name",
      amount: 0,
      asset: "15993946",
      inverse: false,
      margin: 4,
      walletUri: "algorand://",
      errorLevel: "high",
      version: "auto",
      walletDataURL: algoLogo,
      isWalletQrModalOpen: false,
      darkColor: "#000",
      lightColor: "#FFF",
      trxPayment: [],
      trxTransfer: [],
      isModalOpen: false,
    };
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.generateWalletQRCode = this.generateWalletQRCode.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleSelectSolanaWalletButton =
      this.handleSelectSolanaWalletButton.bind(this);
    this.handleSolanaBalanceRefresh =
      this.handleSolanaBalanceRefresh.bind(this);
    this.algorandWallet = null;
    this.fetchAlgoWalletInfo = this.fetchAlgoWalletInfo.bind(this);
    this.handleClickConnectMyAlgoWalletButton =
      this.handleClickConnectMyAlgoWalletButton.bind(this);
    this.handleClickWalletConnectButton =
      this.handleClickWalletConnectButton.bind(this);
    this.handleMyAlgoConnect = this.handleMyAlgoConnect.bind(this);
    this.handleSelectEthereumWalletButton =
      this.handleSelectEthereumWalletButton.bind(this);
    this.handleSelectTronWalletButton =
      this.handleSelectTronWalletButton.bind(this);
    this.getSolanaBalance.bind(this);
    this.handleClickConnectButton = this.handleClickConnectButton.bind(this);
    this.handleClickDisconnectButton =
      this.handleClickDisconnectButton.bind(this);
    this.checkAssetOptIn = this.checkAssetOptIn.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  async componentDidMount() {
    this.props.setIsLoading(true);
    if (
      this.props.fromChain === "algorand" &&
      this.state.algorandWalletAddress
    ) {
      // await this.handleClickDisconnectButton();
    }
    if (this.props.fromChain === "solana" && this.props.solanaWalletObject) {
      // await this.handleClickDisconnectButton();
    }
    this.props.setIsLoading(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.algorandWalletAddress !== this.props.algorandWalletAddress) {
      this.setState({
        algorandWalletAddress: this.props.algorandWalletAddress,
      });
    }
    if (prevProps.solanaWalletAddress !== this.props.solanaWalletAddress) {
      this.setState({ solanaWalletAddress: this.props.solanaWalletAddress });
    }
  }

  generateWalletQRCode() {
    let {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress,
      tronWalletAddress,
      label,
      inverse,
      version,
      margin,
      errorLevel,
      lightColor,
      darkColor,
    } = this.state;
    const that = this;
    const { fromChain } = this.props;
    const errorCorrectionLevel = errorLevel;
    const color = { light: lightColor, dark: darkColor };

    const opts = {
      inverse,
      version,
      margin,
      errorCorrectionLevel,
      color,
    };
    let QR_URI = "";

    QR_URI = `${fromChain}://${
      fromChain === "algorand"
        ? algorandWalletAddress
        : fromChain === "solana"
        ? solanaWalletAddress
        : fromChain === TRON
        ? tronWalletAddress
        : ethereumWalletAddress
    }?label=${label}`;

    opts.mode = "Auto";
    toDataURL(QR_URI, opts)
      .then((res) => {
        this.setState({ walletDataURL: res, walletUri: QR_URI }, () => {
          that.setState({ isWalletQrModalOpen: true });
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async fetchAlgoWalletInfo() {
    const { algorandWalletAddress } = this.state;
    this.props.setIsLoading(true);
    const algoAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "ALGO");
    const xSolAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "xSOL");
    const USDCAssetInfo = configData.algorand[
      this.props.network
    ].assets_info.find((a) => a.symbol === "USDC");

    const that = this;
    //Note: To get minimum balance, we had to switch from algoindexer, to node.  Node is a rand labs api from Algoexplorer
    //https://algoexplorer.io/api-dev/v2
    if (algosdk.isValidAddress(algorandWalletAddress)) {
      const url =
        getServerURL(this.props.network) +
        `/v2/accounts/${algorandWalletAddress}`;
      const urlTrx =
        getIndexerURL(this.props.network) +
        `/v2/accounts/${algorandWalletAddress}/transactions?limit=10`;

      try {
        let account_response = await window.fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (account_response.status === 404) {
          this.props.setFromNativeBalance(0);
          this.props.setFromNativeMinApiBalance(0);
          this.props.setFromTokenBalance(0);
          this.props.setIsLoading(false);
          this.props.setIsWaitingForBalanceUpdate(false);
          return;
        }
        let account_data = await account_response.json();
        //Check Response Type
        if (account_data) {
          //Message indiates error in response from node
          if (account_data.message) {
            if (
              account_data.message.indexOf("no accounts found") > -1 &&
              this.props.fromChain === "algorand"
            ) {
            }
          } else if (account_data.address) {
            if (
              String(account_data.address) === String(algorandWalletAddress)
            ) {
              const amount =
                account_data.amount / 10 ** parseInt(algoAssetInfo.decimal);
              that.props.setFromNativeBalance(amount);
              const min_amount =
                account_data["min-balance"] /
                10 ** parseInt(algoAssetInfo.decimal);
              that.props.setFromNativeMinApiBalance(min_amount);

              if (account_data.assets) {
                account_data.assets.forEach((asset) => {
                  if (this.props.fromToken === "xSOL") {
                    if (asset["asset-id"] === Number(xSolAssetInfo.asset_id)) {
                      const amount =
                        asset.amount / 10 ** parseInt(xSolAssetInfo.decimal);
                      that.props.setFromTokenBalance(amount);
                    }
                  } else {
                    if (asset["asset-id"] === Number(USDCAssetInfo.asset_id)) {
                      const amount =
                        asset.amount / 10 ** parseInt(USDCAssetInfo.decimal);
                      that.props.setFromTokenBalance(amount);
                    }
                  }
                });
              } else {
                that.props.setFromTokenBalance(0);
              }
            }
          }
        }

        let txn_response = await window.fetch(urlTrx, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (txn_response.status === 404) {
          this.props.setIsLoading(false);
          this.props.setIsWaitingForBalanceUpdate(false);
          return;
        }
        let txn_data = await txn_response.json();
        if (txn_data) {
          if (txn_data.transactions) {
            that.setState({
              trxPayment: txn_data.transactions.filter(
                (trx) => !!trx["payment-transaction"]
              ),
              trxTransfer: txn_data.transactions.filter(
                (trx) => !!trx["asset-transfer-transaction"]
              ),
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.props.setIsCheckConnectionAlertOpen(true);
        this.props.setIsLoading(false);
        this.props.setIsWaitingForBalanceUpdate(false);
      }
    }
    this.props.setIsLoading(false);
    this.props.setIsWaitingForBalanceUpdate(false);
  }

  handleClickConnectMyAlgoWalletButton(walletName) {
    if (this.props.algorandWalletType === "") {
      this.handleMyAlgoConnect(walletName);
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });
    } else if (
      ["myAlgoConnect", "deflyConnect"].includes(this.props.algorandWalletType)
    ) {
      this.setState({ isDisconnectDialogOpen: true });
    } else if (this.props.algorandWalletType === "walletConnect") {
      this.setState({ isWalletConnectionModalOpen: true });
    }
  }

  async handleClickWalletConnectButton(walletName) {
    const { network, fromChain } = this.props;
    if (
      this.props.algorandWalletType === "" ||
      !this.props.algorandWalletType ||
      this.props.algorandWalletType === null
    ) {
      this.connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal:
          walletName === "Pera Algo" || walletName === "Exodus Mobile"
            ? QRCodeModal
            : QRCodeModalSimple,
      });

      const that = this;
      if (!this.connector.connected) {
        this.connector.createSession();
      } else {
        this.connector.killSession();
      }
      this.connector.on("connect", async (error, payload) => {
        const { name } = payload?.params?.[0]?.peerMeta;
        const { chainId } = payload?.params;

        if (error) {
          console.error(error);
          return;
        }

        const isTrue = ischainAndIdSame(network, fromChain, chainId);

        if (chainId && !isTrue) {
          that.props.setIsLoading(false);
          that.connector.killSession();
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "wrongChainError",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                10000
              );
            }
          );
          return;
        }

        that.algorandWallet = that.connector;
        that.props.setAlgorandWalletObject(that.algorandWallet);
        const { accounts } = payload.params[0];
        let wallet = accounts[0];

        if (algosdk.isValidAddress(accounts[0])) {
          that.setState(
            {
              isConnectedToWallet: true,
              algorandWalletAddress: wallet,
              algoWalletType: name,
            },
            async () => {
              await that.fetchAlgoWalletInfo();
            }
          );
          that.setState({ isConnectedToWallet: true });
          that.props.setAlgoWallet(wallet);
          that.props.setFromWallet(wallet);
          that.props.setIsWalletEmptyAlertOpen(false);
          that.props.setAlgorandWalletType("walletConnect");
        } else {
          that.connector?.killSession();
          return;
        }
        return;
      });

      this.connector.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }

        const { accounts } = payload.params[0];

        that.setState(
          { algorandWalletAddress: accounts[0].address },
          async () => {
            await that.fetchAlgoWalletInfo();
          }
        );
        that.props.setAlgoWallet(accounts[0].address);
      });

      this.connector.on("disconnect", (error, payload) => {
        this.algorandWallet = null;
        if (error) {
          console.error(error);
          return;
        }
      });
      this.setState({ isAlgoInput: true, isWalletSelectionModalOpen: false });
    } else if (this.props.algorandWalletType === "walletConnect") {
      this.setState({ isDisconnectDialogOpen: true });
    } else if (
      ["myAlgoConnect", "deflyConnect"].includes(this.props.algorandWalletType)
    ) {
      this.setState({ isWalletConnectionModalOpen: true });
    }
  }

  async handleMyAlgoConnect(walletName) {
    let localStorageSession = localStorage.getItem("walletconnect");
    if (walletName === "Defly" && localStorageSession) {
      localStorage.removeItem("walletconnect");
    }

    try {
      this.props.setIsLoading(true);
      this.algorandWallet =
        walletName === "MyAlgo"
          ? new MyAlgo()
          : walletName === "Defly"
          ? new DeflyWalletConnect()
          : this.algorandWallet;

      const accountsConnect = await this.algorandWallet.connect();
      const accounts =
        walletName === "MyAlgo"
          ? accountsConnect
          : [
              {
                address: accountsConnect[0],
              },
            ];
      this.props.setAlgorandWalletObject(this.algorandWallet);

      this.setState(
        { algorandWalletAddress: accounts[0].address },
        async () => {
          await this.fetchAlgoWalletInfo();
        }
      );

      if (algosdk.isValidAddress(accounts[0].address)) {
        this.props.setAlgoWallet(accounts[0].address);
        this.props.setFromWallet(accounts[0].address);
        this.props.setAlgorandWalletType(
          walletName === "MyAlgo" ? "myAlgoConnect" : "deflyConnect"
        );
        this.props.setIsWalletEmptyAlertOpen(false);
      } else {
        this.setState(
          {
            isAlertDialogOpen: true,
            errorType: "algoWalletValidation",
          },
          () => {
            setTimeout(() => this.setState({ isAlertDialogOpen: false }), 5000);
          }
        );
      }
      this.props.setIsLoading(false);
    } catch (err) {
      console.error(err);
      this.props.setIsLoading(false);
      this.props.setIsCheckConnectionAlertOpen(true);
    }
  }

  async getSolanaBalance() {
    const {
      network,
      connection,
      fromToken,
      solanaWalletObject,
      setFromNativeBalance,
      setFromTokenBalance,
    } = this.props;
    let isGetSolBalanceCompleted = false;
    let balance = 0;
    const solAssetInfo = configData.solana[network].assets_info.find(
      (a) => a.symbol === "SOL"
    );
    let retry_count = 0;
    while (!isGetSolBalanceCompleted) {
      try {
        balance = await connection.getBalance(solanaWalletObject.publicKey);
        isGetSolBalanceCompleted = true;
      } catch {
        retry_count++;
        if (retry_count > configData.settings.polling_retry) {
          this.setState({
            isBalanceAlertOpen: true,
          });
          this.props.setIsCheckConnectionAlertOpen(true);
          return;
        }
        await sleep(configData.settings.polling_interval);
        continue;
      }
    }
    balance = balance / 10 ** Number(solAssetInfo.decimal);
    setFromNativeBalance(balance);

    if (fromToken === "xALGO") {
      let tokenAccountInfo = await getTokenAccountList(
        connection,
        solAssetsInfo(network).find((a) => a.symbol === "xALGO").mint,
        solanaWalletObject.publicKey
      );
      if (!tokenAccountInfo) {
        setFromTokenBalance(0);
        return;
      }
      const rawxAlgoBalance = await connection.getTokenAccountBalance(
        tokenAccountInfo[0].accountPubkey
      );
      const xAlgoBalance = Number(rawxAlgoBalance.value.uiAmount);

      setFromTokenBalance(xAlgoBalance);
    }
    if (fromToken === "USDC") {
      let tokenAccountInfo = await getTokenAccountList(
        connection,
        solAssetsInfo(network).find((a) => a.symbol === "USDC").mint,
        solanaWalletObject.publicKey
      );
      if (!tokenAccountInfo) {
        setFromTokenBalance(0);
        return;
      }
      const rawxAlgoBalance = await connection.getTokenAccountBalance(
        tokenAccountInfo[0].accountPubkey
      );
      const xAlgoBalance = Number(rawxAlgoBalance.value.uiAmount);

      setFromTokenBalance(xAlgoBalance);
    }
  }

  async handleSolanaBalanceRefresh() {
    this.props.setIsLoading(true);
    this.props.setIsWaitingForBalanceUpdate(true);
    await this.getSolanaBalance();
    this.props.setIsLoading(false);
    this.props.setIsWaitingForBalanceUpdate(false);
  }

  async handleSelectSolanaWalletButton(walletType) {
    const solInfo = configData.solana[this.props.network].assets_info.find(
      (a) => a.symbol === "SOL"
    );

    if (this.props.solanaWalletType === "") {
      this.props.setIsLoading(true);
      try {
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });
        await this.props.setSolanaWallet(walletType);

        this.props.setIsWalletEmptyAlertOpen(false);
        this.props.setFromWallet(this.props.solanaWalletAddress);
        await this.getSolanaBalance();
        this.setState({ isAlertDialogOpen: false });
        const that = this;
        if (this.props.fromToken !== "SOL") {
          const { isSolanaOptIn } = await checkIfxAlgoAccountExist(
            this.props.network,
            this.props.connection,
            this.props.solanaWalletObject.publicKey,
            this.props.solanaAssetInfo.mint,
            that
          );
          if (
            this.props.fromNativeBalance >= Number(solInfo.min_balance) &&
            isSolanaOptIn === false
          ) {
            this.props.setSolanaOptInAlertOpen(true);
          }
        }
      } catch (err) {
        console.error(err);
        this.props.setIsLoading(false);
        this.props.setIsCheckConnectionAlertOpen(true);
      }
      this.props.setIsLoading(false);
    } else if (this.props.solanaWalletType === walletType) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletType,
      });
    }
  }

  async handleClickConnectButton() {
    const { fromChain } = this.props;
    this.props.setIsLoading(true);
    this.setState({
      isWalletConnectionModalOpen: false,
    });

    if (fromChain === "algorand") {
      await this.props.setAlgoWallet("");

      if (this.props.algorandWalletType === "walletConnect") {
        await this.props.setAlgorandWalletType("");
        this.algorandWallet = null;
        this.handleClickConnectMyAlgoWalletButton();
        if (this.connector) {
          this.connector.killSession();
        }
      } else if (
        ["myAlgoConnect", "deflyConnect"].includes(
          this.props.algorandWalletType
        )
      ) {
        await this.props.setAlgorandWalletType("");
        this.handleClickWalletConnectButton();
      }
    } else if (fromChain === "solana") {
      await this.props.setSolanaWallet("");
      await this.handleSelectSolanaWalletButton(this.state.selectedWalletType);
    } else if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
      await this.handleClickDisconnectButton();
      await this.handleSelectEthereumWalletButton(
        this.state.selectedWalletType
      );
    }
    this.setState({
      isWalletConnectionModalOpen: false,
    });
    this.props.setIsLoading(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
  }

  /*
   *
   *    Ethereum
   */
  async handleSelectEthereumWalletButton(walletname) {
    const that = this;
    const { fromChain, network, fromToken } = this.props;
    const tokenName = fromToken;

    const connectWithWallet = async (walletname) => {
      this.props.setIsLoading(true);

      let wallet;
      try {
        wallet = await ethereumWalletHandler(walletname, network, fromChain);

        if (wallet?.ethereumWalletAddress) {
          this.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            ethereumWalletAddress: wallet?.ethereumWalletAddress,
          });
          this.props.setFromWallet(wallet?.ethereumWalletAddress);
          this.props.setEthereumWalletType(wallet?.ethereumWalletType);
          this.props.setEthereumWalletObject(wallet?.ethereumWalletObject);
          this.props.setEthereumWalletAddress(wallet?.ethereumWalletAddress);
        }
      } catch (error) {
        console.log("error ", error);
        that.setState(
          {
            isAlertDialogOpen: true,
            errorType: "ethereumWalletValidation",
          },
          () => {
            setTimeout(
              () => that.setState({ isAlertDialogOpen: false }),
              10000
            );
          }
        );
      }
      try {
        let balance;

        if (fromChain === "ethereum") {
          balance = await getEthTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (fromChain === "avalanche") {
          balance = await getAvalanceTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        } else if (fromChain === "polygon") {
          balance = await getPolygonTokenBalanceInfo(
            wallet?.ethereumWalletAddress,
            network,
            tokenName,
            wallet?.ethereumWalletObject
          );
        }
        this.props.setFromTokenBalance(balance);
      } catch (e) {
        console.log("error ", e);
        this.props.setFromTokenBalance(0);
        this.props.setIsLoading(false);
        return;
      }

      this.props.setIsLoading(false);
    };

    if (!EVM_SUPPORTED_WALLETS.includes(this.props.ethereumWalletType)) {
      const provider = window?.ethereum;

      if (walletname === "walletConnect") {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        let localStorageSession = localStorage.getItem("walletconnect");
        if (localStorageSession) {
          localStorage.removeItem("walletconnect");
        }

        this.connector = new WalletConnectProvider({
          bridge: "https://bridge.walletconnect.org",
          rpc: {
            1: "https://rpc.ankr.com/eth",
            5: "https://rpc.ankr.com/eth_goerli",
            43113: "https://api.avax-test.network/ext/C/rpc",
            43114: "https://avalanche.public-rpc.com",
            137: "https://rpc.ankr.com/polygon",
            80001: "https://rpc.ankr.com/polygon_mumbai",
          },
          qrcode: QRCodeModalSimple,
          qrcodeModalOptions: {
            desktopLinks: ["exodus", "trust"],
            mobileLinks: ["metamask", "exodus", "trust"],
          },
        });

        //  Enable session (triggers QR Code modal)
        await this.connector.enable();

        let web3Provider = new ethers.providers.Web3Provider(this.connector);
        const walletChainId = web3Provider?.provider?.chainId;
        const isTrue = ischainAndIdSame(
          network,
          fromChain,
          web3Provider?.provider?.chainId
        );

        const connectWalletName = web3Provider?.provider?.wc?.peerMeta?.name;

        if (connectWalletName === "Exodus Mobile" && !isTrue) {
          const param = getEVMChainHelper(network, fromChain);
          try {
            const existingChainId = param[0].chainId;
            await this.connector.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: existingChainId }],
            });
          } catch (e) {
            console.log("error ", e);
          }
        } else if (walletChainId && !isTrue) {
          this.props.setIsLoading(false);
          that.setState(
            {
              isAlertDialogOpen: true,
              errorType: "wrongChainError",
            },
            () => {
              setTimeout(
                () => that.setState({ isAlertDialogOpen: false }),
                10000
              );
            }
          );
          return;
        }

        web3Provider = new ethers.providers.Web3Provider(this.connector);

        that.props.setEthereumWalletObject(web3Provider.getSigner());

        let wallet;

        if (
          web3Provider.provider.accounts &&
          web3Provider.provider.accounts.length > 0
        ) {
          wallet = web3Provider.provider.accounts[0];
          that.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            ethereumWalletAddress: web3Provider.provider.accounts[0],
          });
        }

        try {
          let balance;
          if (wallet) {
            if (fromChain === "ethereum") {
              balance = await getEthTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            } else if (fromChain === "avalanche") {
              balance = await getAvalanceTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            } else if (fromChain === "polygon") {
              balance = await getPolygonTokenBalanceInfo(
                wallet,
                network,
                tokenName,
                web3Provider.getSigner()
              );
            }
            this.props.setFromTokenBalance(balance);
            that.setState({ isConnectedToWallet: true });
            that.props.setEthereumWalletAddress(wallet);
            that.props.setFromWallet(wallet);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setEthereumWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "ethereumWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setFromTokenBalance(0);
        }
      } else if (walletname === "metamask") {
        if (provider && provider?.coreProvider !== undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }
        if (
          window?.ethereum === undefined ||
          !provider?.isMetaMask ||
          provider?.isExodus ||
          provider?.isCoin98
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "metamaskNotAvailable",
          });
          return;
        }
        await connectWithWallet(walletname);
      } else if (walletname === "coin98") {
        if (!provider?.isCoin98) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coin98NotAvailable",
          });
          return;
        }
        await connectWithWallet(walletname);
      } else if (walletname === "coreWallet") {
        if (provider === undefined || provider.coreProvider === undefined) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletNotAvailable",
          });
          return;
        }
        if (
          (["43114", "43113"].includes(
            provider?.coreProvider?.networkVersion
          ) &&
            fromChain === "ethereum") ||
          (["1", "5"].includes(provider?.coreProvider?.networkVersion) &&
            fromChain === "avalanche") ||
          (["137", "80001"].includes(provider?.coreProvider?.networkVersion) &&
            fromChain !== "polygon")
        ) {
          this.setState({
            isAlertDialogOpen: true,
            errorType: "coreWalletWrongChain",
          });
          return;
        }

        await connectWithWallet(walletname);
      }

      this.props.setIsWalletEmptyAlertOpen(false);
      this.setState({ isAlertDialogOpen: false });
      this.props.setIsLoading(false);
    } else if (this.props.ethereumWalletType === walletname) {
      this.setState({ isDisconnectDialogOpen: true });
    } else {
      this.setState({
        isWalletConnectionModalOpen: true,
        selectedWalletType: walletname,
      });
    }
  }

  /*
   *
   * TRON
   *
   */
  async handleSelectTronWalletButton(walletname) {
    const { network, tronWalletType } = this.props;
    const that = this;

    if (tronWalletType === "") {
      if (walletname === "tronLink") {
        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        try {
          const tronWeb = new TronWebNode({
            fullHost: "https://api.trongrid.io",
            eventServer: "https://api.trongrid.io",
          });

          // Check if the user has TronLink  Wallet installed
          let addressTron = window?.tronWeb?.defaultAddress?.base58;
          let tronWebExt = window?.tronWeb;

          if (tronWebExt) {
            await tronWeb.setAddress(addressTron);
          } else {
            console.log("No TronLink installed");
          }

          let isEnoughEnergyPresent = false;
          try {
            isEnoughEnergyPresent = await isEnoughEnergy(
              tronWebExt,
              addressTron
            );
          } catch (error) {
            console.log("error", error);
            isEnoughEnergyPresent = false;
          }
          that.props.setTronWalletObject(tronWebExt);

          if (addressTron) {
            that.setState({
              isConnectedToWallet: true,
              selectedWalletType: walletname,
              tronWalletAddress: addressTron,
            });
          }

          let balance;
          if (addressTron) {
            balance = await getTronUSDCBalanceByAbi(
              tronWebExt,
              network,
              addressTron
            );

            this.props.setFromTokenBalance(balance);
            that.props.setTronWalletAddress(addressTron);
            that.props.setFromWallet(addressTron);
            that.props.setIsWalletEmptyAlertOpen(false);
            that.props.setTronWalletType(walletname);
          } else {
            that.setState(
              {
                isAlertDialogOpen: true,
                errorType: "tronWalletValidation",
              },
              () => {
                setTimeout(
                  () => that.setState({ isAlertDialogOpen: false }),
                  10000
                );
              }
            );
          }
        } catch (e) {
          console.log("error ", e);
          this.props.setFromTokenBalance(0);
        }
      } else if (walletname === "walletConnect") {
        let localStorageSession = localStorage.getItem("walletconnect");
        if (localStorageSession) {
          localStorage.removeItem("walletconnect");
        }

        this.props.setIsLoading(true);
        this.setState({ isSolInput: true, isWalletSelectionModalOpen: false });

        try {
          const provider = new WalletConnectProvider({
            rpc: {
              1: "https://api.trongrid.io",
              2: "https://api.shasta.trongrid.io",
            },
            chainId: 1, // Mainnet
            qrcode: true,
            qrcode: QRCodeModalSimple,
            // qrcodeModalOptions:
          });

          //  Enable session (triggers QR Code modal)
          await provider.enable();

          const connector = await provider.connect();

          this.setState({
            isConnectedToWallet: true,
            selectedWalletType: walletname,
            tronWalletAddress: connector?.address,
          });

          that.setState({ isConnectedToWallet: true });
          that.props.setTronWalletAddress(connector?.address);
          that.props.setFromWallet(connector?.address);
          that.props.setIsWalletEmptyAlertOpen(false);
          that.props.setTronWalletType(walletname);
          // debugger;
        } catch (e) {
          console.log("error ", e);
          this.props.setFromTokenBalance(0);
        }
      }
      this.props.setIsLoading(false);
    } else {
      this.setState({ isDisconnectDialogOpen: true });
    }
  }

  async handleClickDisconnectButton() {
    if (this.props.fromChain === "algorand") {
      let localStorageSession = localStorage.getItem("walletconnect");
      if (localStorageSession) {
        localStorage.removeItem("walletconnect");
      }

      if (this.props.algorandWalletType === "walletConnect") {
        if (this.connector) {
          this.connector?.killSession();
        }
      } else if (this.props.algorandWalletType === "deflyConnect") {
        console.log("defly connect");
        this.props.algorandWalletObject?.disconnect();
      }

      this.props.setAlgorandWalletType("");
      this.props.setAlgoWallet("");
    } else if (this.props.fromChain === "solana") {
      this.props.solanaWalletObject.disconnect();

      await this.props.setSolanaWallet("");
      this.setState({ solanaWalletAddress: "", algorandWalletAddress: "" });
    } else if (EVM_SUPPORTED_CHAINS.includes(this.props.fromChain)) {
      if (this.props.ethereumWalletType === "metamask") {
        if (this.connector) {
          this.connector?.killSession();
        }
      }
      this.props.setEthereumWalletObject("");
      this.props.setEthereumWalletType("");
      this.props.setEthereumWalletAddress("");
    } else if (this.props.fromChain === TRON) {
      if (this.props.tronWalletType === "walletConnect") {
        if (this.connector) {
          this.connector?.killSession();
          this.connector?.disconnect();
        }
      }
      this.props.setTronWalletObject("");
      this.props.setTronWalletType("");
      this.props.setTronWalletAddress("");
    }

    this.setState({
      ethereumWalletAddress: "",
      algorandWalletAddress: "",
      solanaWalletAddress: "",
      tronWalletAddress: "",
    });

    this.setState({
      isDisconnectDialogOpen: false,
      isWalletSelectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });

    this.props.setFromNativeBalance(null);
    this.props.setFromNativeMinApiBalance(null);
    this.props.setFromTokenBalance(null);
    this.props.setIsWalletEmptyAlertOpen(true);
    this.props.setSolanaOptInAlertOpen(false);
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setxSolOptIn(false);
    this.props.setIsAlgoWalletOptIn(false);
  }

  handleCloseDialog() {
    this.setState({
      isWalletQrModalOpen: false,
      isWalletSelectionModalOpen: false,
      isWalletConnectionModalOpen: false,
      isDisconnectDialogOpen: false,
    });
  }

  handleCloseAlert() {
    this.setState({
      isAlertDialogOpen: false,
    });
    this.props.setIsXsolOptInAlertOpen(false);
    this.props.setSolanaOptInAlertOpen(false);
  }

  async checkAssetOptIn(wallet, asset) {
    const result = isOptedIn(this.props.network, wallet, asset);
    this.props.setxSolOptIn(result);
    return result;
  }
  handleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }
  handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      algorandWalletAddress,
      solanaWalletAddress,
      ethereumWalletAddress,
      tronWalletAddress,
      algoWalletType,
      isWalletQrModalOpen,
      isWalletConnectionModalOpen,
      isDisconnectDialogOpen,
      isAlertDialogOpen,
      errorType,
      isModalOpen,
    } = this.state;
    const {
      classes,
      isDark,
      network,
      toChain,
      fromToken,
      fromChain,
      algorandWalletType,
      solanaWalletType,
      ethereumWalletType,
      tronWalletType,
      fromNativeBalance,
      fromTokenBalance,
      setIsWaitingForBalanceUpdate,
    } = this.props;
    const that = this;
    const ethereum = window?.ethereum;

    if (ethereum) {
      ethereum?.on("chainChanged", async (chainId) => {
        if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
          this.props.setIsLoading(true);
          const ethersProvider = new ethers.providers.Web3Provider(
            window?.ethereum
          );

          const signer = ethersProvider?.getSigner();
          const address = await signer?.getAddress();

          let balance = null;
          // debugger;
          try {
            if (fromChain === "ethereum") {
              balance = await getEthTokenBalanceInfo(
                address,
                network,
                fromToken,
                signer
              );
            } else if (fromChain === "avalanche") {
              balance = await getAvalanceTokenBalanceInfo(
                address,
                network,
                fromToken,
                signer
              );
            } else if (fromChain === "polygon") {
              balance = await getPolygonTokenBalanceInfo(
                address,
                network,
                fromToken,
                signer
              );
            }
            that.props.setFromTokenBalance(balance);
            this.props.setEthereumWalletObject(signer);
          } catch (error) {
            console.log("error:", error);
          }
          this.props.setIsLoading(false);
        }
      });
    }

    const balance =
      {
        algorand: `ALGO Balance: ${Number(fromNativeBalance).toPrecision(7)}`,
        solana: `SOL Balance: ${Number(fromNativeBalance).toPrecision(9)}`,
      }[fromChain] || "";

    const logo =
      {
        algorand: (
          <img
            style={{ width: 20, marginLeft: 5 }}
            src={isDark ? algoLogoWhite : algoLogo}
            alt="ALGO"
          />
        ),
        solana: (
          <img
            style={{ width: 20, marginLeft: 5 }}
            src={solanaLogo}
            alt="solana"
          />
        ),
      }[fromChain] || "";

    const selectedFromWalletAddress =
      fromChain === "algorand"
        ? algorandWalletAddress
        : fromChain == "solana"
        ? solanaWalletAddress
        : fromChain == TRON
        ? tronWalletAddress
        : ethereumWalletAddress;

    return (
      <>
        <WalletModal open={isModalOpen} handleClose={this.handleModalClose}>
          <Heading pd="0px 20px" text={"Choose and connect your wallet:"} />
          <FromWallets
            chain={fromChain}
            fromChain={fromChain}
            toChain={toChain}
            ethereumWalletType={ethereumWalletType}
            solanaWalletType={solanaWalletType}
            algorandWalletType={algorandWalletType}
            tronWalletType={tronWalletType}
            handleModalClose={this.handleModalClose}
            handleClickWalletConnectButton={this.handleClickWalletConnectButton}
            handleClickConnectMyAlgoWalletButton={
              this.handleClickConnectMyAlgoWalletButton
            }
            handleSelectSolanaWalletButton={this.handleSelectSolanaWalletButton}
            handleSelectEvmWalletButton={this.handleSelectEthereumWalletButton}
            handleSelectTronWalletButton={this.handleSelectTronWalletButton}
          />
        </WalletModal>
        <FromWalletDialogs
          classes={classes}
          isDark={isDark}
          isWalletQrModalOpen={isWalletQrModalOpen}
          handleCloseDialog={this.handleCloseDialog}
          fromToken={this.props.fromToken}
          walletDataURL={this.state.walletDataURL}
          fromChain={fromChain}
          algorandWalletType={algorandWalletType}
          solanaWalletType={solanaWalletType}
          ethereumWalletType={ethereumWalletType}
          tronWalletType={tronWalletType}
          isWalletConnectionModalOpen={isWalletConnectionModalOpen}
          handleClickConnectButton={this.handleClickConnectButton}
          isDisconnectDialogOpen={isDisconnectDialogOpen}
          handleClickDisconnectButton={this.handleClickDisconnectButton}
        />
        {isAlertDialogOpen && (
          <Alert
            style={{
              color: "white",
            }}
            className={classes.alertBgTransparent}
            severity={errorType === "copiedWalletAddress" ? "success" : "error"}
            onClose={this.handleCloseAlert}
            classes={{
              message: classes.alertMessage,
              action: classes.alertAction,
            }}
          >
            {errorText(this.state.errorType)}
          </Alert>
        )}

        <TextField
          id="wallet"
          label={
            (fromChain === "algorand" && algorandWalletAddress === "") ||
            (fromChain === "solana" && solanaWalletAddress === "") ||
            (EVM_SUPPORTED_CHAINS.includes(fromChain) &&
              ethereumWalletAddress === "") ||
            (fromChain === TRON && tronWalletAddress === "") ? (
              <Typography
                variant="body1"
                style={{
                  fontSize: "12px",
                  color: "#5E14A8",
                  fontFamily: "ProximaNova",
                }}
              >
                Connect your wallet...
              </Typography>
            ) : (
              "Selected wallet"
            )
          }
          variant="outlined"
          value={selectedFromWalletAddress}
          className={classes.addressField}
          margin="normal"
          onChange={(event) => {
            fromChain === "algorand"
              ? this.setState({ algorandWalletAddress: event.target.value })
              : fromChain == "solana"
              ? this.setState({ solanaWalletAddress: event.target.value })
              : fromChain == TRON
              ? this.setState({ tronWalletAddress: event.target.value })
              : this.setState({ ethereumWalletAddress: event.target.value });
          }}
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.addressInput,
            },
            endAdornment: (
              <>
                <InputWalletsLogo
                  classes={classes}
                  fromChain={fromChain}
                  fromToken={fromToken}
                  algorandWalletAddress={algorandWalletAddress}
                  algorandWalletType={algorandWalletType}
                  algoWalletType={algoWalletType}
                  solanaWalletAddress={solanaWalletAddress}
                  solanaWalletType={solanaWalletType}
                  ethereumWalletType={ethereumWalletType}
                  ethereumWalletAddress={ethereumWalletAddress}
                  tronWalletType={tronWalletType}
                  tronWalletAddress={tronWalletAddress}
                  handleClickWalletConnectButton={
                    this.handleClickWalletConnectButton
                  }
                  handleClickConnectMyAlgoWalletButton={
                    this.handleClickConnectMyAlgoWalletButton
                  }
                  handleModal={this.handleModal}
                  handleSelectSolanaWalletButton={
                    this.handleSelectSolanaWalletButton
                  }
                  handleSelectEvmWalletButton={
                    this.handleSelectEthereumWalletButton
                  }
                  handleSelectTronWalletButton={
                    this.handleSelectTronWalletButton
                  }
                />
                {((fromChain === "algorand" && algorandWalletAddress !== "") ||
                  (fromChain === "solana" && solanaWalletAddress !== "") ||
                  (EVM_SUPPORTED_CHAINS.includes(fromChain) &&
                    ethereumWalletAddress !== "") ||
                  (fromChain === TRON && tronWalletAddress !== "")) && (
                  <Tooltip title={"Disconnect"}>
                    <Button
                      style={{
                        position: "absolute",
                        right: "0px",
                        zIndex: "9",
                        minWidth: "40px",
                      }}
                      onClick={() => {
                        if (fromChain === "algorand") {
                          if (algorandWalletType === "walletConnect")
                            this.handleClickWalletConnectButton();
                          else if (
                            ["myAlgoConnect", "deflyConnect"].includes(
                              algorandWalletType
                            )
                          )
                            this.handleClickConnectMyAlgoWalletButton();
                        } else if (fromChain === "solana") {
                          this.handleSelectSolanaWalletButton(solanaWalletType);
                        } else if (EVM_SUPPORTED_CHAINS.includes(fromChain)) {
                          this.handleSelectEthereumWalletButton(
                            ethereumWalletType
                          );
                        } else if (fromChain === TRON) {
                          this.handleSelectTronWalletButton(tronWalletType);
                        }
                      }}
                    >
                      <Close />
                    </Button>
                  </Tooltip>
                )}
              </>
            ),
          }}
          inputlabel={{
            root: classes.inputLabel,
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />

        {(fromChain === "algorand" && algorandWalletAddress !== "") ||
        (fromChain === "solana" && solanaWalletAddress !== "") ||
        (EVM_SUPPORTED_CHAINS.includes(fromChain) &&
          ethereumWalletAddress !== "") ||
        (fromChain === TRON && tronWalletAddress !== "") ? (
          <>
            {balance && logo ? (
              <GradientBox>
                <Typography
                  variant="body1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    fontWeight: "bold",
                  }}
                >
                  {balance}
                  {logo}
                </Typography>
              </GradientBox>
            ) : null}
            {["xALGO", "xSOL", "USDC", "EUROC"].includes(fromToken) && (
              <GradientBox>
                <Typography className={classes.balance}>
                  Your token balance:
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.balance}
                  style={{ fontWeight: "800", display: "flex" }}
                >
                  {` ${Number(fromTokenBalance || 0)?.toFixed(4)} ${fromToken}`}
                </Typography>

                {(fromToken === "xALGO" ||
                  fromToken === "xSOL" ||
                  (fromToken === "USDC" &&
                    !EVM_SUPPORTED_CHAINS.includes(fromChain))) && (
                  <Typography variant="h6" className={classes.balance}>
                    <Tooltip title="Update Balance">
                      <IconButton
                        onClick={() => {
                          if (fromChain === "algorand") {
                            that.fetchAlgoWalletInfo();
                            setIsWaitingForBalanceUpdate(true);
                          } else if (fromChain === "solana") {
                            that.handleSolanaBalanceRefresh();
                            setIsWaitingForBalanceUpdate(true);
                          }
                        }}
                        style={{ padding: "0" }}
                      >
                        <Refresh style={{ color: "#ffffff" }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                )}
              </GradientBox>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

FromWalletStep.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isDark: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  connection: PropTypes.object.isRequired,
  setxSolOptIn: PropTypes.func.isRequired,
  fromWallet: PropTypes.string.isRequired,
  fromToken: PropTypes.string.isRequired,
  fromChain: PropTypes.string.isRequired,
  setFromWallet: PropTypes.func.isRequired,
  setAlgoWallet: PropTypes.func.isRequired,
  setIsWalletEmptyAlertOpen: PropTypes.func.isRequired,
  algorandWalletType: PropTypes.string.isRequired,
  setAlgorandWalletType: PropTypes.func.isRequired,
  algorandWalletAddress: PropTypes.string.isRequired,
  solanaWalletType: PropTypes.string.isRequired,
  solanaWalletAddress: PropTypes.string.isRequired,
  solanaWalletObject: PropTypes.object,
  setSolanaWallet: PropTypes.func.isRequired,
  setAlgorandWalletObject: PropTypes.func.isRequired,
  isSolanaOptIn: PropTypes.bool.isRequired,
  setIsSolanaOptIn: PropTypes.func.isRequired,
};

const _FromWalletStep = memo(FromWalletStep);
export default withStyles(styles)(_FromWalletStep);
