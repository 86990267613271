import React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  alpha,
} from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import Home from "./pages/Home/Index";
import "./app.css";
import BlockedPage from "./pages/BlockedPage/BlockedPage";

const theme = createTheme();
let darkTheme = createTheme({
  overrides: {
    MuiAlert: {
      root: {
        width: "100%",
        background: "initial",
        backgroundColor: "white",
        borderRadius: "7px",
        boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.17)",
        backdropFilter: "blur(21)",
        color: "#5E14A8",
        border: "none",
        "& .MuiAlert-icon": {
          color: "#5e14a8",
          "& .MuiSvgIcon-root": {
            color: "#5e14a8",
          },
        },
        "& .MuiAlert-action": {
          color: "white",
          backgroundColor: "#5e14a8",
          borderRadius: "10px",
          padding: "5px",
          "& .MuiIconButton-label": {
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          },
        },
      },

      standardError: {
        backgroundColor: "white",
        color: "#5E14A8",
        border: "none",
      },
      standardSuccess: {
        backgroundColor: "white",
        color: "#5E14A8",
        border: "none",
      },
      standardInfo: {
        backgroundColor: "white",
        color: "#5E14A8",
        border: "none",
      },
      standardWarning: {
        backgroundColor: "white",
        color: "#5E14A8",
        border: "none",
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "ProximaNova",
        fontWeight: "800",
        fontSize: "25px",
        lineHeight: "29.35px",
        textAlign: "center",
        color: "#FF99D6",
        "@media (min-width:600px)": {
          fontSize: "25px !important",
        },
      },
      body1: {
        color: "#5E14A8",
      },
    },
    MuiPaper: {
      root: {
        background:
          "radial-gradient(100% 222.57% at 0% 15.3%, rgba(153, 94, 222, 0.42) 0%, rgba(114, 127, 247, 0.42) 55.21%, rgba(137, 78, 207, 0.42) 99.66%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(215, 186, 186, 0.12)",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "13px",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "0",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "15px",
      },
    },
    MuiDialogActions: {
      spacing: {
        padding: "16px",
      },
    },
    MuiMenu: {
      paper: {
        background: alpha("#FFFFFF", 0.01),
        backdropFilter: "blur(19px)",
      },
      list: {
        padding: "0",
        borderRadius: "13px",
      },
    },
    MuiBackdrop: {
      root: {
        backdropFilter: "blur(10.4px)",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(12px, 12px) scale(1)",
      },
    },
    MuiInputBase: {
      root: {
        background:
          "radial-gradient(306.75% 102.31% at -35.18% 94.28%, rgba(240, 182, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */",
        backdropFilter: "blur(9px)",
        color: "#5e14a8",
      },
    },

    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#fff",
      },
      root: {
        minHeight: "41px",
        borderRadius: "13px",
      },
      adornedEnd: {
        paddingRight: 0,
        position: "relative",
      },
      input: {
        padding: "5px 9px 6px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#5e14a8",
        "&.Mui-disabled": {
          color: "#a192c7",
        },
      },
      filled: {
        "&[data-shrink=false]": {
          display: "none",
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "white",
        color: "#5E14A8",
        border: "2px solid #5E14A8",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#5E14A8",
          border: "2px solid #5E14A8",
          borderRadius: "10px",
          color: "#fff",
        },
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "#a192c7",
        },
      },
      label: {
        fontSize: "12px",
        lineHeight: "14.09px",
        fontWeight: "700",
        fontFamily: "ProximaNova",
        textTransform: "uppercase",
        paddingTop: "2px",
      },
      outlined: {
        borderWidth: "2px",
      },
    },
  },
  palette: {
    type: "dark",
    purple: "#ff69c5",
    update1Palette: {
      appContainerGradient:
        "radial-gradient(100% 222.57% at 0% 15.3%, rgba(153, 94, 222, 0.55) 0%, rgba(114, 127, 247, 0.55) 55.21%, rgba(137, 78, 207, 0.55) 99.66%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(255, 255, 255, 0.2)",
      bgGradient1:
        "linear-gradient(81.8deg, rgba(251, 60, 255, 0.5) -9.97%, rgba(50, 112, 205, 0.45) 45.69%, rgba(168, 66, 249, 0.5) 98.31%), #FFFFFF",
      bgGradient2:
        "linear-gradient(90deg, #630A92 0.14%, #820379 100.09%) !important",
      bgGradient2Hover:
        "linear-gradient(272.86deg, #5C8092 1.66%, #3B0D5F 51.94%) !important",
      modalGradient:
        "linear-gradient(145.04deg, #31003A 0%, #27053B 99.25%)  !important",
      text1: "#ffffff",
      text2: "gray",
    },
    update2Palette: {
      bgGradient2BcDrop: {
        background:
          "linear-gradient(40deg, rgb(236 229 229 / 13%), rgb(0 0 0 / 0%)) !important",
        backdropFilter: "blur(10px) !important",
      },
      bgGradient2:
        "linear-gradient(40deg, rgb(236 229 229 / 13%), rgb(0 0 0 / 0%)) !important",
    },
    update3Palette: {
      text1: "#ff99d6",
      primary: "#5E14A8",
      bg: "linear-gradient(81.8deg, rgba(251, 60, 255, 0.5) -9.97%, rgba(50, 112, 205, 0.45) 45.69%, rgba(168, 66, 249, 0.5) 98.31%), #FFFFFF",
      modalGradient:
        "radial-gradient(100% 222.57% at 0% 15.3%, rgba(153, 94, 222, 0.42) 0%, rgba(114, 127, 247, 0.42) 55.21%, rgba(137, 78, 207, 0.42) 99.66%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(215, 186, 186, 0.12)",
    },
  },
  typography: {
    // your custome variant
    mysize: {
      fontSize: "30px",
    },
    //customize predefined variant
    subtitle1: {
      fontSize: "20px",
      "@media (max-width:600px)": {
        fontSize: "16px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px !important",
      },
    },
  },
  //customising spacing
  spacing: (factor) => `${0.25 * factor}rem`,
});

darkTheme = responsiveFontSizes(darkTheme);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/blocked-page">
            <BlockedPage
              heading="Unavailable In Your Country"
              text="Due to regulatory uncertainty in the US, Glitter Finance bridging services are unavailable in your country."
            />
          </Route>
          <Route path="*">
            <BlockedPage
              heading="Not Found!"
              text="Sorry we couldn't find this page."
            />
          </Route>
        </Switch>
      </Router>
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
